import   React                    ,  
       { useState                 ,
	     useEffect                }  from 'react';
import   classNames          from 'classnames';

import   styles                  from './bloc_video.module.scss';

import { Video, tabVideo } from '../datas/video';
import { VideoViewer } from '../video_viewer/video_viewer';


export interface VideoThumbProps {
  num: number;                 // classe CSS complémentaire
}

export const VideoThumb = ({ num  }: VideoThumbProps) => {

  const [ videoToShow , setVideoToShow ] = useState<Video|null> (null)

  const WWW_Image:string = "https://media.lovelightfactory.fr/Vignettes/";

  var urlImg: string = `${WWW_Image}${tabVideo[num].imageFile}.webp`;
   
  const handleClick = () => {
  
  	setVideoToShow ( tabVideo[num] )

  }
  
  const handleVideoViewClosure = () => {
  
  	setVideoToShow ( null )
  }

  return (

              <div className={classNames(styles.video_thumb)}>

                { videoToShow !== null && ( <VideoViewer video = {videoToShow} onClose = {handleVideoViewClosure} /> ) }

                <div className={classNames(styles.line_image)}>
                  <img src = {urlImg} className={classNames(styles.image)}
				       onClick = {handleClick}/>
                </div>

                <div className={classNames(styles.line_title)}>
                 <div className={classNames(styles.title)}>
                  {tabVideo[num].title}
                 </div>
                </div>

              </div>
           
  );
};

export const Videotheque = () => {

  return (

              <div className={classNames(styles.root)}>

                {/*
                 <div className={classNames(styles.block1)}>

                  <p className={classNames(styles.titre)}>
                       Les videos du Lovelight Challenge 2024
                  </p>

                  <br/>

                 </div>

                */}



                <div className={classNames(styles.contain)}>

                  
                 <div className={classNames(styles.block2)}>
   
	                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => ( <VideoThumb key={num} num={num} /> ))}
	
	             </div>

                </div>

              </div>
           
  );
};


export default Videotheque;
