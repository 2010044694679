export enum ActionType
 {
  INIT_REGISTRATION                = 'INIT_REGISTRATION'                ,
  UPDATE_ID                        = 'UPDATE_ID'                        , 
  UPDATE_EMAIL                     = 'UPDATE_EMAIL'                     , 
  UPDATE_PASSWORD                  = 'UPDATE_PASSWORD'                  , 
  UPDATE_PASSWORD2                 = 'UPDATE_PASSWORD2'                 , 
  UPDATE_FIRSTNAME                 = 'UPDATE_FIRSTNAME'                 ,
  UPDATE_LASTNAME                  = 'UPDATE_LASTNAME'                  ,
  UPDATE_SEX                       = 'UPDATE_SEX'                       ,
  UPDATE_BIRTHDAY                  = 'UPDATE_BIRTHDAY'                  ,
  UPDATE_ADDRESS                   = 'UPDATE_ADDRESS'                   ,
  UPDATE_ADDRESS2                  = 'UPDATE_ADDRESS2'                  ,
  UPDATE_ZIPCODE                   = 'UPDATE_ZIPCODE'                   ,
  UPDATE_CITY                      = 'UPDATE_CITY'                      ,
  UPDATE_COUNTRY                   = 'UPDATE_COUNTRY'                   ,
  UPDATE_PHONENUMBER               = 'UPDATE_PHONENUMBER'               ,
  UPDATE_WEBSITE                   = 'UPDATE_WEBSITE'                   ,
  UPDATE_INSTAGRAM                 = 'UPDATE_INSTAGRAM'                 ,
  UPDATE_FACEBOOK                  = 'UPDATE_FACEBOOK'                  ,
  UPDATE_TIKTOK                    = 'UPDATE_TIKTOK'                    ,
  UPDATE_BIO                       = 'UPDATE_BIO'                       , 
  UPDATE_COMBINAISON               = 'UPDATE_COMBINAISON'               , 
  UPDATE_RANG                      = 'UPDATE_RANG'                      , 
  UPDATE_EXAEQUO                   = 'UPDATE_EXAEQUO'                   , 
  VERIFY_REGISTRATION              = 'VERIFY_REGISTRATION'              ,
  ADD_PHOTO                        = 'ADD_PHOTO'                        ,
  UPDATE_PHOTO_NAME                = 'UPDATE_PHOTO_NAME'                ,
  UPDATE_PHOTO_DESCRIPTION         = 'UPDATE_PHOTO_DESCRIPTION'         ,
  UPDATE_PHOTO_CAMERABRAND         = 'UPDATE_PHOTO_CAMERABRAND'         ,
  UPDATE_PHOTO_CAMERAMODEL         = 'UPDATE_PHOTO_CAMERAMODEL'         ,
  UPDATE_PHOTO_CHOOSEN             = 'UPDATE_PHOTO_CHOOSEN'             ,
  UPDATE_PHOTO_AUTHORCERTIFICATION = 'UPDATE_PHOTO_AUTHORCERTIFICATION' ,
  UPDATE_PHOTO_NEWSTATE            = 'UPDATE_PHOTO_NEWSTATE'            ,
  REMOVE_PHOTO                     = 'REMOVE_PHOTO'
 }

export interface Photo
 {
  id:                  number  ,
  file:                string  ,
  name:                string  ,
  description:         string  ,
  cameraBrand:         string  ,
  cameraModel:         string  ,
  choosen:             boolean ,
  authorCertification: boolean ,
  newState:            number  ,
  reviewState:         string  ,
  nbVotes:             number  ,
  rang:                number  ,
  exAequo:             number
 }
 
export interface Registration
 {
  id:          number  ,
  email:       string  ,
  password:    string  ,
  password2:   string  ,
  firstname:   string  ,
  lastname:    string  ,
  sex:         string  ,
  birthday:    string  ,
  address:     string  ,
  address2:    string  ,
  zipCode:     string  ,
  city:        string  ,
  country:     string  ,
  phoneNumber: string  ,
  website:     string  ,
  instagram:   string  ,
  facebook:    string  ,
  tiktok:      string  ,
  bio:         string  ,
  combinaison: number  ,
  rang:        number  ,
  exAequo:     number
  verify:      boolean ,
  photos:      Photo[]
 }

export interface actionInitRegistration
 {
  type:                   ActionType.INIT_REGISTRATION;
 }

export interface actionUpdateId
 {
  type:                   ActionType.UPDATE_ID
  newId:                  number;
 }

export interface actionUpdateEmail
 {
  type:                   ActionType.UPDATE_EMAIL
  newEmail:               string;
 }

export interface actionUpdatePassword
 {
  type:                   ActionType.UPDATE_PASSWORD;
  newPassword:            string;
 }

export interface actionUpdatePassword2
 {
  type:                   ActionType.UPDATE_PASSWORD2;
  newPassword2:           string;
 }

export interface actionUpdateFirstname
 {
  type:                   ActionType.UPDATE_FIRSTNAME;
  newFirstname:           string;
 }

export interface actionUpdateLastname
 {
  type:                   ActionType.UPDATE_LASTNAME;
  newLastname:            string;
 }

export interface actionUpdateSex
 {
  type:                   ActionType.UPDATE_SEX;
  newSex:                 string;
 }

export interface actionUpdateBirthday
 {
  type:                   ActionType.UPDATE_BIRTHDAY;
  newBirthday:            string;
 }

export interface actionUpdateAddress
 {
  type:                   ActionType.UPDATE_ADDRESS;
  newAddress:             string;
 }

export interface actionUpdateAddress2
 {
  type:                   ActionType.UPDATE_ADDRESS2;
  newAddress2:            string;
 }

export interface actionUpdateZipCode
 {
  type:                   ActionType.UPDATE_ZIPCODE;
  newZipCode:             string;
 }

export interface actionUpdateCity
 {
  type:                   ActionType.UPDATE_CITY;
  newCity:                string;
 }

export interface actionUpdateCountry
 {
  type:                   ActionType.UPDATE_COUNTRY;
  newCountry:             string;
 }

export interface actionUpdatePhoneNumber
 {
  type:                   ActionType.UPDATE_PHONENUMBER;
  newPhoneNumber:         string;
 }

export interface actionUpdateWebsite
 {
  type:                   ActionType.UPDATE_WEBSITE;
  newWebsite:             string;
 }

export interface actionUpdateInstagram
 {
  type:                   ActionType.UPDATE_INSTAGRAM;
  newInstagram:           string;
 }

export interface actionUpdateFacebook
 {
  type:                   ActionType.UPDATE_FACEBOOK;
  newFacebook:            string;
 }

export interface actionUpdateTiktok
 {
  type:                   ActionType.UPDATE_TIKTOK;
  newTiktok:              string;
 }

export interface actionUpdateBio
 {
  type:                   ActionType.UPDATE_BIO;
  newBio:                 string;
 }

export interface actionUpdateCombinaison
 {
  type:                   ActionType.UPDATE_COMBINAISON;
  newCombinaison:         number;
 }

export interface actionUpdateRang
 {
  type:                   ActionType.UPDATE_RANG;
  newRang:                number;
 }

export interface actionUpdateExAequo
 {
  type:                   ActionType.UPDATE_EXAEQUO;
  newExAequo:             number;
 }

export interface actionVerifyRegistration
 {
  type:                   ActionType.VERIFY_REGISTRATION;
  newVerify:              boolean;
 }

export interface actionAddPhoto
 {
  type:                   ActionType.ADD_PHOTO;
  newPhoto:               Photo;  
 }

export interface actionUpdatePhotoName
 {
  type:                   ActionType.UPDATE_PHOTO_NAME;
  numPhoto:               number;
  newName:                string;
  newState:               number;
 }

export interface actionUpdatePhotoDescription
 {
  type:                   ActionType.UPDATE_PHOTO_DESCRIPTION;
  numPhoto:               number;
  newDescription:         string;
  newState:               number;
 }
 
export interface actionUpdatePhotoCameraBrand
 {
  type:                   ActionType.UPDATE_PHOTO_CAMERABRAND;
  numPhoto:               number;
  newCameraBrand:         string;
  newState:               number;
 }

export interface actionUpdatePhotoCameraModel
 {
  type:                   ActionType.UPDATE_PHOTO_CAMERAMODEL;
  numPhoto:               number;
  newCameraModel:         string;
  newState:               number;
 }

export interface actionUpdatePhotoChoosen
 {
  type:                   ActionType.UPDATE_PHOTO_CHOOSEN;
  numPhoto:               number;
  newChoosen:             boolean;
  newState:               number;
 }

export interface actionUpdatePhotoAuthorCertification
 {
  type:                   ActionType.UPDATE_PHOTO_AUTHORCERTIFICATION;
  numPhoto:               number;
  newAuthorCertification: boolean;
  newState:               number;
 }

export interface actionUpdatePhotoNewState
 {
  type:                   ActionType.UPDATE_PHOTO_NEWSTATE;
  numPhoto:               number;
  newNewState:            number;
 }

export interface actionRemovePhoto
 {
  type:                   ActionType.REMOVE_PHOTO;
  numPhoto:               number;
 }

export type Action = actionInitRegistration                | 
                     actionUpdateId                        | 
                     actionUpdateEmail                     | 
                     actionUpdatePassword                  | 
                     actionUpdatePassword2                 | 
                     actionUpdateFirstname                 | 
					 actionUpdateLastname                  |
					 actionUpdateSex                       |
					 actionUpdateBirthday                  |
					 actionUpdateAddress                   |
                     actionUpdateAddress2                  |
                     actionUpdateZipCode                   |
                     actionUpdateCity                      |
                     actionUpdateCountry                   |
                     actionUpdatePhoneNumber               |
                     actionUpdateWebsite                   |
                     actionUpdateInstagram                 |
                     actionUpdateFacebook                  |
                     actionUpdateTiktok                    |
                     actionUpdateBio                       |
                     actionUpdateCombinaison               |
                     actionUpdateRang                      |
                     actionUpdateExAequo                   |
					 actionVerifyRegistration              |
					 actionAddPhoto                        |
					 actionUpdatePhotoName                 |
					 actionUpdatePhotoDescription          |
					 actionUpdatePhotoCameraBrand          |
					 actionUpdatePhotoCameraModel          |
					 actionUpdatePhotoChoosen              |
					 actionUpdatePhotoAuthorCertification  |
					 actionUpdatePhotoNewState             |
					 actionRemovePhoto;


export type DispatchTypeInitRegistration               = ( args: actionInitRegistration               ) => actionInitRegistration
export type DispatchTypeUpdateId                       = ( args: actionUpdateId                       ) => actionUpdateId
export type DispatchTypeUpdateEmail                    = ( args: actionUpdateEmail                    ) => actionUpdateEmail
export type DispatchTypeUpdatePassword                 = ( args: actionUpdatePassword                 ) => actionUpdatePassword
export type DispatchTypeUpdatePassword2                = ( args: actionUpdatePassword2                ) => actionUpdatePassword2
export type DispatchTypeUpdateFirstname                = ( args: actionUpdateFirstname                ) => actionUpdateFirstname
export type DispatchTypeUpdateLastname                 = ( args: actionUpdateLastname                 ) => actionUpdateLastname
export type DispatchTypeUpdateSex                      = ( args: actionUpdateSex                      ) => actionUpdateSex
export type DispatchTypeUpdateBirthday                 = ( args: actionUpdateBirthday                 ) => actionUpdateBirthday
export type DispatchTypeUpdateAddress                  = ( args: actionUpdateAddress                  ) => actionUpdateAddress
export type DispatchTypeUpdateAddress2                 = ( args: actionUpdateAddress2                 ) => actionUpdateAddress2
export type DispatchTypeUpdateZipCode                  = ( args: actionUpdateZipCode                  ) => actionUpdateZipCode
export type DispatchTypeUpdateCity                     = ( args: actionUpdateCity                     ) => actionUpdateCity
export type DispatchTypeUpdateCountry                  = ( args: actionUpdateCountry                  ) => actionUpdateCountry
export type DispatchTypeUpdatePhoneNumber              = ( args: actionUpdatePhoneNumber              ) => actionUpdatePhoneNumber
export type DispatchTypeUpdateWebsite                  = ( args: actionUpdateWebsite                  ) => actionUpdateWebsite
export type DispatchTypeUpdateInstagram                = ( args: actionUpdateInstagram                ) => actionUpdateInstagram
export type DispatchTypeUpdateFacebook                 = ( args: actionUpdateFacebook                 ) => actionUpdateFacebook
export type DispatchTypeUpdateTiktok                   = ( args: actionUpdateTiktok                   ) => actionUpdateTiktok
export type DispatchTypeUpdateBio                      = ( args: actionUpdateBio                      ) => actionUpdateBio
export type DispatchTypeUpdateCombinaison              = ( args: actionUpdateCombinaison              ) => actionUpdateCombinaison
export type DispatchTypeUpdateRang                     = ( args: actionUpdateRang                     ) => actionUpdateRang
export type DispatchTypeUpdateExAequo                  = ( args: actionUpdateExAequo                  ) => actionUpdateExAequo
export type DispatchTypeVerifyRegistration             = ( args: actionVerifyRegistration             ) => actionVerifyRegistration
export type DispatchTypeAddPhoto                       = ( args: actionAddPhoto                       ) => actionAddPhoto
export type DispatchTypeUpdatePhotoName                = ( args: actionUpdatePhotoName                ) => actionUpdatePhotoName
export type DispatchTypeUpdatePhotoDescription         = ( args: actionUpdatePhotoDescription         ) => actionUpdatePhotoDescription
export type DispatchTypeUpdatePhotoCameraBrand         = ( args: actionUpdatePhotoCameraBrand         ) => actionUpdatePhotoCameraBrand
export type DispatchTypeUpdatePhotoCameraModel         = ( args: actionUpdatePhotoCameraModel         ) => actionUpdatePhotoCameraModel
export type DispatchTypeUpdatePhotoChoosen             = ( args: actionUpdatePhotoChoosen             ) => actionUpdatePhotoChoosen
export type DispatchTypeUpdatePhotoAuthorCertification = ( args: actionUpdatePhotoAuthorCertification ) => actionUpdatePhotoAuthorCertification
export type DispatchTypeUpdatePhotoNewState            = ( args: actionUpdatePhotoNewState            ) => actionUpdatePhotoNewState
export type DispatchTypeRemovePhoto                    = ( args: actionRemovePhoto                    ) => actionRemovePhoto

					 
