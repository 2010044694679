import   React               from "react";
import   classNames          from 'classnames';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import   styles              from './bloc_countdown4.module.scss';

import { Trigger              }  from '../widget_trigger/trigger';


export interface BlocCountdown4Props {
  className?: string;
}

export const BlocCountdown4 = ({ className  }: BlocCountdown4Props) => {

  return (
        
           <div className={classNames(styles.root, className)}>
		   
             <div className={classNames(styles.photo_overlay, className)}>
               
			   <img src="/images/LovelightChallenge_1024x1024.png" 
			        className={classNames(styles.logo_challenge, className)}
			        alt="logo LovelightChallenge"/>
			   
             </div>
			 
           </div>
          
		 );
};

export default BlocCountdown4;
