import   React                , 
       { useEffect            ,
         useState             }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import { useLocation          }  from 'react-router-dom';
import   styles                  from './home10.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';


import { Welcome9             }  from '../bloc_welcome9/welcome9';
import { KeyDates             }  from '../bloc_keydates/keydates';
import { Jury                 }  from '../bloc_jury/jury';
import { Partners             }  from '../bloc_partners/partners';

export interface Home8Props {
  className?: string;
}

export const Home10 = ({ className }: Home8Props) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);

  const [ firstRender           , setFirstRender       ] = useState(true);

  const location = useLocation();

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
            }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
     
   var identifiant = location.hash.substring(1);
   
   if ( identifiant === "" )
    {   
	 identifiant = "accueil";
    }

   console.log ("identifiant = ", identifiant );
   
   
   if ( identifiant !== "accueil" )
    {
     var element = document.getElementById( identifiant );

     console.log ("element     = ", element );

     if ( element !== null )
      {	 
       console.log ("on scrolle" );
	  
       element.scrollIntoView (); //({ behavior: 'smooth', block: "end", inline: "nearest" })	 
	  }
	}
	else {
	
	     var element = document.getElementById( identifiant );

     if ( element !== null )
      {	 
	   element.scrollTo(0, 70);
	  }
	}
	
      
  }); 

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

/*

                 <section id="le-jury">
				  <Jury />
				 </section>

                 <section id="les-prix">
				  <Prices />
				 </section>

                 <section id="les-partenaires">
				  <Partners />
				 </section>

*/

 return (
  

            <>


              { 
                showCookiesParameters && 
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }
             
              <Menus />

              <div className={classNames(styles.root, className)} id="home">

                 <section id="accueil">
				  <Welcome9 />
				 </section>
				 
                 <section id="les-dates-clees">
				  <KeyDates />
				 </section>
				 
                 <section id="les-partenaires">
				  <Partners />
				 </section>

			 </div>
            

            </>
            
         );
};
