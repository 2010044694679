import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import { Link                 , 
         useNavigate          }  from 'react-router-dom';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



import   styles                  from './faq.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';

export interface FaqProps {
  className?: string;
}

export const Faq = ({ className }: FaqProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
  }); 
  

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };


  return (
  
        <>

              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
    
        <h1>FAQ</h1>

                    <p className={classNames(styles.texte, className)} >
Bienvenue dans notre section FAQ, où nous avons rassemblé les réponses aux questions les plus fréquemment posées concernant le Lovelight Challenge. Explorez les questions ci-dessous pour obtenir des informations utiles sur l'événement. Si vous ne trouvez pas la réponse que vous cherchez, n'hésitez pas à nous contacter.                    </p>
        <br/>
		
		<Accordion allowZeroExpanded className={classNames(styles.accordion, className)}>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    1. Qu'est-ce que le Lovelight Challenge ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
C'est une série d’émissions audiovisuelles sous la forme d’un jeu concours photo entièrement gratuit. Le concept est simple : proposer une série d’épreuves photographiques et éliminatoires aux candidats sélectionnés jusqu’à la victoire finale du grand gagnant.
Son ambition est la mise en lumière de nouveaux talents de la photographie ainsi que le partage d’informations sur les techniques photographiques et le métier de photographe. 
Pour participer, il suffit de s’inscrire et soumettre sa participation sur le présent site.
Les photos proposées seront ensuite présentées au vote du public pour les présélections. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    2. Qui peut participer ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Le Lovelight Challenge est ouvert à toute personne majeure résidant en France métropolitaine, Suisse ou Belgique, peu importe le matériel utilisé.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    3. Quelles sont les dates d’inscription pour participer au Lovelight Challenge ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Les inscriptions pour la 1ère édition seront ouvertes du 21 novembre 2023 au 22 décembre 2023 avant 22h00.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    4. Est-il possible de soumettre plusieurs photos ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Oui, il est possible de soumettre jusqu’à 3 photos tant qu'elles respectent les contraintes demandées, indiquées dans le règlement du jeu.
Pour garantir un renouvellement des œuvres proposées, la date de première diffusion de la photo, quelle que soit la plateforme, ne doit pas être antérieure au 1er janvier 2015.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    5. Comment participer ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Pour participer, il faut s'inscrire sur le site www.lovelightchallenge.com pendant la période d'appel à photos puis compléter son profil, les informations sur la photo et télécharger cette dernière avant la date de clôture des participations.
Aucun autre moyen de participation ne sera pris en compte (envoi de photo par courrier, lien de transfert, lien YouTube, site multi-concours...).
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    6. A quels critères doit répondre une photo pour participer ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Pour participer, il faut être l’auteur de la photo proposée qui doit respecter les critères de format et de taille imposée pour l'édition en cours.
La photo doit être proposée sous un format Jpeg de bonne définition, d’une résolution minimale de 920x768 pixels.
Pour garantir un renouvellement des œuvres proposées, la date de première diffusion de la photo, quelle que soit la plateforme, ne doit pas être antérieure au 1er janvier 2015.
La photo ne doit pas impliquer des tiers directement ou indirectement sans autorisation de leur part. Par ailleurs, la photo doit respecter la loi, l'ordre public et ne pas être contraire aux bonnes mœurs.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    7. La violence et la nudité sont-elles interdites dans les photos ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Il est interdit d’intégrer de la violence ou de la nudité dans vos photos.
Le contenu proposé ne doit pas contenir de messages à caractère pornographique ou inciter au crime, à la haine, à la violence, au suicide et au racisme. 
Pour plus d'informations, nous vous invitons à consulter le règlement.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    8. Que faire si le téléchargement de la photo via le site ne fonctionne pas ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Si le téléchargement de la photo via le site ne fonctionne pas, il faut vérifier que le fichier respecte bien les recommandations (format et poids du fichier) et réessayer avec une meilleure connexion internet, si possible filaire.
Si cela ne fonctionne toujours pas, contactez les organisateurs à l'adresse team@lovelightchallenge.com : ils vous indiqueront la procédure alternative à suivre.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    9. Peut-on envoyer sa photo à d'autres concours ou la diffuser ailleurs ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Oui, il est tout à fait possible de soumettre sa photo à d'autres concours pendant ou après le Lovelight Challenge.
Il n'y aucune exclusivité imposée, au contraire nous vous invitons à faire vivre vos photos le plus possible. La diffusion avant, pendant et après le concours en dehors de notre plateforme est autorisée.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    10. Quels sont les critères d’évaluation du Jury ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Les décisions du Jury s’établiront principalement sur les critères suivants :
    • La pertinence du thème de la photo
    • La qualité et l’originalité de la photo
    • La justesse de la mise en scène et/ou du cadrage de la photo
    • La qualité technique générale de la photo (éclairage, colorimétrie…)
    • L’appréciation personnelle et les émotions provoquées
L'évaluation des photos en compétition est un tout, aucun critère n'est prioritaire par rapport à un autre.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    11. Qui effectue la pré-sélection des 15 finalistes ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
La présélection des 15 finalistes du Lovelight Challenge est effectuée par le vote du public sur le site www.lovelightchallenge.com, à compter du 26 décembre 2023 jusqu’au 21 janvier 2023 avant 22h00.
Cette liste sera dévoilée publiquement sur Internet au moment de l’annonce de cette présélection, prévue le 22 janvier 2022.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    12. Comment voter pour une photo ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Au moment du lancement des votes du public prévu le 26 décembre 2023, il faut cliquer sur le bouton « Cœur » disponible sur la page de la photo en question. Pour voter, il est nécessaire de se rendre sur le site www.lovelightchallenge.com et de suivre les indications de vote.
Chaque votant peut voter pour autant de photos en compétition qu'il le souhaite dans la limite d'un vote par photo.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    13. La sélection des 6 derniers finalistes est-elle liée aux votes du public ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Non, la sélection des 6 derniers finalistes est effectuée par le jury du Lovelight Challenge sur la base de critères suivants : 
    • La pertinence de la photo par rapport au concours
    • La qualité et l’originalité de la photo
    • La qualité technique, de photographie et de mise en scène
    • L’appréciation personnelle
    • Le respect du thème imposé
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    14. Est-il possible d'utiliser une IA dans le cadre de sa participation ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Non, l’utilisation de l’IA n’est pas acceptée.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
                    15. Où consulter le règlement du Lovelight Challenge ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
                      Nous vous invitons à consulter le règlement <Link to="/reglement-du-lovelight-challenge" className = { classNames(styles.link, className) } >ici</Link>.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>


            </Accordion>
		
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>

            
  );
};

export default Faq;
