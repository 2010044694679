export enum ActionType
 {
  INIT_ENSEMBLE          = 'INIT_ENSEMBLE'          ,
  ADD_PHOTO              = 'ADD_PHOTO'              ,
  UPDATE_PHOTO_SELECTION = 'UPDATE_PHOTO_SELECTION'
 }

export interface Photo
 {
  id:                  number  ,
  file:                string  ,
  name:                string  ,
  description:         string  ,
  cameraBrand:         string  ,
  cameraModel:         string  ,
  choosen:             boolean ,
  authorCertification: boolean ,
  urlThumbnail:        string  ,
  urlMagnified:        string  ,
  width:               number  ,
  height:              number  ,
  selected:            boolean ,
  firstname:           string  ,
  lastname:            string
 }
 
export interface Ensemble
 {
  photos:      Photo[]
 }

export interface actionInitEnsemble
 {
  type:         ActionType.INIT_ENSEMBLE;
 }

export interface actionAddPhoto
 {
  type:         ActionType.ADD_PHOTO;
  newPhoto:     Photo;  
 }

export interface actionUpdatePhotoSelection
 {
  type:         ActionType.UPDATE_PHOTO_SELECTION;
  num:          number;  
  newSelected:  boolean;  
 }

export type Action = actionInitEnsemble | 
                     actionAddPhoto     | 
					 actionUpdatePhotoSelection;

export type DispatchTypeInitEnsemble         = ( args: actionInitEnsemble         ) => actionInitEnsemble
export type DispatchTypeAddPhoto             = ( args: actionAddPhoto             ) => actionAddPhoto
export type DispatchTypeUpdatePhotoSelection = ( args: actionUpdatePhotoSelection ) => actionUpdatePhotoSelection
