import { Dispatch } from 'redux';

import { ActionType                                 ,
         Registration                               ,
         actionInitRegistration                     ,
		 actionUpdateId                             ,
         actionUpdateEmail                          ,
         actionUpdatePassword                       ,
         actionUpdatePassword2                      ,
         actionUpdateFirstname                      ,
         actionUpdateLastname                       ,
         actionUpdateSex                            ,
         actionUpdateBirthday                       ,
         actionUpdateAddress                        ,
         actionUpdateAddress2                       ,
         actionUpdateZipCode                        ,
         actionUpdateCity                           ,
         actionUpdateCountry                        ,
         actionUpdatePhoneNumber                    ,
         actionUpdateWebsite                        ,
         actionUpdateInstagram                      ,
         actionUpdateFacebook                       ,
         actionUpdateTiktok                         ,
         actionUpdateBio                            ,
         actionUpdateCombinaison                    ,
         actionUpdateRang                           ,
         actionUpdateExAequo                        ,
         actionVerifyRegistration                   ,
		 actionAddPhoto                             ,
		 actionUpdatePhotoName                      ,
		 actionUpdatePhotoDescription               ,
		 actionUpdatePhotoCameraBrand               ,
		 actionUpdatePhotoCameraModel               ,
		 actionUpdatePhotoChoosen                   ,
		 actionUpdatePhotoAuthorCertification       ,
		 actionUpdatePhotoNewState                  ,
		 actionRemovePhoto                          ,
		 DispatchTypeInitRegistration               ,
         DispatchTypeUpdateId                       ,
         DispatchTypeUpdateEmail                    ,
         DispatchTypeUpdatePassword                 ,
         DispatchTypeUpdatePassword2                ,
         DispatchTypeUpdateFirstname                ,
         DispatchTypeUpdateLastname                 ,
         DispatchTypeUpdateSex                      ,
         DispatchTypeUpdateBirthday                 ,
         DispatchTypeUpdateAddress                  ,
         DispatchTypeUpdateAddress2                 ,
         DispatchTypeUpdateZipCode                  ,
         DispatchTypeUpdateCity                     ,
         DispatchTypeUpdateCountry                  ,
         DispatchTypeUpdatePhoneNumber              ,
         DispatchTypeUpdateWebsite                  ,
         DispatchTypeUpdateInstagram                ,
         DispatchTypeUpdateFacebook                 ,
         DispatchTypeUpdateTiktok                   ,
         DispatchTypeUpdateBio                      ,
         DispatchTypeUpdateCombinaison              ,
         DispatchTypeUpdateRang                     ,
         DispatchTypeUpdateExAequo                  ,
		 DispatchTypeVerifyRegistration             ,
		 DispatchTypeAddPhoto                       ,
		 DispatchTypeUpdatePhotoName                ,
		 DispatchTypeUpdatePhotoDescription         ,
		 DispatchTypeUpdatePhotoCameraBrand         ,
		 DispatchTypeUpdatePhotoCameraModel         ,
		 DispatchTypeUpdatePhotoChoosen             ,
		 DispatchTypeUpdatePhotoAuthorCertification ,
		 DispatchTypeUpdatePhotoNewState            ,
		 DispatchTypeRemovePhoto  		            } from './actionTypes';

					 


export const initRegistration = () => 
 {
  const action: actionInitRegistration = 
   {
    type:          ActionType.INIT_REGISTRATION
   }

  return ( dispatch: DispatchTypeInitRegistration ) => 
   {
    dispatch (action);
   }
 } 

export const updateId = ( newId: number ) => 
 {
  const action: actionUpdateId = 
   {
    type:          ActionType.UPDATE_ID,
	newId:         newId
   }

  return ( dispatch: DispatchTypeUpdateId ) => 
   {
    dispatch (action);
   }
 } 

export const updateEmail = ( newEmail: string ) => 
 {
  const action: actionUpdateEmail = 
   {
    type:          ActionType.UPDATE_EMAIL,
	newEmail:      newEmail
   }

  return ( dispatch: DispatchTypeUpdateEmail ) => 
   {
    dispatch (action);
   }
 } 

export const updatePassword = ( newPassword: string ) => 
 {
  const action: actionUpdatePassword =
   {
    type:          ActionType.UPDATE_PASSWORD,
	newPassword:   newPassword
   }
 
  return ( dispatch: DispatchTypeUpdatePassword ) => 
   {
    dispatch (action);
   }
 } 

export const updatePassword2 = ( newPassword2: string ) => 
 {
  const action: actionUpdatePassword2 =
   {
    type:          ActionType.UPDATE_PASSWORD2,
	newPassword2:  newPassword2
   }
 
  return ( dispatch: DispatchTypeUpdatePassword2 ) => 
   {
    dispatch (action);
   }
 } 

export const updateFirstname = ( newFirstname: string ) => 
 {
  const action: actionUpdateFirstname =
   {
    type:          ActionType.UPDATE_FIRSTNAME,
	newFirstname:  newFirstname
   }
   
  return ( dispatch: DispatchTypeUpdateFirstname ) => 
   {
    dispatch (action);
   }
 } 

export const updateLastname = ( newLastname: string ) => 
 {
  const action: actionUpdateLastname =
   {
    type:          ActionType.UPDATE_LASTNAME,
	newLastname:   newLastname
   }
   
  return ( dispatch: DispatchTypeUpdateLastname ) => 
   {
    dispatch (action);
   }
 } 

export const updateSex = ( newSex: string ) => 
 {
  const action: actionUpdateSex =
   {
    type:          ActionType.UPDATE_SEX,
	newSex:        newSex
   }
   
  return ( dispatch: DispatchTypeUpdateSex ) => 
   {
    dispatch (action);
   }
 } 

export const updateBirthday = ( newBirthday: string ) => 
 {
  const action: actionUpdateBirthday =
   {
    type:          ActionType.UPDATE_BIRTHDAY,
	newBirthday:   newBirthday
   }
   
  return ( dispatch: DispatchTypeUpdateBirthday ) => 
   {
    dispatch (action);
   }
 } 

export const updateAddress = ( newAddress: string ) => 
 {
  const action: actionUpdateAddress =
   {
    type:          ActionType.UPDATE_ADDRESS,
	newAddress:    newAddress
   }
  
  return ( dispatch: DispatchTypeUpdateAddress ) => 
   {
    dispatch (action);
   }
 } 

export const updateAddress2 = ( newAddress2: string ) => 
 {
  const action: actionUpdateAddress2 =
   {
    type:           ActionType.UPDATE_ADDRESS2,
	newAddress2:    newAddress2
   }
  
  return ( dispatch: DispatchTypeUpdateAddress2 ) => 
   {
    dispatch (action);
   }
 } 

export const updateZipCode = ( newZipCode: string ) => 
 {
  const action: actionUpdateZipCode =
   {
    type:           ActionType.UPDATE_ZIPCODE,
	newZipCode:     newZipCode
   }
  
  return ( dispatch: DispatchTypeUpdateZipCode ) => 
   {
    dispatch (action);
   }
 } 

export const updateCity = ( newCity: string ) => 
 {
  const action: actionUpdateCity =
   {
    type:           ActionType.UPDATE_CITY,
	newCity:        newCity
   }
  
  return ( dispatch: DispatchTypeUpdateCity ) => 
   {
    dispatch (action);
   }
 } 

export const updateCountry = ( newCountry: string ) =>
 {
  const action: actionUpdateCountry =
   {
    type:           ActionType.UPDATE_COUNTRY,
	newCountry:     newCountry
   }

  return ( dispatch: DispatchTypeUpdateCountry ) =>
   {
    dispatch (action);
   }
 }

export const updatePhoneNumber = ( newPhoneNumber: string ) =>
 {
  const action: actionUpdatePhoneNumber =
   {
    type:           ActionType.UPDATE_PHONENUMBER,
	newPhoneNumber: newPhoneNumber
   }
   
  return ( dispatch: DispatchTypeUpdatePhoneNumber ) => 
   {
    dispatch (action);
   }
 } 

export const updateWebsite = ( newWebsite: string ) => 
 {
  const action: actionUpdateWebsite =
   {
    type:           ActionType.UPDATE_WEBSITE,
	newWebsite:     newWebsite
   }
   
  return ( dispatch: DispatchTypeUpdateWebsite ) => 
   {
    dispatch (action);
   }
 } 

export const updateInstagram = ( newInstagram: string ) => 
 {
  const action: actionUpdateInstagram =
   {
    type:           ActionType.UPDATE_INSTAGRAM,
	newInstagram:   newInstagram
   }
   
  return ( dispatch: DispatchTypeUpdateInstagram ) => 
   {
    dispatch (action);
   }
 } 

export const updateFacebook = ( newFacebook: string ) => 
 {
  const action: actionUpdateFacebook =
   {
    type:           ActionType.UPDATE_FACEBOOK,
	newFacebook:    newFacebook
   }
   
  return ( dispatch: DispatchTypeUpdateFacebook ) => 
   {
    dispatch (action);
   }
 } 

export const updateTiktok = ( newTiktok: string ) => 
 {
  const action: actionUpdateTiktok =
   {
    type:           ActionType.UPDATE_TIKTOK,
	newTiktok:      newTiktok
   }
   
  return ( dispatch: DispatchTypeUpdateTiktok ) => 
   {
    dispatch (action);
   }
 } 

export const updateBio = ( newBio: string ) => 
 {
  const action: actionUpdateBio =
   {
    type:           ActionType.UPDATE_BIO,
	newBio:         newBio
   }
   
  return ( dispatch: DispatchTypeUpdateBio ) => 
   {
    dispatch (action);
   }
 } 

export const updateCombinaison = ( newCombinaison: number ) => 
 {
  const action: actionUpdateCombinaison =
   {
    type:           ActionType.UPDATE_COMBINAISON,
	newCombinaison: newCombinaison
   }
   
  return ( dispatch: DispatchTypeUpdateCombinaison ) => 
   {
    dispatch (action);
   }
 } 

export const updateRang = ( newRang: number ) => 
 {
  const action: actionUpdateRang =
   {
    type:           ActionType.UPDATE_RANG,
	newRang:        newRang
   }
   
  return ( dispatch: DispatchTypeUpdateRang ) => 
   {
    dispatch (action);
   }
 } 

export const updateExAequo = ( newExAequo: number ) => 
 {
  const action: actionUpdateExAequo =
   {
    type:           ActionType.UPDATE_EXAEQUO,
	newExAequo:     newExAequo
   }
   
  return ( dispatch: DispatchTypeUpdateExAequo ) => 
   {
    dispatch (action);
   }
 } 

export const verifyRegistration = ( newVerify: boolean ) =>
 {
  const action: actionVerifyRegistration =
   {
    type:       ActionType.VERIFY_REGISTRATION,
	newVerify:  newVerify
   }
   
  return ( dispatch: DispatchTypeVerifyRegistration ) =>
   {
    dispatch (action);
   }
 }
 
export const addPhoto = ( newId:                  number  ,
                          newFile:                string  ,
                          newName:                string  ,
                          newDescription:         string  ,
                          newChoosen:             boolean ,
                          newAuthorCertification: boolean ,
                          newCameraBrand:         string  ,
						  newCameraModel:         string  ,
                          newNewState:            number  ,
                          newReviewState:         string  ,
						  newNbVotes:             number  ,
						  newRang:                number  ,
						  newExAequo:             number  ) =>
 {
  const action: actionAddPhoto =
   {
    type:           ActionType.ADD_PHOTO,
	newPhoto: {
      id:                  newId                  ,
      file:                newFile                ,
      name:                newName                ,
      description:         newDescription         ,
      choosen:             newChoosen             ,
      authorCertification: newAuthorCertification ,
      cameraBrand:         newCameraBrand         ,
      cameraModel:         newCameraModel         ,
      newState:            newNewState            ,
      reviewState:         newReviewState         ,
      nbVotes:             newNbVotes             ,
      rang:                newRang                ,
      exAequo:             newExAequo
	}
   }
   
  return ( dispatch: DispatchTypeAddPhoto ) =>
   {
    dispatch (action);
   }
 } 
 
export const updatePhotoName = ( numPhoto: number ,
                                 newName:  string ) => 
 {
  const action: actionUpdatePhotoName =
   {
    type:           ActionType.UPDATE_PHOTO_NAME,
	numPhoto:       numPhoto,
	newName:        newName,
    newState:       2
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoName ) => 
   {
    dispatch (action);
   }
 } 

export const updatePhotoDescription = ( numPhoto:       number ,
                                        newDescription: string ) => 
 {
  const action: actionUpdatePhotoDescription =
   {
    type:           ActionType.UPDATE_PHOTO_DESCRIPTION,
	numPhoto:       numPhoto,
	newDescription: newDescription,
    newState:       2
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoDescription ) => 
   {
    dispatch (action);
   }
 } 

export const updatePhotoCameraBrand = ( numPhoto:       number ,
                                         newCameraBrand: string ) => 
 {
  const action: actionUpdatePhotoCameraBrand =
   {
    type:           ActionType.UPDATE_PHOTO_CAMERABRAND,
	numPhoto:       numPhoto,
	newCameraBrand: newCameraBrand,
    newState:       2
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoCameraBrand ) => 
   {
    dispatch (action);
   }
 } 

export const updatePhotoCameraModel = ( numPhoto:       number ,
                                         newCameraModel: string ) => 
 {
  const action: actionUpdatePhotoCameraModel =
   {
    type:           ActionType.UPDATE_PHOTO_CAMERAMODEL,
	numPhoto:       numPhoto,
	newCameraModel: newCameraModel,
    newState:       2
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoCameraModel ) => 
   {
    dispatch (action);
   }
 } 

export const updatePhotoChoosen = ( numPhoto:   number  ,
                                     newChoosen: boolean ) => 
 {
  const action: actionUpdatePhotoChoosen =
   {
    type:           ActionType.UPDATE_PHOTO_CHOOSEN,
	numPhoto:       numPhoto,
	newChoosen:     newChoosen,
    newState:       2
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoChoosen ) => 
   {
    dispatch (action);
   }
 } 

export const updatePhotoAuthorCertification = ( numPhoto:               number  ,
                                                 newAuthorCertification: boolean ) => 
 {
  const action: actionUpdatePhotoAuthorCertification =
   {
    type:                    ActionType.UPDATE_PHOTO_AUTHORCERTIFICATION,
	numPhoto:                numPhoto,
	newAuthorCertification:  newAuthorCertification,
    newState:                2
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoAuthorCertification ) => 
   {
    dispatch (action);
   }
 } 

export const updatePhotoNewState = ( numPhoto:    number ,
                                     newNewState: number ) =>
 {
  const action: actionUpdatePhotoNewState =
   {
    type:                    ActionType.UPDATE_PHOTO_NEWSTATE,
	numPhoto:                numPhoto,
    newNewState:             newNewState
   }

  return ( dispatch: DispatchTypeUpdatePhotoNewState ) =>
   {
    dispatch (action);
   }
 }

export const removePhoto = ( numPhoto: number ) =>
 {
  const action: actionRemovePhoto =
   {
    type:      ActionType.REMOVE_PHOTO,
	numPhoto:  numPhoto
   }
   
  return ( dispatch: DispatchTypeRemovePhoto ) => 
   {
    dispatch (action);
   }
 } 
