import   React                           , 
       { useEffect                       , 
         useState                        }  from 'react';
import { Dispatch                        }  from 'redux';
import { useDispatch                     }  from 'react-redux';

import   axios                              from 'axios';

import   classNames                         from 'classnames';

import   Uploady                         , 
       { useItemFinishListener           , 
         useItemProgressListener         , 
         useItemStartListener            , 
         useUploady                      ,
		 useItemErrorListener            }  from "@rpldy/uploady";
import   UploadButton                       from "@rpldy/upload-button";
import   UploadPreview                      from "@rpldy/upload-preview";

import   ProgressBar                        from 'react-customizable-progressbar'

import { FontAwesomeIcon                 }  from '@fortawesome/react-fontawesome'
import { IconDefinition                  }  from '@fortawesome/fontawesome-common-types'; 
import { faTrashCan                      , 
         faUpload                        , 
         faMagnifyingGlassPlus           ,
		 faPen                           }  from '@fortawesome/free-solid-svg-icons'

import   styles                             from './photo.module.scss';

import { Action                          }  from '../../redux/registrationManagment/actionTypes';
         
import { addPhoto                        ,
         updatePhotoName                 ,
         updatePhotoDescription          ,
         updatePhotoCameraBrand          ,
         updatePhotoCameraModel          ,
         updatePhotoChoosen              ,
         updatePhotoAuthorCertification  ,
         updatePhotoNewState             ,
         removePhoto                     }  from '../../redux/registrationManagment/actionCreators';

import { useTypedSelector                }  from '../../hooks/useTypeSelector';
import { store                           }  from '../../redux/store';

import { Field                           }  from '../widget_field/field';
import { Select                          }  from '../widget_select/select';
import { Toggle                          }  from '../widget_toggle/toggle';
import { TextArea                        }  from '../widget_textarea/textarea';
import { Trigger                         }  from '../widget_trigger/trigger';
import { WindowPhoto                     }  from '../window_photo/window_photo';
import { WindowMessage                   }  from '../window_message/window_message';
import { WindowQuestion                  }  from '../window_question/window_question';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

/**************************************************************************************************/     

/********************************************/
/* La jauge de progression lors de l'upload */
/********************************************/

export interface ProgressGaugeProps {
  className?: string; 
}

const ProgressGauge = ({ className }: ProgressGaugeProps) => {

  const [progress, setProgress] = useState(0);

  useItemStartListener(() => {
    setProgress(() => 1);
  });

  useItemProgressListener((item) => {
    setProgress(() => item.completed / 100 * 80);
  });

  return ( 
    
    <ProgressBar progress           = {progress}
                 strokeWidth        = {12}
                 strokeColor        = "#ffff00"
                 strokeLinecap      = "butt"
                 pointerStrokeColor = "#ffff00"
                 trackStrokeColor   = "#c7c7c7"
                 trackStrokeWidth   = {14}
                 trackStrokeLinecap = "butt"
                 radius             = {55}        >

    </ProgressBar>

  );      
}    
     
/**************************************************************************************************/     

/*************************************/
/* Le boutton pour uploader la photo */
/*************************************/
  
export interface UploaderButtonProps {
  className?:           string; 
  name:                 string;  
  icon?:                IconDefinition | undefined;
  onUploadStart:        () => void;
  onUploadFinish:       (num: number) => void;
  onUploadError:        (num: number, error: string) => void;
}
 
const UploaderButton = ({ className            , 
                          name                 , 
                          icon                 ,
                          onUploadStart        ,
                          onUploadFinish       ,
						  onUploadError        }: UploaderButtonProps)  => {
                          
  const dispatch: Dispatch<any> = useDispatch()
    
  const { registration } = useTypedSelector((state) => state.registrationManagment );
 
  useItemStartListener (() => {
    onUploadStart();
  });
   
  useItemFinishListener((item) => {

    dispatch ( addPhoto ( item.uploadResponse.data.id                  ,
                          item.uploadResponse.data.file                ,
                          item.uploadResponse.data.name                ,
                          item.uploadResponse.data.description         ,
                          true                  ,
                          ( item.uploadResponse.data.authorCertification == "true" ),
                          item.uploadResponse.data.cameraBrand         ,
                          item.uploadResponse.data.cameraModel         ,
                           0                                           ,
						   ""                                          ,
						   0                                           ,
						   0                                           ,
						   0                                           ) );
   
    onUploadFinish( registration.photos.length - 1 );    

  });

  useItemErrorListener((item, options) => {
  
    onUploadError ( item.uploadResponse.data.status,  item.uploadResponse.data.detail );
  }); 

  if ( typeof {icon} === 'undefined' )
        {
         return ( <UploadButton className = {classNames(className, styles.little_trigger)} >{name}</UploadButton> );
        }
   else {
         const nomIcone: IconDefinition = icon!;

         return ( <UploadButton className = {classNames(className, styles.little_trigger)} ><FontAwesomeIcon icon={nomIcone}/></UploadButton> );
        }
}; 

/**************************************************************************************************/     

/*****************/
/* Le bloc photo */
/*****************/
 
export interface PhotoProps {
  className?:        string;
  index?:            number | undefined; 
  onNewPhotoUpload?: () => void;
  onPhotoRemove?:    () => void; 
}
 
export const Photo = ({ className        , 
                        index            ,
                        onNewPhotoUpload , 
                        onPhotoRemove    }: PhotoProps) => {
                        
  const dispatch: Dispatch<any> = useDispatch()

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );                        

  const [ id                         , setId                         ] = useState ( 0 );
  const [ file                       , setFile                       ] = useState ( "" );
  const [ name                       , setName                       ] = useState ( "" );
  const [ description                , setDescription                ] = useState ( "" );
  const [ choosen                    , setChoosen                    ] = useState ( false );
  const [ authorCertification        , setAuthorCertification        ] = useState ( false );
  const [ cameraBrand                , setCameraBrand                ] = useState ( "" );
  const [ cameraModel                , setCameraModel                ] = useState ( "" );
  const [ newState,                    setNewState                   ] = useState ( 2 );

  const [ messageName                , setMessageName                ] = useState('');
  const [ messageAuthorCertification , setMessageAuthorCertification ] = useState('');

  
  const [ rank                       , setRank                       ] = useState ( ( typeof ( index ) == "undefined" ) ? -1 : index );
  const [ imageUrl                   , setImageUrl                   ] = useState ( "" );
  const [ imageUrlZoom               , setImageUrlZoom               ] = useState ( "" );
  
  const [ showProgress               , setShowProgress               ] = useState ( false );
  const [ showZoom                   , setShowZoom                   ] = useState ( false );
  const [ showError                  , setShowError                  ] = useState ( false );
  const [ errorMessage               , setErrorMessage               ] = useState ( "" );
  const [ showUpdateOk               , setShowUpdateOk               ] = useState ( false );
  const [ showUploadOk               , setShowUploadOk               ] = useState ( false );
  const [ showRemovePhoto            , setShowRemovePhoto            ] = useState ( false );

  const [ firstRender                , setFirstRender                ] = useState ( true );

  const [ updateButtonClass          , setUpdateButtonClass          ] = useState ( "styles.little_trigger" );

  const [isBlinking, setIsBlinking] = useState(false);

  const [modifyMode, setModifyMode] = useState( ( newState == 2 ? false : true ) );

  useEffect(() => {

    if ( firstRender )
     {
      if ( typeof ( index ) == "undefined" )
       {
        setRank ( -1 );
       }
      
  
	  
      setFirstRender (false);
     }
	 
      getInfos ();	
	  
  } ); 

  const getInfos = () => {

    if ( rank == -1 )
          {
           setImageUrl            ( "" );
           setImageUrlZoom        ( "" );
          
           setId                  ( 0 );
           setFile                ( "" );
           setName                ( "" );
           setDescription         ( "" );
           setChoosen             ( false );
           setAuthorCertification ( false );
           setCameraBrand         ( "" );
           setCameraModel         ( "" );
           setNewState            ( 2 );
          }
     else {    
           getUrls ();
		  
           setId                  ( registration.photos[rank].id                  );
           setFile                ( registration.photos[rank].file                );
           setName                ( registration.photos[rank].name                );
           setDescription         ( registration.photos[rank].description         );
           setChoosen             ( registration.photos[rank].choosen             );
           setAuthorCertification ( registration.photos[rank].authorCertification );
           setCameraBrand         ( registration.photos[rank].cameraBrand         );
           setCameraModel         ( registration.photos[rank].cameraModel         );
           setNewState            ( registration.photos[rank].newState         );
          }
  }
  
  const getUrls = () => {
      
    if ( rank == -1 )
          {
           setImageUrl     ( "" );
           setImageUrlZoom ( "" );
          }
     else {    
           const fileName   = registration.photos[rank].file;   

           const serveur    = new String ( "https://photos.lovelightchallenge.com/" );   
           const extension1 = new String ( "_mini.jpeg" );   
           const extension2 = new String ( ".jpeg" );   
    
           setImageUrl     ( serveur.concat(fileName.toString()).concat(extension1.toString()) );
           setImageUrlZoom ( serveur.concat(fileName.toString()).concat(extension2.toString()) );
          }
  }

  const handleImageUploadStart = () => {
  
    setShowProgress(true);
    setShowError(false);
  };

  const handleImageUploadFinish = ( num: number ) => {
  
    setShowProgress(false);
    setShowError(false);
        setShowUploadOk( false );

    if ( typeof (onNewPhotoUpload) != "undefined" )
     {
      onNewPhotoUpload ();
     }
  };

  const handleImageUploadError = ( num:   number ,
                                   error: string ) => {
  
    setShowProgress(false);
    setShowError(true);

	if ( num == 400 ) 
	 { 
	  setErrorMessage (error) ; 
	 }

	if ( num == 500 ) 
	 { 
	  setErrorMessage ("Le serveur a rencontré une erreur interne lors du traitement de votre photo") ; 
	 }
	
  };
  
  const handlePhotoRemoveClick1 = () => {
  
   setShowRemovePhoto (true);
   setShowUpdateOk (false);
   setShowUploadOk (false);
  }

  const handlePhotoRemoveClick2 = async () => {
    
    const baseUrl  = `${API_URLS.DeletePhoto}`; // "https://api.lovelightfactory.com/api/photos/";

    const url = `${baseUrl}${id}`;

    const headers = {
          'Authorization': `Bearer ${authentication.token}`,
      };


    try {

        const response = await axios.delete(url, { headers: headers });

        dispatch ( removePhoto ( rank ) );

	    if ( typeof (onPhotoRemove) != "undefined" )
         {
          onPhotoRemove ();
         }

      } 
      catch (error) {

      }
  }

  const handlePhotoZoomClick = () => {
   
    setShowZoom( true );
  }

  const handlePhotoInformationUpdateClick = async () => {
   
    var test = true;

	if ( name == "" )
	 {
	  setMessageName ("Vous devez nommer votre photo");
	  test = false;
	 }
	 
	if ( authorCertification )
	      {
           setMessageAuthorCertification ("");
		  }
     else {	
           setMessageAuthorCertification ("Validation obligatoire");
		  }

    if ( test )
     {
      const name                =   registration.photos[rank].name;
      const description         =   registration.photos[rank].description;
      const choosen             = ( registration.photos[rank].choosen             ? "true" : "false" );
      const authorCertification = ( registration.photos[rank].authorCertification ? "true" : "false" );
      const cameraBrand         =   registration.photos[rank].cameraBrand;
      const cameraModel         =   registration.photos[rank].cameraModel;

      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${authentication.token}`,
      };
 
      const baseUrl     = `${API_URLS.PatchPhoto}`; // "https://api.lovelightfactory.com/api/photos/"

      const url = `${baseUrl}${id}`;
 
 
      try {

      if (!isBlinking) {
        setIsBlinking(true);

        setTimeout(() => {

         setIsBlinking(false);
      }, 2000);
     }

        const response = await axios.patch (
        
                                            // 'https://webhook.site/292cb8db-a372-4805-8bf6-93e0768063ab',
                             
                                            url,
                              
                                               { 
											     name                ,
                                                 description         ,
                                                 choosen             ,
                                                 authorCertification ,
                                                 cameraBrand         ,
                                                 cameraModel 
											   },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );


            setShowUpdateOk( true );


      } 
      catch (error) {

      }
    }

  }

  const handleClosePhotoZoomClick = () => {
   
    setShowZoom( false );
  }

  const handleCloseUpdateOkMessageClick = () => {

    setShowUpdateOk( false );
	if ( newState == 1 )
	 {
      setNewState( 2 );
	  dispatch ( updatePhotoNewState ( rank, 2 ));
	 }
  }

  const handleCloseUploadOkMessageClick = () => {

    setShowUploadOk( false );
    setNewState( 1 );
	dispatch ( updatePhotoNewState ( rank, 1 ));
  }
  
  const handleModifyModeClick = () => {
  
    setModifyMode (!modifyMode);

    console.log ( modifyMode ? "Modify mode" : "locked" );
  }

  const handleClosePhotoRemoveValidClick = () => {
   
   handlePhotoRemoveClick2 ();
   setShowRemovePhoto (false);
  }

  const handleClosePhotoRemoveCancelClick = () => {

   setShowRemovePhoto (false);
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setName (e.target.value) 
    dispatch ( updatePhotoName ( rank, e.target.value) );
	
	setMessageName ("");

	if ( e.target.value == "" )
	 {
	  setMessageName ("Vous devez nommer votre photo");
	 }
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setDescription (e.target.value) 
    dispatch ( updatePhotoDescription (rank, e.target.value) );
  };

  const handleCameraBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCameraBrand (e.target.value) 
    dispatch ( updatePhotoCameraBrand (rank, e.target.value) );
  };

  const handleCameraModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCameraModel (e.target.value) 
    dispatch ( updatePhotoCameraModel (rank, e.target.value) );
  };

  const handleAuthorCertificationChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthorCertification(e.target.checked);
    dispatch ( updatePhotoAuthorCertification (rank, e.target.checked) );
	

	if (e.target.checked)
	      {
           setMessageAuthorCertification ("");
		  }
     else {	
           setMessageAuthorCertification ("Validation obligatoire");
		  }
  };

  const handleChoosenChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoosen(e.target.checked);
    dispatch ( updatePhotoChoosen (rank, e.target.checked) );
  };



  const buttonClass = isBlinking ? classNames( styles.blinking ) : classNames( '' );

  return (
  
    <div className={classNames(styles.root, className)}>
    
      { 
        showZoom && 
        ( <WindowPhoto onClickOnCloseButton = {handleClosePhotoZoomClick} url = {imageUrlZoom} /> ) 
      }
    
      {
        showUpdateOk &&
        ( <WindowMessage onClickOnCloseButton = {handleCloseUpdateOkMessageClick} 
		                 message = "Votre photo a bien été enregistrée. Vous avez jusqu'au 30 septembre pour la modifier" /> )
      }

      {
        showRemovePhoto &&
        ( <WindowQuestion onClickOnCancelButton = {handleClosePhotoRemoveCancelClick} 
		                  onClickOnValidButton  = {handleClosePhotoRemoveValidClick} 
		                  message = "Etes-vous sûr de vouloir supprimer l'image ?"       /> )
      }

     
	  

		


      <Uploady  destination       = { { url:     (`${API_URLS.PostPhoto}`) ,
                                        params:  { user: (authentication.login) }                ,            
                                        headers: { 'Authorization': (`Bearer ${authentication.token}`) }
                                    } }
                forceJsonResponse = { true                                                      } >

        { 
          ( rank != -1 ) ?
          ( <div className={classNames(styles.photo_nom, className)} >Proposition n°{(rank+1)}</div> ) :
		  ( <></> )
		}
		
        <div className={classNames(styles.bloc, className)}>

          <div className={classNames(styles.bloc_ligne_photo, className)}>
          
            { 
              showProgress ? 
              ( 
			    <div className={classNames(styles.progressGauge, className)}>
                 <ProgressGauge className={classNames(styles.progress, className)} /> 
			    </div>
              ) 
              : 
			  (
			    showError ? 
			     (
                   <div className={classNames(styles.placeholder_photo, className)}>
                    <p className={classNames(styles.errorMessage, className)}>
					 {errorMessage}
					</p>
					<p className={classNames(styles.placeholder_photo, styles.errorMessage, className)}>
					 Veuillez téléchargez une photo au format JPEG dont la taille ne dépasse pas 48 Mo.
					</p>
				   </div> 
			     )
			     :
                 ( imageUrl ? 
                    ( 
                      <img src={imageUrl} 
				           className={classNames(styles.vignette, className)} 
				 	       onClick= {handlePhotoZoomClick}/> 
                    ) 
                    :  
                    ( 
                      <div className={classNames(styles.placeholder_photo, className)}>Téléchargez une photo au format JPEG dont la taille ne dépasse pas 48 Mo.</div> 
                    )
                 ) 
			  )
			  
            }
          
          </div>

          {
            imageUrl ? 
            ( 
              <>
			  
			  <div className={classNames(styles.bloc_ligne_info, className)}>

                <div className={classNames(styles.grid, className)}>
				

                   <div className={classNames(styles.name_nom, className)}>
                    Nom de la photo<i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                   </div> 

                   <div className={classNames(styles.name_data, className)}>
                      <Field    className={classNames(styles.field, className)}
					            name        = ""
                                type        = "text"
                                placeholder = ""
                                value       = {name}
                                error       = {messageName}
                                onChange    = {handleNameChange} 
								readOnly    = {( !modifyMode && newState == 2 )}/>
                   </div> 

                   <div className={classNames(styles.cameraBrand_nom, className)}>
                    Marque de l'appareil photo
                   </div> 

                   <div className={classNames(styles.cameraBrand_data, className)}>
                      <Field    className={classNames(styles.field, className)}
					            name        = ""
					            type        = "text"
                                placeholder = ""
                                value       = {cameraBrand}
                                error       = ""
                                onChange    = {handleCameraBrandChange}
								readOnly    = {( !modifyMode && newState == 2 )}/>
                   </div>
				   
                   <div className={classNames(styles.cameraModel_nom, className)}>
                    Modèle de l'appareil photo
                   </div>
				   
                   <div className={classNames(styles.cameraModel_data, className)}>
                      <Field    className={classNames(styles.field, className)}
					            name        = ""
					            type        = "text"
                                placeholder = ""
                                value       = {cameraModel}
                                error       = ""
                                onChange    = {handleCameraModelChange}
								readOnly    = {( !modifyMode && newState == 2 )}/>
                   </div>
                   
                   <div className={classNames(styles.description, className)}>
                      <TextArea name        = "Votre commentaire sur cette photo"
                                placeholder = ""
                                value       = {description}
                                error       = ""
                                onChange    = {handleDescriptionChange} 
								classNameT  = {classNames(styles.commentaires, className)}
								readOnly    = {( !modifyMode && newState == 2 )}/>
                   </div>

                </div>

              </div>

              <div className={classNames(styles.bloc_ligne_certif, className)}>

                Je certifie être l'auteur de cette photo
                
				<Toggle checked    = {authorCertification}
                        onChange   = {handleAuthorCertificationChange} 
						error      = {messageAuthorCertification}      
						readOnly    = {( !modifyMode && newState == 2 )}/>

              </div>
			  
              <div className={classNames(styles.bloc_ligne_boutons, className)}>

                {
                  ( newState == 2 ) &&
                  ( <Trigger link      = "#"
                             name      = "Modifier" 
                             className = {classNames(styles.little_trigger, className)}
                             onClick   = {handleModifyModeClick}                       /> )
                }


                <Trigger link      = "#"
                         name      = "Supprimer" 
                         className = {classNames(styles.little_trigger, className)}
                         onClick   = {handlePhotoRemoveClick1}                       />

                <Trigger link      = "#"
                         name      = "Enregistrer" 
                         className = {classNames(styles.little_trigger, `${buttonClass}`, className)}
                         onClick   = {handlePhotoInformationUpdateClick}            
						 disabled  = {!authorCertification }              />

              </div>
			  
			  </>
            ) 
            : 
            ( 
              <div className={classNames(styles.bloc_ligne_boutons, className)}>

                <UploaderButton className      = { className               }
                                name           = { "Télécharger"           }
                                icon           = { faUpload                }
                                onUploadStart  = { handleImageUploadStart  }
                                onUploadFinish = { handleImageUploadFinish } 
								onUploadError  = { handleImageUploadError  }/> 

              </div>
            )
          }
            
        </div>
         
      </Uploady>
    
    </div>
  );
};

export default Photo;
