import   React                ,  
       { useEffect            , 
         useState             }  from "react";
import { Link                 ,
         useNavigate          }  from 'react-router-dom';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import   Toggle                  from "react-native-toggle-element";   //  https://github.com/mymai91/react-native-toggle-element
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                , 
       { AxiosError           }  from 'axios';
import { Container            , 
         Typography           , 
         Grid                 , 
         Switch               }  from '@mui/material';

import   styles                  from './account.module.scss';

import { initRegistration     ,
         updateId             ,  
         updateEmail          ,  
         updatePassword       , 
         updatePassword2      , 
         updateFirstname      , 
         updateLastname       , 
         updateSex            , 
         updateBirthday       , 
         updateAddress        , 
         updateAddress2       , 
         updateZipCode        ,
         updateCity           , 
         updatePhoneNumber    ,
         updateWebsite        , 
         updateInstagram      , 
         updateFacebook       , 
         updateTiktok         , 
         updateBio            ,
         verifyRegistration   ,
		 addPhoto             }  from '../../redux/registrationManagment/actionCreators';

import { initAuthentication   ,
         setLogin             , 
         setToken             ,
		 setState             }  from '../../redux/authenticationManagment/actionCreators';

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';

import { Field                }  from '../widget_field/field';
import { Select               }  from '../widget_select/select';
import { TextArea             }  from '../widget_textarea/textarea';
import { Trigger              }  from '../widget_trigger/trigger';
import { Menus                }  from '../bloc_menus/menus';
import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';

import { TopBannerAccount     }  from '../bloc_top_banner_account/top_banner_account';
import { Album                }  from '../bloc_album/album';
import { ProfilEdition        }  from '../bloc_profil_edition/profil_edition';
import { PasswordEdition      }  from '../bloc_password_edition/password_edition';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

interface Photo {
  id:                  number ,
  file:                string ,
  name:                string ,
  description:         string ,
  cameraBrand:         string ,
  cameraModel:         string ,
  choosen:             string ,
  authorCertification: string

}

export interface AccountProps {
  className?: string;
}

export const Account = ({ className }: AccountProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );

  const [ email                 , setEmail              ] = useState('');
  const [ firstname             , setFirstname          ] = useState('');
  const [ lastname              , setLastname           ] = useState('');

  const [ verify                , setVerify             ] = useState(true);

  const [ showCookiesParameters , setCookiesParameters  ] = useState(false);
  const [ cookies               , setCookie             ] = useCookies(['CookiesConsent']);

  const [ showMessageError      , setShowMessageError   ] = useState(false);
  const [ showMessageAck        , setShowMessageAck     ] = useState(false);
  
  const [ firstRender           , setFirstRender        ] = useState(true);

  // 0 : Etat normal
  // 1 : Modification du profil
  // 2 : Modification du mot de passe

  const [ pageState             , setPageState          ] = useState(0);
  const [ passwordModified      , setPasswordModified   ] = useState(false);

  const [ render                , setRender             ] = useState(0);
  const [ key                   , setKey                ] = useState(0);

  const [ stateAccount          , setStateAccount       ] = useState(authentication.state);


  useEffect(() => {

    if ( authentication.login == "" || 
         authentication.token == "" || 
		 authentication.state == 0  )
          {
           navigate ("/connexion");
          }
	 else {
           if ( firstRender )
            {
             if ( document.cookie )
                   {
                    setCookiesParameters (false);
                   }
              else {
                    setCookiesParameters (true);
                   }

             if ( stateAccount !== 2 )
			  {
			   getInfos (authentication.login);
			  }
			  
   	 	    // getImages (authentication.login);
             setFirstRender (false);
	         setPageState(0);
            }

		  }
		  
	  
  }); 

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

  const getInfos = async (email: string) => {
  
    console.log ("Account:getInfos");
  
    const baseUrl  = `${API_URLS.GetInfosUserByEmail}?`; // "https://api.lovelightfactory.com/api/by_email?";
    const nomInfo1 = "email="; 
    const valInfo1 = encodeURIComponent (email);      

    const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    const headers = {
          'Authorization': `Bearer ${authentication.token}`,
    };


    try {
      const response = await axios.get(url, { headers: headers });
      const statut = response.status;


      if ( statut == 200 )
       {
        dispatch ( updateId          (   response.data.id                        ) );
        dispatch ( updateEmail       (   response.data.email                     ) );
        dispatch ( updatePassword    (   ""                                      ) );
        dispatch ( updatePassword2   (   ""                                      ) );
        dispatch ( updateFirstname   (   response.data.firstname                 ) );
        dispatch ( updateLastname    (   response.data.lastname                  ) );
        dispatch ( updateSex         (   response.data.sex                       ) );
        dispatch ( updateBirthday    (   response.data.birthday.substring (0,10) ) );
        dispatch ( updateAddress     (   response.data.address                   ) );
        dispatch ( updateAddress2    (   response.data.address2                  ) );
        dispatch ( updateZipCode     (   response.data.zipCode                   ) );
        dispatch ( updateCity        (   response.data.city                      ) );
        dispatch ( updatePhoneNumber (   response.data.phoneNumber               ) );
        dispatch ( updateWebsite     (   response.data.website                   ) );
        dispatch ( updateInstagram   (   response.data.instagram                 ) );
        dispatch ( updateFacebook    (   response.data.facebook                  ) );
        dispatch ( updateTiktok      (   response.data.tiktok                    ) );
        dispatch ( updateBio         (   response.data.bio                       ) );
        
        setEmail       (response.data.email);
        setFirstname   (response.data.firstname);
        setLastname    (response.data.lastname);
		
		
		const baseUrl  = `${API_URLS.GetPhotosByEmail}?`; // "https://api.lovelightfactory.com/api/photosbyemail?";
        const nomInfo1 = "email="; 
        const valInfo1 = encodeURIComponent (email);      

        const url = `${baseUrl}${nomInfo1}${valInfo1}`;


        try {
             const response = await axios.get(url, { headers: headers });
             const statut = response.status;

             if ( statut == 200 )
              {
			   const userPhotos = response.data['hydra:member'];

			   userPhotos.map((photo:Photo) => {
			    
			      dispatch ( 
				    addPhoto (   
					    photo.id                              ,
                        photo.file                            ,
                        photo.name                            ,
                        photo.description                     ,
                      ( photo.choosen             == "true" ) ,
                      ( photo.authorCertification == "true" ) ,
                        photo.cameraBrand                     ,
						photo.cameraModel                     ,
                        2                                     ,
				        ""                                    ,
						0                                     ,
						0                                     ,
						0 
						) 
					);
	            })
              }
			  
		   setRender ( render + 1 );
        } 
        catch (error) {

           // setShowMessageAck   ( false );     
           // setShowMessageError ( true );     
        }
		
        dispatch ( setState (2) );
        setStateAccount (2);

       }		
    } 
    catch (error) {

      setShowMessageAck   ( false );     
      setShowMessageError ( true );     
    }
  };

  const getImages = async (email: string) => {
  
		const baseUrl  = `${API_URLS.GetPhotosByEmail}?`; // "https://api.lovelightfactory.com/api/photosbyemail?";
        const nomInfo1 = "email="; 
        const valInfo1 = encodeURIComponent (email);      

        const url = `${baseUrl}${nomInfo1}${valInfo1}`;

            const headers = {
          'Authorization': `Bearer ${authentication.token}`,
    };

        try {
             const response = await axios.get(url, { headers: headers });
             const statut = response.status;

             if ( statut == 200 )
              {
			   const userPhotos = response.data['hydra:member'];

			   userPhotos.map((photo:Photo) => {
			    
			      dispatch ( 
				    addPhoto (   
					    photo.id                              ,
                        photo.file                            ,
                        photo.name                            ,
                        photo.description                     ,
                      ( photo.choosen             == "true" ) ,
                      ( photo.authorCertification == "true" ) ,
                        photo.cameraBrand                     ,
						photo.cameraModel                     ,
                        2                                     ,
  					    ""                                    ,
						0                                     ,
						0                                     ,
						0                                     
						) 
					);
	            })
              }
			  
		   setRender ( render + 1 );
        } 
        catch (error) {

           // setShowMessageAck   ( false );     
           // setShowMessageError ( true );     
        }
       		
    


  };
  
  const handleCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };
     
  const handleLogoutClick = () => {

    dispatch ( initAuthentication () );
    dispatch ( initRegistration   () );
        
    navigate("/");
  };
         
  const handlePageStateChange = ( num: number ) => {
  
    setPageState ( num );
  }  
        
  const handleAlbumChange = () =>
   {

   
   setKey((prevKey) => prevKey + 1);

	
   }
		
  return (

        <>
          
              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>

                <TopBannerAccount className              = { classNames(className) } 
                                  firstname              = { firstname             }  
						          lastname               = { lastname              }
						          email                  = { email                 }
								  pageState              = { pageState             }
                                  onPageStateChange      = { handlePageStateChange } />
							
                <br/>
			  
			    {  pageState === 0   && <Album           onAlbumChange     = { handleAlbumChange } />                                                         }
                {  pageState === 1   && <ProfilEdition   onPageStateChange = { handlePageStateChange } /> }
                {  pageState === 2   && <PasswordEdition onPageStateChange = { handlePageStateChange } /> }
				  
               <div className={classNames(styles.espace_bas, className)}>
               </div>
        
            </div>
			
          </div>
        
        </>
            
  );
};

export default Account;
