import   React               from "react";
import   classNames          from 'classnames';
import { Link        , 
         useNavigate }  from 'react-router-dom';
import   styles              from './candidats.module.scss';


export interface CandidatsProps {
  className?:         string;                 // classe CSS complémentaire
}

export const Candidats = ({ className  }: CandidatsProps) => {

  return (
  
    <div className={classNames(styles.root, className)}>

      <div className={classNames(styles.contain, className)}>

      <div className={classNames(styles.espace_haut, className)}>
      </div>
	  
      <p className={classNames(styles.texte_2, className)}>
		Les 15 candidats préséléctionnés de la saison 2024...
      </p>
      
      <br/>
      <br/>
      <br/>

      <div className={classNames(styles.photos, className)}>

        <div className={classNames(styles.photo1, className)}>
	      <img src= "/images/Vignette_Web_Vincent.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Vincent"/>
        </div>

        <div className={classNames(styles.photo2, className)}>
	      <img src= "/images/Vignette_Web_Samuel.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Samuel"/>
        </div>
		

        <div className={classNames(styles.photo3, className)}>
	      <img src= "/images/Vignette_Web_Olivier.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Olivier"/>
        </div>

       <div className={classNames(styles.photo4, className)}>
	      <img src= "/images/Vignette_Web_Maxime.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Maxime"/>
        </div>

       <div className={classNames(styles.photo5, className)}>
	      <img src= "/images/Vignette_Web_Mathias.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Mathias"/>
        </div>

       <div className={classNames(styles.photo6, className)}>
	      <img src= "/images/Vignette_Web_Hugo.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Hugo"/>
        </div>

       <div className={classNames(styles.photo7, className)}>
	      <img src= "/images/Vignette_Web_Frederic.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Frederic"/>
        </div>

       <div className={classNames(styles.photo8, className)}>
	      <img src= "/images/Vignette_Web_Eliott.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Olivier"/>
        </div>

       <div className={classNames(styles.photo9, className)}>
	      <img src= "/images/Vignette_Web_Cyrille.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Cyrille"/>
        </div>

       <div className={classNames(styles.photo10, className)}>
	      <img src= "/images/Vignette_Web_Beatrice.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Beatrice"/>
        </div>

       <div className={classNames(styles.photo11, className)}>
	      <img src= "/images/Vignette_Web_Bastian.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Bastian"/>
        </div>

       <div className={classNames(styles.photo12, className)}>
	      <img src= "/images/Vignette_Web_Aymeric.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Aymeric"/>
        </div>

       <div className={classNames(styles.photo13, className)}>
	      <img src= "/images/Vignette_Web_Arnaud.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Olivier"/>
        </div>

       <div className={classNames(styles.photo14, className)}>
	      <img src= "/images/Vignette_Web_Amaury.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Amaury"/>
        </div>

       <div className={classNames(styles.photo15, className)}>
	      <img src= "/images/Vignette_Web_Alexandre.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Alexandre"/>
        </div>


       <div className={classNames(styles.photo15, className)}>
	      <img src= "/images/Vignette_Web_Olivier.jpg"
		       className={classNames(styles.image, className)}
		       alt="Vignette_Web_Olivier"/>
        </div>

      </div>
	  
	  <div className={classNames(styles.espace_bas, className)}>
      </div>

	  
	  </div>

    </div>
           
  );
};

export default Candidats;
