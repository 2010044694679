import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import { Link                 }  from 'react-router-dom';

import   styles                  from './legal_notice.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';

export interface LegalNoticeProps {
  className?: string;
}

export const LegalNotice = ({ className }: LegalNoticeProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
  }); 
  

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

  return (
  
        <>

              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>

            <div className={classNames(styles.texte, className)}>

    
        <h1>MENTIONS LÉGALES</h1>
		
        <h2>ÉDITEUR & HÉBERGEMENT</h2>

        <p>Éditeur : Lovelight Factory</p>

        <p>Le site lovelightchallenge.com est édité par Lovelight Factory 
		   SARL, au capital de 1000 euros, dont le siège social se trouve :</p>

        <p>1 place de la fontaine – 06610 La Gaude – France</p>

        <p>Immatriculée au RCS de Grasse<br/> 
		   sous le numéro 978 665 511.<br/>
		   contact: team@lovelightchallenge.com</p>

        <p>Hébergement : Scaleway</p>

        <p>Société par Actions Simplifiée au capital de 214 410,50 Euros</p>

        <p>SIREN : 433 115 904 RCS Paris</p>

        <p>Siège social : 8 rue de la Ville l’Evêque, 75008 Paris</p>

        <p><Link to="https://www.scaleway.com/fr/" target="__blank">https://www.scaleway.com/fr/</Link></p>

        <p>CRÉDITS</p>

        <p>Design & Développement : Lovelight Factory</p>

              </div>
		
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>
            
  );
};

export default LegalNotice;
