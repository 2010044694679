import { Action, ActionType, Ensemble, Photo } from './actionTypes';


interface State 
 {
  ensemble: Ensemble;
 }

const initialState = 
 {
  ensemble: 
   {
	photos:      []
   } 
 }

const VoteManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_ENSEMBLE:          state.ensemble.photos      = []
										
                                            return state;

	case ActionType.ADD_PHOTO:              state.ensemble.photos      = [...state.ensemble.photos, action.newPhoto];

									        return state;

	case ActionType.UPDATE_PHOTO_SELECTION: state.ensemble.photos[action.num].selected = action.newSelected;

									        return state;
											  
    default:                                return state;              
   }
 }


export default VoteManagmentReducer;
