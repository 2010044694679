import   React               from "react";
import   classNames          from 'classnames';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import   styles              from './bloc_countdown6.module.scss';

import { Trigger              }  from '../widget_trigger/trigger';


export interface BlocCountdown6Props {
  className?: string;
}

export const BlocCountdown6 = ({ className  }: BlocCountdown6Props) => {

  return (
        
           <div className={classNames(styles.root, className)}>
		   
             <div className={classNames(styles.photo_overlay, className)}>
               
			   <img src="/images/LovelightChallenge_decale.png" 
			        className={classNames(styles.logo_challenge, className)}
			        alt="logo LovelightChallenge"/>
               
			   <div className={classNames(styles.text_overlay, className)}>
			    
				
			      <FlipClockCountdown to        = {new Date('2024-10-31T22:00:00').getTime()} 
				                      className = {classNames(styles.flip_clock, className)}
									  labels    = {['Jours', 'Heures', 'Minutes', 'Secondes']}            />
									  
				  <p  className={classNames(styles.text_bas, className)}>
 				   avant la clôture des candidatures
				  </p>

                  <Trigger name        = "PARTICIPEZ"
                           link        = "/inscription"
                           className   = {classNames(styles.trigger_registration, className)} />

		   	   </div>
			   
             </div>
			 
           </div>
          
		 );
};

export default BlocCountdown6;
