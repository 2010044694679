import   React                ,  
       { useEffect            , 
         useState             }  from "react";
import { Link                 ,
         useNavigate          }  from 'react-router-dom';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import   Toggle                  from "react-native-toggle-element";   //  https://github.com/mymai91/react-native-toggle-element
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                , 
       { AxiosError           }  from 'axios';
import { Container            , 
         Typography           , 
         Grid                 , 
         Switch               }  from '@mui/material';

import   styles                  from './account2.module.scss';

import { initRegistration     ,
         updateId             ,  
         updateEmail          ,  
         updatePassword       , 
         updatePassword2      , 
         updateFirstname      , 
         updateLastname       , 
         updateSex            , 
         updateBirthday       , 
         updateAddress        , 
         updateAddress2       , 
         updateZipCode        ,
         updateCity           , 
         updatePhoneNumber    ,
         updateWebsite        , 
         updateInstagram      , 
         updateFacebook       , 
         updateTiktok         , 
         updateBio            ,
         updateCombinaison    ,
         updateRang           ,
         updateExAequo        ,
         verifyRegistration   ,
		 addPhoto             }  from '../../redux/registrationManagment/actionCreators';

import { initAuthentication   ,
         setLogin             , 
         setToken             ,
		 setState             }  from '../../redux/authenticationManagment/actionCreators';

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';

import { Field                }  from '../widget_field/field';
import { Select               }  from '../widget_select/select';
import { TextArea             }  from '../widget_textarea/textarea';
import { Trigger              }  from '../widget_trigger/trigger';
import { Menus                }  from '../bloc_menus/menus';
import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';

import { TopBannerAccount2     }  from '../bloc_top_banner_account2/top_banner_account2';
import { Album2                }  from '../bloc_album2/album2';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

interface Photo {
  id:                  number ,
  file:                string ,
  name:                string ,
  description:         string ,
  cameraBrand:         string ,
  cameraModel:         string ,
  choosen:             string ,
  authorCertification: string

}

export interface Account2Props {
  className?: string;
}

export const Account2 = ({ className }: Account2Props) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );

  const [ email                 , setEmail              ] = useState('');
  const [ firstname             , setFirstname          ] = useState('');
  const [ lastname              , setLastname           ] = useState('');
  const [ rang                  , setRang               ] = useState(0);
  const [ exAequo               , setExAequo            ] = useState(0);

  const [ verify                , setVerify             ] = useState(true);

  const [ showCookiesParameters , setCookiesParameters  ] = useState(false);
  const [ cookies               , setCookie             ] = useCookies(['CookiesConsent']);

  const [ showMessageError      , setShowMessageError   ] = useState(false);
  const [ showMessageAck        , setShowMessageAck     ] = useState(false);
  
  const [ firstRender           , setFirstRender        ] = useState(true);

  // 0 : Etat normal
  // 1 : Modification du profil
  // 2 : Modification du mot de passe


  const [ render                , setRender             ] = useState(0);
  const [ key                   , setKey                ] = useState(0);

  const [ stateAccount          , setStateAccount       ] = useState(authentication.state);


  useEffect(() => {

    if ( authentication.login == "" || 
         authentication.token == "" || 
		 authentication.state == 0  )
          {
           navigate ("/connexion");
          }
	 else {
           if ( firstRender )
            {
             if ( document.cookie )
                   {
                    setCookiesParameters (false);
                   }
              else {
                    setCookiesParameters (true);
                   }

             if ( stateAccount !== 2 )
			  {
			   getInfos (authentication.login);
			  }
			  
   	 	    // getImages (authentication.login);
             setFirstRender (false);
            }

		  }
		  
	  
  }); 

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

  const getInfos = async (email: string) => {
  
  //  console.log ("Account:getInfos");
  
    const baseUrl  = `${API_URLS.GetInfosUserByEmail}?`; // "https://api.lovelightfactory.com/api/by_email?";
    const nomInfo1 = "email="; 
    const valInfo1 = encodeURIComponent (email);      

    const url = `${baseUrl}${nomInfo1}${valInfo1}`;

    const headers = {
          'Authorization': `Bearer ${authentication.token}`,
    };

	dispatch ( initRegistration () );
			  

    try {
      const response = await axios.get(url, { headers: headers });
      const statut = response.status;


      if ( statut == 200 )
       {
        dispatch ( updateId          (   response.data.id                        ) );
        dispatch ( updateEmail       (   response.data.email                     ) );
        dispatch ( updatePassword    (   ""                                      ) );
        dispatch ( updatePassword2   (   ""                                      ) );
        dispatch ( updateFirstname   (   response.data.firstname                 ) );
        dispatch ( updateLastname    (   response.data.lastname                  ) );
        dispatch ( updateSex         (   response.data.sex                       ) );
        dispatch ( updateBirthday    (   response.data.birthday.substring (0,10) ) );
        dispatch ( updateAddress     (   response.data.address                   ) );
        dispatch ( updateAddress2    (   response.data.address2                  ) );
        dispatch ( updateZipCode     (   response.data.zipCode                   ) );
        dispatch ( updateCity        (   response.data.city                      ) );
        dispatch ( updatePhoneNumber (   response.data.phoneNumber               ) );
        dispatch ( updateWebsite     (   response.data.website                   ) );
        dispatch ( updateInstagram   (   response.data.instagram                 ) );
        dispatch ( updateFacebook    (   response.data.facebook                  ) );
        dispatch ( updateTiktok      (   response.data.tiktok                    ) );
        dispatch ( updateBio         (   response.data.bio                       ) );
        dispatch ( updateCombinaison (   response.data.combinaison               ) );
        dispatch ( updateRang        (   response.data.rang                      ) );
        dispatch ( updateExAequo     (   response.data.exAequo                   ) );
        
        setEmail       (response.data.email);
        setFirstname   (response.data.firstname);
        setLastname    (response.data.lastname);
        setRang        (response.data.rang);
        setExAequo     (response.data.exAequo);
		
		
		const baseUrl  = `${API_URLS.GetPhotosByEmail}?`; // "https://api.lovelightfactory.com/api/photosbyemail?";
        const nomInfo1 = "email="; 
        const valInfo1 = encodeURIComponent (email);      

        const url = `${baseUrl}${nomInfo1}${valInfo1}`;

        try {
             const response = await axios.get(url, { headers: headers });
             const statut = response.status;

             if ( statut == 200 )
              {
			   const userPhotos = response.data['hydra:member'];

               for ( let p = 0 ; p < userPhotos.length ; p++ )
			    {
				 const photo = userPhotos[p];
				 
    			 dispatch ( 
				                   addPhoto (   
					                            photo.id                              ,
                                                photo.file                            ,
                                                photo.name                            ,
                                                photo.description                     ,
                                              ( photo.choosen             == "true" ) ,
                                              ( photo.authorCertification == "true" ) ,
                                                photo.cameraBrand                     ,
						                        photo.cameraModel                     ,
                                                2                                     ,
												photo.reviewState ,
						                        photo.nbVotes                      ,
												photo.rang                         ,
												photo.exAequo												
						                    ) 
					      );

			    }
				
			 //  console.log ("fin");
				
		       setRender ( render + 1 );
			  }
            } 
        catch (error) {

           // setShowMessageAck   ( false );     
           // setShowMessageError ( true );     
        }
						  

        /*

      //  console.log (url);
        try {
             const response = await axios.get(url, { headers: headers });
             const statut = response.status;

           //  console.log (response);

             if ( statut == 200 )
              {
			   const userPhotos = response.data['hydra:member'];

             //  console.log (userPhotos);

               for ( let p = 0 ; p < userPhotos.length ; p++ )
			    {
              //   console.log ("p=", p);

                 const photo = userPhotos[p];

              //   console.log (photo);
				 
				 
		         //const baseUrlb  = `${API_URLS.GetVotesForPhoto}?`; // "https://api.lovelightfactory.com/api/photosbyemail?";
                 //const nomInfo1b = "photo_id="; 
                 //const valInfo1b = encodeURIComponent (photo.id);      

                 //const urlb = `${baseUrlb}${nomInfo1b}${valInfo1b}`;
                 
				 
                 const valInfo1b = encodeURIComponent (photo.id);      
		         const baseUrlb  = `${API_URLS.GetPositionForPhoto}`; 

                 const urlb = `${baseUrlb}/${valInfo1b}`;
				 
                 try {
                      const responseb = await axios.get(urlb, { headers: headers });
                      const statutb = response.status;

                     // console.log ( urlb, responseb );

                      if ( statutb == 200 )
                       {
			            const position = responseb.data['hydra:member'][0];

                       // console.log ( position );
 
                        if ( typeof ( position ) == "undefined" )
						 {
    			        dispatch ( 
				                   addPhoto (   
					                            photo.id                              ,
                                                photo.file                            ,
                                                photo.name                            ,
                                                photo.description                     ,
                                              ( photo.choosen             == "true" ) ,
                                              ( photo.authorCertification == "true" ) ,
                                                photo.cameraBrand                     ,
						                        photo.cameraModel                     ,
                                                2                                     ,
												photo.reviewState ,
						                        -1                      ,
												-1                         ,
												0												
						                    ) 
					             );
						 }
						 else {
    			        dispatch ( 
				                   addPhoto (   
					                            photo.id                              ,
                                                photo.file                            ,
                                                photo.name                            ,
                                                photo.description                     ,
                                              ( photo.choosen             == "true" ) ,
                                              ( photo.authorCertification == "true" ) ,
                                                photo.cameraBrand                     ,
						                        photo.cameraModel                     ,
                                                2                                     ,
												photo.reviewState ,
						                        position.nbVotes                      ,
												position.rang                         ,
												position.exAequo												
						                    ) 
					             );
						 }
	                   }
                     }
                 catch (error) {

                   // setShowMessageAck   ( false );     
                   // setShowMessageError ( true );     
                 }
				 
			    }
				
			 //  console.log ("fin");
				
		       setRender ( render + 1 );
			  }
            } 
        catch (error) {

           // setShowMessageAck   ( false );     
           // setShowMessageError ( true );     
        }
		
		
		*/
		
        dispatch ( setState (2) );
        setStateAccount (2);

       }		
    } 
    catch (error) {

      setShowMessageAck   ( false );     
      setShowMessageError ( true );     
    }
  };

  const handleCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };
     
  const handleLogoutClick = () => {

    dispatch ( initAuthentication () );
    dispatch ( initRegistration   () );
        
    navigate("/");
  };
         
		
  return (

        <>
          
              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>

                <TopBannerAccount2 className              = { classNames(className) } 
                                   firstname              = { firstname             }  
						           lastname               = { lastname              }
						           email                  = { email                 } 
								   rang                   = { rang                  }
								   exAequo                = { exAequo               } />
							
                <br/>
			  
			    <Album2 />

				  
               <div className={classNames(styles.espace_bas, className)}>
               </div>
        
            </div>
			
          </div>
        
        </>
            
  );
};

export default Account2;
