import { Action, ActionType, Registration, Photo } from './actionTypes';


interface State 
 {
  registration: Registration;
 }

const initialState = 
 {
  registration: 
   {
    id:          0     ,
    email:       ""    ,
    password:    ""    ,
    password2:   ""    ,
    firstname:   ""    ,
    lastname:    ""    ,
    sex:         "Aucune réponse"    ,
    birthday:    ""    ,
    address:     ""    ,
    address2:    ""    ,
    zipCode:     ""    ,
    city:        ""    ,
    country:     "France" ,
    phoneNumber: ""    ,
    website:     ""    ,
    instagram:   ""    ,
    facebook:    ""    ,
    tiktok:      ""    ,
    bio:         ""    ,
    combinaison: 0     ,
    rang:        0     ,
    exAequo:     0     ,
	verify:      true  ,
	photos:      []
   } 
 }

const RegistrationManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_REGISTRATION:   state.registration.id          = 0
                                         state.registration.email       = ""
                                         state.registration.password    = ""
                                         state.registration.password2   = ""
                                         state.registration.firstname   = ""
                                         state.registration.lastname    = ""
                                         state.registration.sex         = "Aucune réponse"
                                         state.registration.birthday    = ""
                                         state.registration.address     = ""
                                         state.registration.address2    = ""
                                         state.registration.zipCode     = ""
                                         state.registration.city        = ""
                                         state.registration.country     = "France"
                                         state.registration.phoneNumber = ""
                                         state.registration.website     = ""
                                         state.registration.instagram   = ""
                                         state.registration.facebook    = ""
                                         state.registration.tiktok      = ""
                                         state.registration.bio         = ""
                                         state.registration.combinaison = 0
                                         state.registration.rang        = 0
                                         state.registration.exAequo     = 0
                                         state.registration.verify      = true
										 state.registration.photos      = []
										
                                         return state;

    case ActionType.UPDATE_ID:           state.registration.id          = action.newId

                                         return state;
										
    case ActionType.UPDATE_EMAIL:        state.registration.email       = action.newEmail
 
                                         return state;
									 
    case ActionType.UPDATE_PASSWORD:     state.registration.password    = action.newPassword

                                         return state;
									 
    case ActionType.UPDATE_PASSWORD2:    state.registration.password2   = action.newPassword2

                                         return state;
									 
    case ActionType.UPDATE_FIRSTNAME:    state.registration.firstname   = action.newFirstname;
	
	                                     return state;
									 
    case ActionType.UPDATE_LASTNAME:     state.registration.lastname    = action.newLastname;
									 
									     return state;
									 
    case ActionType.UPDATE_SEX:          state.registration.sex         = action.newSex;
									 
									     return state;
									 
    case ActionType.UPDATE_BIRTHDAY:     state.registration.birthday    = action.newBirthday;
									 
									     return state;
									 
    case ActionType.UPDATE_ADDRESS:      state.registration.address     = action.newAddress;
									 
									     return state;
									 
    case ActionType.UPDATE_ADDRESS2:     state.registration.address2    = action.newAddress2;
									 
									     return state;
									 
    case ActionType.UPDATE_ZIPCODE:      state.registration.zipCode     = action.newZipCode;
									 
									     return state;
									 
    case ActionType.UPDATE_CITY:         state.registration.city        = action.newCity;
									 
									     return state;
									 
    case ActionType.UPDATE_COUNTRY:      state.registration.country     = action.newCountry;

									     return state;

    case ActionType.UPDATE_PHONENUMBER:  state.registration.phoneNumber = action.newPhoneNumber;
									 
									     return state;
									 
    case ActionType.UPDATE_WEBSITE:      state.registration.website     = action.newWebsite;
									 
									     return state;
									 
    case ActionType.UPDATE_INSTAGRAM:    state.registration.instagram   = action.newInstagram;
									 
									     return state;
									 
    case ActionType.UPDATE_FACEBOOK:     state.registration.facebook    = action.newFacebook;
									 
									     return state;
									 
    case ActionType.UPDATE_TIKTOK:       state.registration.tiktok      = action.newTiktok;
									 
									     return state;
									 
    case ActionType.UPDATE_BIO:               state.registration.bio         = action.newBio;
									 
									          return state;

    case ActionType.UPDATE_COMBINAISON:       state.registration.combinaison = action.newCombinaison;
									 
									          return state;

    case ActionType.UPDATE_RANG:              state.registration.rang        = action.newRang;
									 
									          return state;

    case ActionType.UPDATE_EXAEQUO:           state.registration.exAequo     = action.newExAequo;
									 
									          return state;

    case ActionType.VERIFY_REGISTRATION:      state.registration.verify      = action.newVerify;
									 
									          return state;
										 
	case ActionType.ADD_PHOTO:                state.registration.photos      = [...state.registration.photos, action.newPhoto];

									          return state;

	case ActionType.UPDATE_PHOTO_NAME:        state.registration.photos[action.numPhoto].name        = action.newName;

									          return state;
											   
	case ActionType.UPDATE_PHOTO_DESCRIPTION: state.registration.photos[action.numPhoto].description = action.newDescription;

									          return state;

	case ActionType.UPDATE_PHOTO_CAMERABRAND: state.registration.photos[action.numPhoto].cameraBrand = action.newCameraBrand;

									          return state;

	case ActionType.UPDATE_PHOTO_CAMERAMODEL:          state.registration.photos[action.numPhoto].cameraModel = action.newCameraModel;

									                   return state;

	case ActionType.UPDATE_PHOTO_CHOOSEN:              state.registration.photos[action.numPhoto].choosen = action.newChoosen;

									                   return state;
										 
	case ActionType.UPDATE_PHOTO_AUTHORCERTIFICATION:  state.registration.photos[action.numPhoto].authorCertification = action.newAuthorCertification;

									                   return state;

    case ActionType.UPDATE_PHOTO_NEWSTATE:             state.registration.photos[action.numPhoto].newState = action.newNewState;
									          
											           return state;

	case ActionType.REMOVE_PHOTO:                      state.registration.photos = state.registration.photos.filter ((e, i) => i !== action.numPhoto);

									                   return state;
										 
    default:                                           return state;
   }
 }


export default RegistrationManagmentReducer;
