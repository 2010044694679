import { Action, ActionType, Authentication } from './actionTypes';


interface State 
 {
  authentication: Authentication;
 }

const initialState = 
 {
  authentication: 
   {
    login:        "" ,
    token:        "" ,
	state:        0  ,
	verification: ""
   } 
 }

const AuthenticationManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.INIT_AUTHENTICATION: state.authentication.login        = ""
                                         state.authentication.token        = ""
										 state.authentication.state        = 0
                                         state.authentication.verification = ""
                                         
										 return state;

    case ActionType.SET_LOGIN:           state.authentication.login = action.login

                                         return state;
									 
    case ActionType.SET_TOKEN:           state.authentication.token = action.token

                                         return state;

    case ActionType.SET_STATE:           state.authentication.state = action.state

                                         return state;
									 
    case ActionType.SET_VERIFICATION:    state.authentication.verification = action.verification

                                         return state;
									 
    default:                             return state;
   }
 }


export default AuthenticationManagmentReducer;