import { Dispatch } from 'redux';

import { ActionType                     ,
         Authentication                 ,
         actionInitAuthentication       ,
		 actionSetLogin                 ,
         actionSetToken                 ,
         actionSetState                 ,
         actionSetVerification          ,
         DispatchTypeInitAuthentication ,
         DispatchTypeSetLogin           ,
         DispatchTypeSetToken           ,
         DispatchTypeSetState           ,
		 DispatchTypeSetVerification    } from './actionTypes';




export const initAuthentication = () => 
 {
  const action: actionInitAuthentication = 
   {
    type:   ActionType.INIT_AUTHENTICATION
   }

  return ( dispatch: DispatchTypeInitAuthentication ) => 
   {
    dispatch (action);
   }
 } 

export const setLogin = ( login: string ) => 
 {
  const action: actionSetLogin = 
   {
    type:  ActionType.SET_LOGIN,
	login: login
   }

  return ( dispatch: DispatchTypeSetLogin ) => 
   {
    dispatch (action);
   }
 } 

export const setToken = ( token: string ) => 
 {
  const action: actionSetToken =
   {
    type:  ActionType.SET_TOKEN,
	token: token
   }
 
  return ( dispatch: DispatchTypeSetToken ) => 
   {
    dispatch (action);
   }
 } 

export const setState = ( state: number ) => 
 {
  const action: actionSetState =
   {
    type:  ActionType.SET_STATE,
	state: state
   }
 
  return ( dispatch: DispatchTypeSetState ) => 
   {
    dispatch (action);
   }
 } 

export const setVerification = ( verification: string ) => 
 {
  const action: actionSetVerification =
   {
    type:         ActionType.SET_VERIFICATION,
	verification: verification
   }
 
  return ( dispatch: DispatchTypeSetVerification ) => 
   {
    dispatch (action);
   }
 } 
