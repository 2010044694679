import   React               from "react";
import   classNames          from 'classnames';
import { Link             }  from 'react-router-dom';

import   styles              from './sticky_footer.module.scss';

import { useTypedSelector }  from '../../hooks/useTypeSelector';

export interface StickyFooterProps {
  className?: string;
  page?:      string;  
}

export const StickyFooter = ({ className , page }: StickyFooterProps) => {

  return (
  
              <div className={classNames(styles.sticky, className)}>

                <Link to="/mentions-legales">
                    <button className={classNames(styles.button)}>Mentions légales</button>
                </Link>

                <Link to="/conditions-generales-d-utilisation">
                    <button className={classNames(styles.button)}>CGU</button>
                </Link>

                <Link to="/notice-d-information-sur-les-donnees-personnelles">
                    <button className={classNames(styles.button)}>Confidentialité</button>
                </Link>

                <Link to="/notice-d-information-sur-les-cookies">
                    <button className={classNames(styles.button)}>Cookies</button>
                </Link>

              </div>

          
		 );
};

export default StickyFooter;
