import   React               from "react";
import   classNames          from 'classnames';

import   styles              from './prices.module.scss';


export interface PricesProps {
  className?:         string;                 // classe CSS complémentaire
}

export const Prices = ({ className  }: PricesProps) => {

  return (

    <div className={classNames(styles.root, className)}>

      <div className={classNames(styles.contain, className)}>

        <p className={classNames(styles.titre, className)}>
            10 000€ de prix offerts par les partenaires
        </p>

        <br/>
 
        <p className={classNames(styles.texte, className)}>
            Liste des lots communiquée à partir du 15 décembre 2023
        </p> 
      
	  </div>

    </div>
           
  );
};

export default Prices;
