import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



import   styles                  from './terms_of_service.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';

export interface TermsOfServiceProps {
  className?: string;
}

export const TermsOfService = ({ className }: TermsOfServiceProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
  }); 
  

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };


  return (
  
        <>

              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
    
        <h1>CGU – Conditions générales d’utilisation</h1>
        <br/>
		
		<Accordion allowZeroExpanded className={classNames(styles.accordion, className)}>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							1. Application
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
                        Le présent site Web (ci-après « le Site Web ») est mis à disposition 
						par la société Lovelight Factory SARL (ci-après «Lovelight Factory »). 
						N'hésitez pas à parcourir ce document. L'accès au site et son utilisation 
						sont soumis aux Conditions d'utilisation répertoriées ci-dessous, ainsi 
						qu'à toute loi pertinente applicable. La présente page expose également 
						quelques informations légales supplémentaires. En accédant au Site Web et/ou 
						en l'utilisant, vous vous engagez à vous conformer aux présentes Conditions 
						d'utilisation. Veuillez lire attentivement ces Conditions d'utilisation. 
						Les données personnelles que vous pourriez être amené à nous communiquer via 
						le Site Web seront traitées conformément à notre Politique de confidentialité.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							2. Restrictions d’utilisation afférentes aux droits de propriété intellectuelle
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
                        Le Site Web contient de nombreux éléments qui sont protégés par les droits 
						d'auteur européens et internationaux et les autres droits intellectuels de la 
						propriété, tels que (mais sans s'y limiter) les marques commerciales, les noms 
						commerciaux, les logos, les photographies, les vidéos, la musique, les textes 
						et la conception graphique du Site Web de Lovelight Factory (dénommés ci-après 
						le Contenu). Sans aller à l'encontre des éventuelles restrictions découlant de 
						la législation en vigueur, les restrictions d'utilisation du Site Web suivantes 
						sont appliquées pour protéger certains droits :

                        <ul>
                         <li>Aucune partie du Site Web ne peut être copiée électroniquement ou par un 
						     autre procédé, sauf dans la mesure où cela s'avère nécessaire pour le 
							 consulter via Internet et utiliser ses services de manière efficace. La 
							 reproduction de toute partie du Site Web sur un autre site est expressément 
							 interdite.</li>
                         <li>Les marques commerciales, les noms commerciaux, les marques de service et 
						     les logos de Lovelight Factory tels qu'utilisés sur le Site Web ne peuvent 
							 en aucun cas être affichés ou utilisés sans le consentement préalable écrit 
							 de Lovelight Factory.</li>
                         <li>Lovelight Factory vous autorise à utiliser le Site Web et son Contenu pour 
						     votre usage personnel uniquement, à condition que vous conserviez tel quel 
							 l'ensemble des mentions de copyright et de propriété, et interdit toute 
							 utilisation à des fins commerciales.</li>
                         <li>En principe, des liens hypertextes pointant vers le Site Web peuvent être 
						     créés, à condition qu'ils pointent vers la page d'accueil [INSÉRER LIEN ou 
							 AJOUTER : du présent Site Web]. La création de liens hypertextes profonds 
							 ou de liens d'insertion (« in-line links ») vers d'autres parties du Site 
							 Web est interdite, sauf en cas d'accord de Lovelight Factory.</li>
                         <li>La consultation du Site Web par des moyens automatisés (avec des « robots » 
						     par exemple) afin d'y récupérer des informations de manière systématique ou 
							 de créer des liens fonctionnels entre le Contenu du Site Web et un autre 
							 site n'est pas autorisée.</li>
                         <li>La définition d'un lien hypertexte de sorte qu'un internaute voie le Site 
						     Web dans le cadre d'un autre site est interdite.</li>
                        </ul>

                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							3. Marques commerciales
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						« Lovelight Factory » est une marque commerciale française déposée de Lovelight 
						Factory SARL . Les autres marques commerciales, logos et marques de service 
						figurant sur le Site Web peuvent être des marques, déposées ou non, de Lovelight 
						Factory.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							4. Utilisation non acceptable
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Est interdite toute utilisation du Site Web ou de son Contenu pouvant gêner 
						les autres utilisateurs, enfreindre leurs droits, entraver de quelque manière 
						que ce soit le bon fonctionnement du Site Web ou avoir un effet défavorable 
						sur les informations présentées sur le Site Web et/ou sur le logiciel 
						contrôlant son fonctionnement. L'utilisation du site Web sous un faux nom ou 
						par le contournement de toute mesure de sécurité mise en place pour les parties 
						du Site Web dont l'accès est restreint n'est pas autorisée. Est interdite toute 
						utilisation du Site Web ou de son Contenu pouvant raisonnablement être 
						considérée comme constituant ou encourageant un comportement qui pourrait être 
						qualifié de délit pénal, donnant lieu à des poursuites civiles ou s'avérant de 
						quelque manière que ce soit contraire à la législation d'un pays.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							5. Liens vers des sites web tiers
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Le présent Site Web peut contenir des liens hypertextes vers d'autres sites Web 
						hors du domaine [http://lovelight.tv/]. D'autres conditions d'utilisation, 
						indiquées dans les pages consultées, s'appliquent à ces liens hypertextes. 
						Tous les liens vers des sites Web tiers sont fournis uniquement pour votre 
						confort et l'utilisation que vous en faites est à vos risques et périls. 
						Lovelight Factory n'est pas responsable du contenu des pages ainsi consultées.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							6. Responsabilité
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Lovelight Factory et toutes les autres parties concernées par la création, la 
						production ou la publication du Site Web déclinent toute responsabilité en cas 
						de perte, de dommages ou de frais, ou de dommages et intérêts directs, 
						accessoires, consécutifs, particuliers ou exemplaires découlant de votre 
						utilisation du Site Web ou des services (y compris la responsabilité en cas de 
						retard ou d'inexactitudes lors de la transmission d'informations, ou en cas 
						d'interruption, de suspension ou d'arrêt du site Web), à moins que de tels 
						dommages et intérêts soient le résultat d'une malveillance de la part de 
						Lovelight Factory.

						Lovelight Factory ne saurait être tenu pour responsable des dommages ou des 
						virus pouvant affecter votre équipement informatique suite à la consultation 
						ou l'utilisation du Site Web ou de son Contenu.

						Aucune stipulation des présentes Conditions d'utilisation ne saurait exclure 
						ni limiter la responsabilité de Lovelight Factory, qui ne peut être exclue ou 
						limitée selon la législation en vigueur.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							7. Garanties
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						L’utilisation que vous faites du Site Web et la manière dont vous le parcourez 
						dépendent de votre responsabilité. Sauf spécification expresse, le Site Web dans 
						son ensemble est fourni « tel quel » et « sous réserve de disponibilité ».
						Lovelight Factory rejette formellement toute garantie, expresse ou implicite, y 
						compris, mais sans s'y limiter, toute garantie implicite de qualité 
						satisfaisante, d'adéquation à un usage spécifique ou de non-violation du droit 
						d'auteur, dans la mesure permise par la loi.
						Un grand soin a été apporté au Contenu et à l'exactitude des informations 
						présentées sur le Site Web. Toutefois, ne garantit en aucun cas que le Site Web 
						et les informations qu'il contient répondent aux exigences des utilisateurs, ni 
						que le site Web sera exempt d'interruptions, de retards, de failles de sécurité 
						ou d'erreurs.  n'offre également aucune garantie quant aux résultats obtenus 
						suite à l'utilisation du Site Web.
						Tout téléchargement ou autre procédé permettant d'obtenir des documents et/ou 
						des données via l'utilisation du Site Web se fait à votre propre appréciation 
						et à vos risques et périls
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							8. Indemnisation
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Vous êtes responsable du contenu de votre fichier d'image placé sur le serveur 
						de ou de tout autre document distribué via le Site Web ou les services. Vous 
						assumez également la responsabilité de toutes les obligations découlant de 
						votre utilisation du Site Web ou des services. Vous vous engagez à indemniser 
						et ses sociétés affiliées en cas de perte, de dommage, d'obligation ou de 
						plainte, ainsi que de menace ou d'introduction d'une action en justice contre 
						ou ses sociétés affiliées et découlant de l'utilisation que vous faites du 
						Site Web ou des services.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							9. Responsabilité relative au nom d’utilisateur et au mot de passe
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Lorsque vous fournissez des informations pour l'utilisation ou la demande d'un 
						nom d'utilisateur et d'un mot de passe, vous vous engagez à fournir des données 
						exactes, à jour et complètes, telles que requises par les formulaires 
						d'inscription rendus accessibles par via le Site Web ou un autre canal. Si les 
						informations que vous fournissez sont fausses, inexactes, obsolètes ou 
						incomplètes, peut résilier ou suspendre votre accès à la section en question 
						du Site Web.
						Le nom d'utilisateur et le mot de passe que vous recevez pour accéder à certains 
						services fournis via le Site Web sont confidentiels et ne peuvent être ni 
						divulgués ni partagés avec un tiers, ni utilisés à des fins non autorisées. Il vous appartient de veiller à la confidentialité du nom d'utilisateur et du mot de passe qui vous sont communiqués et la responsabilité des activités qui sont effectuées avec ce nom d'utilisateur et ce mot de passe vous revient intégralement.
						Vous reconnaissez que l'utilisation conjointe du mot de passe et du nom 
						d'utilisateur représente votre autorisation de vous connecter sur le site. 
						Vous acceptez d'être lié à toute action enregistrée par l'utilisation de votre 
						nom d'utilisateur et de votre mot de passe. Vous vous engagez donc à mettre en 
						œuvre les mesures de sécurité appropriées pour garantir que le nom d'utilisateur 
						et le mot de passe qui vous ont été attribués seront divulgués uniquement aux 
						personnes autorisées.
						Vous vous engagez à :
						<ul>
						 <li>signaler immédiatement à toute utilisation frauduleuse de votre nom 
						     d'utilisateur et de votre mot de passe ou toute autre défaillance de la 
							 sécurité ;</li>
						 <li>vous assurer de vous déconnecter de votre nom d'utilisateur à la fin de 
						     chaque session.</li>
						</ul>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							10. Généralités
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Lovelight Factory se réserve le droit de changer ou de mettre à jour à tout 
						moment les présentes Conditions d'utilisation. Vous serez lié par ces 
						modifications, aussi vous devrez consulter régulièrement cette page pour vous 
						informer des Conditions d'utilisation en vigueur.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							11. Droit applicable
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Les présentes Conditions d'utilisation doivent être régies et interprétées 
						conformément au droit français. Les litiges relatifs aux présentes
						Conditions d'utilisation sont soumis à la compétence exclusive des tribunaux 
						de Grasse (Alpes Maritimes).
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
							12. Adresses
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Lovelight Factory 
						1 place de la Fontaine
						06610 La Gaude 
						France 

						Registre du commerce Grasse : 978 665 511

						Numéro de TVA : FR60978665511 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

        </Accordion>
		
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>

            
  );
};

export default TermsOfService;
