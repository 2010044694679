import { Action, ActionType, Choices } from './actionTypes';

interface State 
 {
  choices: Choices;
 }

const initialState = 
 {
  choices: 
   {
    cookies1: true,
    cookies2: true,
    cookies3: true
   } 
 }

const CookiesManagmentReducer = (state: State = initialState, action: Action):State => 
 {
  switch(action.type) 
   {
    case ActionType.UPDATE_CHOICES: return {
                                            ...state,
                                            choices: action.choices
                                           };
									 
    default:                        return state;
   }
 }

export default CookiesManagmentReducer;