import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



import   styles                  from './personnal_datas_information_leaflet.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';

export interface PersonnalDatasInformationLeafletProps {
  className?: string;
}

export const PersonnalDatasInformationLeaflet = ({ className }: PersonnalDatasInformationLeafletProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
  }); 
  

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };


  return (
  
        <>

              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
        <h1>Notice d’information sur les données personnelles de Lovelight Factory</h1>
		
        <br/>

        <p className={classNames(styles.texte, className)} >
			Dans la présente notice d’information sur les données personnelles, nous expliquons 
			la manière dont nous recueillons et utilisons vos données personnelles. Cette notice 
			d’information sur les données personnelles s’applique à toutes les données personnelles 
			vous concernant que nous sommes amenés à traiter lorsque vous commandez, achetez ou 
			utilisez nos produits et services, lorsque vous visitez nos sites Internet ou notre 
			service d’assistance ou lorsque vous interagissez de toute autre manière avec Lovelight 
			Factory. Lovelight Factory respecte votre vie privée et reconnaît que le traitement des 
			données personnelles est une responsabilité sociale importante et doit se faire d’une 
			manière licite et appropriée ; elle déclare donc qu’elle s’efforcera de protéger les 
			données personnelles. Elle s’applique 
			à vous (ci-après, « vous »), en tant que client et/ou fournisseur potentiel, actuel et 
			passé, et à votre usage de nos produits et services, de nos sites Internet ou de tous les 
			autres rapports commerciaux avec Lovelight Factory. Dans la présente notice d’information 
			sur les données personnelles, nous expliquons la manière dont nous traitons vos données 
			personnelles. C’est pourquoi nous vous encourageons à lire cet avis avec attention.
        </p>
		
		
        <br/>
		
		<Accordion allowZeroExpanded className={classNames(styles.accordion, className)}>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						1. Qui sommes-nous ?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
                     Lovelight Factory est une société française sous la forme d’une société à responsabilité limitée. 
					 Nous sommes responsables de la collecte et de l’utilisation de vos données personnelles décrites 
					 dans cette notice d’information sur les données personnelles.
                     Si vous voulez exercer vos droits, comme votre droit d’accéder, de corriger, d’effacer, de restreindre, 
					 de refuser ou de transmettre vos données personnelles, ou de retirer votre consentement, ou si vous 
					 avez des questions concernant le traitement de vos données personnelles, vous pouvez nous contacter 
					 conformément à la présente notice d’information sur les données personnelles à l'adresse :
					 team@lovelightchallenge.com
					</p>	
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						2. Données personnelles : objet, type et utilisation
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Les données personnelles que nous traitons sont des informations que vous nous fournissez sciemment, 
						que ce soit directement ou à travers des tierces parties. Cependant, dans certains cas, nous traitons 
						des données personnelles que nous pouvons inférer vous concernant sur la base d’autres informations 
						que vous nous avez communiquées ou de nos interactions avec vous, ou les données personnelles que nous 
						recevons de la part d’une société du groupe ou d’une tierce partie avec votre accord.
                    </p>
                    <p className={classNames(styles.texte, className)} >
						Si vous refusez de fournir les données personnelles dont nous avons besoin pour l’exécution du contrat
						ou la conformité avec une obligation légale, nous pourrions ne pas être à même de fournir tout ou partie
						des services que vous nous demandez.
                    </p>
                    <p className={classNames(styles.texte, className)} >
						Nous pouvons être amenés à traiter votre nom, vos coordonnées, votre date de naissance, vos données 
						d’assistance client, votre profession, vos intérêts photographiques, votre domaine d’expertise 
						en fonction des produits et/ou services Lovelight Factory que nous 
						vous fournissons. Les données personnelles que nous recueillons et traitons, ainsi que leur objet, 
						dépendront des produits et services que Lovelight Factory vous prodigue. Les objectifs principaux pour 
						lesquels nous traitons vos données personnelles sont comme suit :

						<ol>
							<li>Vous fournir nos services et communiquer avec vous
							    <br/>
								Nous utilisons également vos coordonnées pour répondre à vos questions lorsque vous contactez 
								notre service à la clientèle.
                            </li>
							<li>Objectifs de marketing direct
							    <br/>
								Nous utilisons vos informations de compte et vos coordonnées pour vous envoyer des informations 
								sur les nouveaux produits et services susceptibles de vous intéresser. Nous ne vous envoyons 
								des messages de marketing direct qu’avec votre accord préalable ou si nous avons recueilli 
								votre adresse électronique dans le cours de nos rapports avec vous. Vous pouvez toujours vous 
								désabonner pour ne plus recevoir ces messages en suivant pour cela les instructions contenues 
								dans la communication de marketing relative, en réglant vos préférences de compte (le cas échéant) 
								ou en contactant notre service à la clientèle.
							    <br/>
								Nous analysons vos données personnelles et nous pourrions inclure des offres personnalisées dans 
								nos messages de marketing direct en fonction des intérêts légitimes que nous pourrions avoir à vous 
								communiquer des offres appropriées. Vous pouvez à tout moment vous opposer à l’utilisation de vos 
								données personnelles aux fins de marketing direct.
							    <br/>
								Nous pourrions utiliser différents canaux de communication pour vous envoyer des publicités 
								personnalisées et des offres spéciales, tels que les courriers postaux ou, avec votre consentement, 
								des notifications poussées mobiles ou les médias sociaux.
                            </li>
							<li>Pour recherche statistique
							    <br/>
								Nous utilisons des outils automatiques pour conduire une recherche statistique sur les tendances 
								générales concernant l’utilisation de nos services, sites Internet, applications et médias sociaux 
								et sur le comportement et les préférences de nos clients et utilisateurs.
							    <br/>
								Les informations que nous utilisons pour notre recherche incluent votre code postal et votre pays 
								de résidence, les informations techniques que nous recevons si vous visitez l’un de nos sites Internet, 
								les informations que nous recueillons via les cookies, vos préférences et intérêts personnels et les 
								informations que vous partagez avec nous en ligne ou via les médias sociaux. Pour notre recherche, 
								nous n’utilisons que les données agrégées qui ne sont pas attachées à votre nom et à votre adresse 
								électronique. Nous utilisons les résultats agrégés de la recherche pour détecter les tendances 
								générales dans l’utilisation de nos produits, services et outils. Cela nous permet de mieux développer 
								des produits, services, outils et offres, d’assurer un service à la clientèle plus réactif et 
								d’améliorer la conception et le contenu de nos sites Internet. Pour en savoir davantage sur les cookies 
								et autres technologies similaires, veuillez consulter la Notice d’information sur les cookies sur 
								notre site Internet.
                            </li>
							<li>Pour la tenue des registres et la conformité aux obligations réglementaires
							    <br/>
								Nous recueillons, stockons et utilisons vos données à des fins professionnelles internes telles que 
								la tenue d’un registre et la conformité à nos obligations légales et fiscales.
                            </li>
							<li>Services spécifiques, applications, événements Lovelight Factory, concours ou campagnes
							    <br/>
								Pour certains services, applications, événements, concours ou campagnes en particulier, nous pouvons 
								utiliser vos données personnelles à des fins autres que celles décrites dans la présente notice 
								d’information sur les données personnelles. Nous vous informerons de telles fins lorsque vous vous 
								enregistrerez pour les services, événements, concours ou campagnes ou lorsque vous téléchargerez 
								l’application appropriée.
							    <br/>
								Vos données personnelles peuvent être utilisées pour d’autres fins encore. Dans un tel cas, nous nous 
								assurerons que ceci est fait conformément aux lois applicables.
                            </li>
						</ol>
                    </p>
                    <p className={classNames(styles.texte, className)} >
						Base juridique
                    </p>
                    <p className={classNames(styles.texte, className)} >
						Nous traitons vos données personnelles parce que cela est nécessaire à l’exécution d’un contrat stipulé avec 
						vous afin de vous fournir nos produits et services, de nous conformer aux obligations juridiques ou si cela est 
						dans notre intérêt légitime ou dans celui d’une tierce partie, ou encore sur la base de votre consentement.
						<br/>
						Lorsque nous traiterons vos données personnelles pour notre intérêt légitime, nous prendrons également en compte 
						et nous assurerons que le traitement ultérieur de vos données personnelles est fait en conformité avec les 
						exigences légales. De ce fait, nous nous efforcerons également de nous assurer que le traitement effectué à 
						cette nouvelle fin est compatible avec l’objectif pour lequel les données personnelles ont été initialement 
						recueillies. Ceci ne s’applique pas lorsque ce traitement est fait en vertu d’une obligation légale ou lorsque 
						nous avons obtenu votre consentement. Nos intérêts légitimes sont par exemple l’amélioration de nos produits 
						et services, la réduction de nos coûts, l’amélioration de nos brochures et sites Internet ou la sécurisation de 
						nos services et de nos installations.
						<br/>
						Si vous avez donné votre consentement, sachez que vous pouvez le retirer à tout moment en suivant le lien de 
						désabonnement dans un courriel, en réglant vos paramètres (le cas échéant) ou en nous contactant.
					</p>	
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						3. Partage d’informations
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >

                    <p className={classNames(styles.texte, className)} >
						Il est possible que vos données personnelles soient partagées avec des tierces parties de confiance exécutant 
						des fonctions commerciales ou vous fournissant les services. Il sera demandé à ces tierces parties de protéger 
						de manière appropriée vos données personnelles, sous réserve des accords correspondant aux exigences des lois 
						applicables. Vos données personnelles peuvent également être partagées pour des investigations (par ex., 
						divulgation en prévention de crimes ou de fraudes, ou pour se conformer avec une ordonnance de justice ou au 
						droit).
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Un transfert ou une divulgation des données personnelles peut également être effectué(e) en lien avec une 
						opération (prévue) altérant la structure de notre entreprise, comme une réorganisation, une fusion, une vente, 
						une coentreprise, une cession, un transfert, un changement de contrôle ou toute autre disposition de tout ou 
						partie de notre entreprise, de nos actifs ou des actions (opérations sociales).
					</p>	

                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						4. Mesures de sécurité et rétention de données
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Nous protégerons vos données personnelles conformément à nos politiques informatiques et de sécurité de sorte 
						que celles-ci seront protégées contre toute utilisation non autorisée, tout accès non autorisé et toute 
						modification, perte ou destruction abusive. Vos données personnelles seront conservées aussi longtemps que 
						nécessaire pour les fins pour lesquelles elles ont été obtenues, ainsi que conformément à nos exigences légales 
						et fiscales.
					</p>	
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						5. Vos droits
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Vous pouvez nous contacter pour exercer l’un ou l’autre des droits mentionnés ci-dessous. Nous pourrions 
						ne pas toujours être en position de répondre à votre requête. Par exemple, votre requête d’accès pourrait 
						être limitée si nécessaire afin de protéger les droits et les libertés d’autres personnes, ou nous pourrions 
						refuser de supprimer vos données personnelles dans le cas où leur traitement serait nécessaire en vertu de 
						nos obligations légales.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Si vous voulez exercer vos droits, veuillez envoyer votre requête aux coordonnées ci-dessous. Notez que nous 
						pourrions avoir besoin que vous nous donniez d’autres informations pour confirmer votre identité. Vous avez 
						également le droit de déposer une plainte auprès de l’autorité néerlandaise de protection des données.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous pouvez aussi nous contacter si vous avez des questions, remarques ou plaintes en lien avec la présente 
						notice d’information sur les données personnelles.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Droit d’accès
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous pouvez demander à accéder à vos données personnelles. Cela inclut la question de savoir si nous traitons 
						des données personnelles vous concernant.  
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Droit de rectification
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous avez le droit de faire rectifier vos données personnelles en cas d’inexactitudes ou de lacunes.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Droit d’effacement
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous avez aussi le droit de faire effacer vos données personnelles. Nous effacerons vos données conformément 
						à votre demande et à la loi. Dans le cas où vos données seraient supprimées, sachez que, en raison de la façon 
						dont nous maintenons certains services, un certain temps pourrait être nécessaire avant que les copies 
						sauvegardées ne soient effacées.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Droit de restriction du traitement
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous avez le droit d’obtenir que le traitement de vos données personnelles soit restreint dans certaines 
						circonstances.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Droit de portabilité des données
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Le droit à la portabilité des données s’applique aux informations que nous recevons directement de votre part. 
						Vous avez le droit de demander que nous transférions les informations que vous nous donnez d’une organisation 
						à une autre (lorsque techniquement possible) ou que nous vous les donnions. Ce droit ne s’applique que dans 
						le cas de processus automatisés et pour le traitement de vos données sur la base de votre consentement ou 
						de l’exécution d’un contrat.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Droit d’opposition
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous avez le droit de vous opposer au traitement de vos données personnelles dans le cas où nous traiterions 
						vos données personnelles sur la base de nos « intérêts légitimes » (y compris par profilage).
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Vous pouvez, à tout moment et gratuitement, vous opposer aux fins de marketing direct dans le cas où vos 
						données personnelles seraient traitées à de telles fins, ce qui inclut les fins de profilage dans la mesure 
						où elles sont liées à un tel marketing direct. Si vous exercez ce droit, nous ne traiterons plus vos données 
						personnelles à de telles fins.
					</p>	
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						6. Comment nous observons cette politique
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Nous avons très récemment mis à jour cette notice en novembre 2023 et celle-ci remplace les versions antérieures. 
						Nous actualiserons occasionnellement cette notice d’information sur les données personnelles.
					</p>	
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						7. Coordonnées en cas de question sur la protection de vos données personnelles
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Lovelight Factory - 1 place de la Fontaine - 06610 La Gaude - France
						E-mail : team@lovelightchallenge.com					
					</p>	
                </AccordionItemPanel>
            </AccordionItem>

        </Accordion>
		
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>

            
  );
};

export default PersonnalDatasInformationLeaflet;
