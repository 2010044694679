import   React               from "react";
import   classNames          from 'classnames';

import   ImageGallery            from "react-image-gallery";
import   "react-image-gallery/styles/css/image-gallery.css";

import   styles                  from './welcome2.module.scss';


import { BlocCountdown2        }  from '../bloc_countdown2/bloc_countdown2';

export interface Welcome2Props {
  className?:         string;                 // classe CSS complémentaire
}

export const Welcome2 = ({ className  }: Welcome2Props) => {

  const images = [
  
  
    {
      original: "/images/Partenaires/Nikon.png",
	  thumbnail: "/images/Partenaires/Nikon.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Sigma.png",
      thumbnail: "/images/Partenaires/Sigma.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Leica.png",
      thumbnail: "/images/Partenaires/Leica.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Camara.png",
      thumbnail: "/images/Partenaires/Camara.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
    {
      original: "/images/Partenaires/Lumix.png",
      thumbnail: "/images/Partenaires/Lumix.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
    {
      original: "/images/Partenaires/PhaseOne.png",
      thumbnail: "/images/Partenaires/PhaseOne.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Profoto.png",
      thumbnail: "/images/Partenaires/Profoto.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Nation-Photo.png",
      thumbnail: "/images/Partenaires/Nation-Photo.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
  
  
    {
      original: "/images/Partenaires/Canon.png",
      thumbnail: "/images/Partenaires/Canon.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Ilford.png",
      thumbnail: "/images/Partenaires/Ilford.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Leofoto.png",
      thumbnail: "/images/Partenaires/Leofoto.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Think-Tank.png",
      thumbnail: "/images/Partenaires/Think-Tank.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
	{
      original: "/images/Partenaires/Fujifilm.png",
	  thumbnail: "/images/Partenaires/Fujifilm.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
  ];


  return (

              <div className={classNames(styles.root, className)}>

                <div className={classNames(styles.contain, className)}>
    
                    <div className={classNames(styles.espace, className)}></div>

                  <BlocCountdown2 />

                    <div className={classNames(styles.espace, className)}></div>
                  
                </div>

                <div className={classNames(styles.blocDroite, className)}>
                  
                    <div className={classNames(styles.accroche, className)}>
                     L’émission qui révèle les nouveaux talents de la photographie !
                    </div>

                    <div className={classNames(styles.accroche2, className)}>
                     LES CANDIDATURES SONT CLOSES<br/>
                     VENEZ VOTER POUR LES MEILLEURES PHOTOS<br/>
                     À PARTIR DU 26 DÉCEMBRE À 13H00<br/>
                    </div>
                  
                    <div className={classNames(styles.partenaires, className)}>

                      <ImageGallery items                = {images} 
                                    infinite             = {true}
                                    showNav              = {false}
                                    showThumbnails       = {false}
                                    showFullscreenButton = {false}
                                    showPlayButton       = {false}
                                    showBullets          = {false}
                                    showIndex            = {false}
                                    autoPlay             = {true}
                                    slideDuration        = {450}     
                                    additionalClass      = {classNames(styles.album, className)} />
                    </div>

                </div>

              </div>
           
  );
};


export default Welcome2;
