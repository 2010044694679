import   React                , 
       { useState    ,
	     useEffect           }  from 'react';
import { Link       } from 'react-router-dom';
import   classNames   from 'classnames';
import { useCookies } from 'react-cookie';

import   styles       from './window_cookies_manager.module.scss';

import { Trigger    } from '../widget_trigger/trigger';
import { WindowCookiesManager2 }  from '../window_cookies_manager/window_cookies_manager_2';

import { WWW_URLS } from '../../urls/urls';

export interface WindowCookiesManagerProps {
  className?:           string;
  onClickOnCloseButton: (() => void)
}

export const WindowCookiesManager = ({ className, onClickOnCloseButton }: WindowCookiesManagerProps) => {

  const [ showParameters , setShowParameters ] = useState(false);
  const [ cookies        , setCookie         ] = useCookies(['CookiesConsent','fonctionnement','analyse','marketing']);


  const handleClickAccept = () => {
  
    setCookie ( 'CookiesConsent' , '1' );
    setCookie ( 'fonctionnement' , '1' );
    setCookie ( 'analyse'        , '1' );
    setCookie ( 'marketing'      , '1' );

    onClickOnCloseButton();
  };

  const handleClickRefuse = () => {

    setCookie ( 'CookiesConsent' , '-1' );
    setCookie ( 'fonctionnement' , '-1' );
    setCookie ( 'analyse'        , '-1' );
    setCookie ( 'marketing'      , '-1' );

    onClickOnCloseButton();
  };

  const handleClickParameters = () => {
  
    setCookie('CookiesConsent', '0');
    setCookie('fonctionnement', '1');
    setShowParameters (true);
  };

  const handleTriggerCookiesParametersClose = () => {
  
       setShowParameters (false);
       onClickOnCloseButton();

  };

  return (
            <>
            
              { 
                showParameters && 
                ( <WindowCookiesManager2 onClickOnCloseButton = {handleTriggerCookiesParametersClose }/> ) 
              }

              <div className={classNames(styles.window, className)}>
              
                <h1>Centre de préférences de la confidentialité</h1>
              
                <br/>
              
                <p  className={classNames(styles.text_rgpd, className)}>
				   Lorsque vous vous rendez sur un site web, des informations peuvent être 
                   stockées sur votre navigateur ou récupérées à partir de celui-ci, généralement 
                   sous la forme de cookies. Ces informations peuvent porter sur vous, sur vos 
                   préférences ou sur votre appareil et sont principalement utilisées pour 
                   s'assurer que le site fonctionne comme prévu. Les données enregistrées ne 
                   permettent généralement pas de vous identifier directement, mais peuvent vous 
                   permettre de bénéficier d'une expérience web personnalisée. Vous pouvez choisir 
                   de bloquer certains types de cookies. Cliquez sur les différentes catégories 
                   pour obtenir plus de détails sur chacune d'entre elles, et pour modifier les 
                   paramètres par défaut. Veuillez noter, cependant, que si vous bloquez certains 
                   types de cookies, votre expérience de navigation et les services que nous sommes 
                   en mesure de vous offrir peuvent être impactés.</p>
                 
                <br/>
              
                <p><Link to={WWW_URLS.CookiesInfos} 
				         target="_blank"  
						 className = { classNames(styles.link, className) }>Notice d’Information sur les cookies</Link></p>

                <br/>

                <div className={classNames(styles.buttons, className)}>
              
                  <Trigger link      = "#"
                           name      = "Tout accepter" 
                           onClick   = {handleClickAccept}
                           className = {classNames(styles.trigger_window, className)}/>
                       
                  <Trigger link      = "#"
                           name      = "Tout refuser" 
                           onClick   = {handleClickRefuse}
                           className = {classNames(styles.trigger_window, className)}/>
                       
                  <Trigger link      = "#"
                           name      = "Réglages" 
                           onClick   = {handleClickParameters}
                           className = {classNames(styles.trigger_window, className)}/>
                         
                </div>
              
              </div>
            
            </>
         );
};

export default WindowCookiesManager;
