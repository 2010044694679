import   React         from 'react';
import   classNames    from 'classnames';

import { Switch     }  from '@mui/material';

import   styles        from './toggle.module.scss';

export interface ToggleProps {
  className?: string; 
  checked?:   boolean | undefined;     // disponible ou non
  name?:      string; 
  error?:     string;                  // Le message d'erreur
  readOnly?:     boolean | undefined;                                      // éditable ou non
  onChange?:  ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;     
}

export const Toggle = ({ className , 
                         checked   ,
                         name      , 
						 error     ,
						 readOnly   ,
						 onChange   }: ToggleProps) => {

  return (
  
           <div className={classNames(styles.switch, className)}>
 
             {
			   ( typeof (name) != "undefined" ) &&
			   ( <div className={classNames(styles.switch_nom, className)} >{name}</div> )
             }
			 
		     <Switch inputProps = {{ 'aria-label': 'controlled' }} 
                     checked    = {checked}
                     onChange   = {onChange} 
					 disabled   = {readOnly} />
						
             <div className={classNames(styles.switch_info, className)} >{error}</div>
	  
           </div>
		   
         );

};

export default Toggle;
