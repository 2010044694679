// urls.ts


const WWW_BASE_URL = 'https://www.lovelightchallenge.com';
const API_BASE_URL = 'https://api.lovelightchallenge.com';

/*
const WWW_BASE_URL = 'https://home.lovelightfactory.com';
const API_BASE_URL = 'https://api.lovelightfactory.com';
*/

/*
const WWW_BASE_URL = 'http://192.168.1.4:3000';
const API_BASE_URL = 'http://192.168.1.4:8001';
*/

export const API_URLS = {
  GetEmailValidation:      `${API_BASE_URL}/api/email_validation`,
  GetEmailValidationCheck: `${API_BASE_URL}/api/email_validation_check`,
  GetExistenceCheck:       `${API_BASE_URL}/api/existence_check`,
  GetPasswordReset:        `${API_BASE_URL}/api/password_reset`,
  GetPasswordResetRequest: `${API_BASE_URL}/api/password_reset_request`,
  PostUser:                `${API_BASE_URL}/api/users`,

  GetInfosUserByEmail:     `${API_BASE_URL}/api/by_email`,
  GetInfosUserById:        `${API_BASE_URL}/api/users/`,
  PatchUser:               `${API_BASE_URL}/api/users/`,

  PostConnection:          `${API_BASE_URL}/api/connections`,

  GetPhotosByEmail:        `${API_BASE_URL}/api/photosbyemail`,
  GetPhotosById:           `${API_BASE_URL}/api/photos/`,
  GetPhotosForVote:        `${API_BASE_URL}/api/photosforvote`,
  
  PostPhoto:               `${API_BASE_URL}/api/photos`,
  PatchPhoto:              `${API_BASE_URL}/api/photos/`,
  DeletePhoto:             `${API_BASE_URL}/api/photos/`,

  PatchPhotoVote:          `${API_BASE_URL}/api/vote/`,
  GetVotes:                `${API_BASE_URL}/api/votes`,
  GetVotesForPhoto:        `${API_BASE_URL}/api/votesforphoto`,
  GetPositionForPhoto:     `${API_BASE_URL}/api/photos_position`,

  PostAuth:                `${API_BASE_URL}/auth`,
  
  Test:                    'https://webhook.site/d3023ee7-42da-4087-8464-f1c34fb0bb76'
};

export const WWW_URLS = {
  Home:                 `${WWW_BASE_URL}`,
  Reglement:            `${WWW_BASE_URL}/reglement-du-lovelight-challenge`,
  MentionsLegales:      `${WWW_BASE_URL}/mentions-legales`,
  ConditionsGenerales:  `${WWW_BASE_URL}/conditions-generales-d-utilisation`,
  Confidentialite:      `${WWW_BASE_URL}/notice-d-information-sur-les-donnees-personnelles`,
  CookiesInfos:         `${WWW_BASE_URL}/notice-d-information-sur-les-cookies`,
};

/*


 GET /api/email_validation
 GET /api/email_validation_check
 GET /api/existence_check
 GET /api/password_reset
 GET /api/password_reset_request
 POST /api/users

 GET /api/by_email
 GET /api/users/{id}
 PATCH /api/users/{id}

 GET /api/users

 GET /api/cameras
 POST /api/cameras
 GET /api/cameras/{id}
 PUT /api/cameras/{id}
 DELETE /api/cameras/{id}
 PATCH /api/cameras/{id}

 GET /api/connections
 POST /api/connections
 GET /api/connections/{id}
 PUT /api/connections/{id}
 DELETE /api/connections/{id}
 PATCH /api/connections/{id}

 GET /api/faqs
 POST /api/faqs
 GET /api/faqs/{id}
 PUT /api/faqs/{id}
 DELETE /api/faqs/{id}
 PATCH /api/faqs/{id}

 GET /api/photos
 POST /api/photos
 GET /api/photos/{id}
 DELETE /api/photos/{id}
 PATCH /api/photos/{id}
 GET /api/photosbyemail

 POST /auth

*/
