import   React                ,  
       { useState             }  from "react";
import   classNames              from 'classnames';
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                   from 'axios';
	   
import   styles                  from './password_edition.module.scss';
import { updatePassword       , 
         updatePassword2      }  from '../../redux/registrationManagment/actionCreators';
		 
import { Field                }  from '../widget_field/field';
import { Trigger              }  from '../widget_trigger/trigger';
import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { WindowMessage                   }  from '../window_message/window_message';

import { API_URLS } from '../../urls/urls';

export interface PasswordEditionProps {
  className?:         string;  
  onPageStateChange:  (num: number) => void;  // gérer le click sur le bouton "Modifier profil"
}

export const PasswordEdition = ({ className         ,
                                  onPageStateChange }: PasswordEditionProps) => {

  const dispatch: Dispatch<any> = useDispatch()

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );

  const [ password              , setPassword           ] = useState('');
  const [ password2             , setPassword2          ] = useState('');

  const [ messagePassword       , setMessagePassword    ] = useState('');
  const [ messagePassword2      , setMessagePassword2   ] = useState('');

  const [ showMessageError      , setShowMessageError   ] = useState(false);
  const [ showMessageAck        , setShowMessageAck     ] = useState(false);
  const [ showUpdateOk          , setShowUpdateOk       ] = useState(false);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword (e.target.value) 
    dispatch ( updatePassword (e.target.value) );

    if ( registration.password === "" )
          {
           setMessagePassword ("Il manque le mot de passe");
          }
     else {
           if ( registration.password.length > 0 && registration.password.length < 8) 
                 {
                  setMessagePassword ("Le mot de passe fait moins de 8 caractères.");
                 }
            else {
                  setMessagePassword ("");
                 }
          }        
          
    if ( registration.password2 !== registration.password )
          {
           setMessagePassword2 ("Les deux mots de passe sont différents");
          }
     else {
           setMessagePassword2 ("");
          }
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2 (e.target.value) 
    dispatch ( updatePassword2 (e.target.value) );

    if ( registration.password2 !== registration.password )
          {
           setMessagePassword2 ("Les deux mots de passe sont différents");
          }
     else {
           setMessagePassword2 ("");
          }
  };

  const checkInputs = () => {

    var test = true;

    if ( registration.password === '' )
          {
	       test = false;
           setMessagePassword ("Il manque le mot de passe")
          }
	 else {
           if ( registration.password.length < 8 )
            {
	         test = false;
             setMessagePassword ("Le mot de passe fait moins de 8 caractères")
            }
          }
		  
    if ( registration.password !== registration.password2 )
     {
	  test = false;
      setMessagePassword2 ("Les 2 mots de passe sont différents")
     }
          
    return test;
  }

  const handlePasswordUpdateClick = async () => {
  
    const test = checkInputs ();

    if ( test )
     {
      const id          =   registration.id
      const password    =   registration.password

      const baseUrl     =  `${API_URLS.PatchUser}`; // "https://api.lovelightfactory.com/api/users/"

      const url = `${baseUrl}${id}`;

      // const url = 'https://webhook.site/292cb8db-a372-4805-8bf6-93e0768063ab';

      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${authentication.token}`,
      };

      try {
        const response = await axios.patch (
                                              url              ,
                              
                                            { 
                                              password      
                                            },
                                             
                                            { headers: headers }
                                          
                                           );

        setShowMessageAck   ( true );     
        setShowMessageError ( false );
            setShowUpdateOk( true );

     
      } 
      catch (error) {
      
        setShowMessageAck   ( false );     
        setShowMessageError ( true );     
       }
    }
  };


  const handleCancelClick = () => {

    onPageStateChange (0);
  }

  const handleUpdateClick = () => {
  
    onPageStateChange (0);
  }

    const handleCloseUpdateOkMessageClick = () => {

    setShowUpdateOk( false );
    onPageStateChange (0);

  }



  return (
           <div className={classNames(styles.root, className)}>

             {
               showUpdateOk &&
               ( <WindowMessage onClickOnCloseButton = {handleCloseUpdateOkMessageClick} message = "Les modifications ont bien été effectuées" /> )
             }

			 <br/>
			 
                <div className={classNames(styles.grid_password_nom,  styles.nom,  className)}>
                  Nouveau mot de passe <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
				</div>
                <div className={classNames(styles.grid_password_data,  className)}>
                  <Field   name        = ""
                           type        = "password"
                           placeholder = ""
                           value       = {password}
                           error       = {messagePassword}
                           onChange    = {handlePasswordChange} />
                </div>
                    
                <div className={classNames(styles.grid_password2_nom,  styles.nom,  className)}>
                  Confirmation du nouveau mot de passe
                </div>
                <div className={classNames(styles.grid_password2_data,  className)}>
                  <Field   name        = ""
                           type        = "password"
                           placeholder = ""
                           value       = {password2}
                           error       = {messagePassword2}
                           onChange    = {handlePassword2Change} />
                </div>
			 
             <div className={classNames(styles.grid_button)}>
			    <Trigger  name        = "Retour"
                          link        = "#"
                          onClick     = {handleCancelClick}
                          className   = {classNames(styles.big_trigger, className)} />

			    <Trigger  name        = "Enregistrer les modifications"
                          link        = "#"
                          onClick     = {handlePasswordUpdateClick}
                          className   = {classNames(styles.big_trigger, className)} />
             </div>

           </div>
         );
};

export default PasswordEdition;
