import   React                , 
       { useEffect            ,
         useState             }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import { Link                 }  from 'react-router-dom';
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                , 
       { AxiosError           }  from 'axios';

import { Image                , 
         Gallery              }  from "react-grid-gallery";	   
		 
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';


import { getImageSize         }  from 'react-image-size';	   
	   
import   styles                  from './vote.module.scss';

import { initEnsemble         ,
         addPhoto             ,
         updatePhotoSelection }  from '../../redux/voteManagment/actionCreators';
		 
import { Ensemble             }  from '../../redux/voteManagment/actionTypes';		 

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { WindowPhoto2          }  from '../window_photo2/window_photo2';
import { Menus                }  from '../bloc_menus/menus';
import { Bulletin             }  from '../bloc_bulletin/bulletin';

import { API_URLS             ,
         WWW_URLS             }  from '../../urls/urls';

		 
interface Photo {
  id:                  number ,
  file:                string ,
  name:                string ,
  description:         string ,
  cameraBrand:         string ,
  cameraModel:         string ,
  choosen:             string ,
  authorCertification: string ,
  reviewState:         string ,
  dateCreation:        string ,
  dateReview:          string , 
  urlThumbnail:        string ,
  urlMagnified:        string ,
  widthThumbnail:      number ,
  heightThumbnail:     number ,
  firstname:           string ,
  lastname:            string
}

interface TraceVote {
  id:                  number ,
  ipAddress:           string ,
  dateCreation:        string ,
  photo:               string  
}

export interface VoteProps {
  className?: string;
}

export const Vote = ({ className }: VoteProps) => {

  const VIDE:  Image[] = [];
  const VIDE2: number[] = [];
  const VIDE3: number[] = [];
  
  const dispatch: Dispatch<any> = useDispatch()

  const { ensemble       } = useTypedSelector ((state) => state.voteManagment   );
  
  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender       ] = useState(true);

  const [ nbPhotos              , setNbPhotos          ] = useState(0);
  const [ images                , setImages            ] = useState(VIDE);
  const [ indexes               , setIndexes           ] = useState(VIDE2);
  const [ idPhotos              , setIdPhotos          ] = useState(VIDE3);
  
  const [ showZoom_index        , setShowZoom_index    ] = useState (-1);

  const [ipAddress, setIpAddress] = useState ("");

  const [renderKey, setRenderKey] = useState(0);

  useEffect(() => {

    if ( firstRender )
     {
      getIPAddress();

      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
	 else 
	 {
	  getPhotos();
	 }
	 
  },[ipAddress,idPhotos]); 
 
   useEffect(() => {
   
   console.log (images)
  },[images]); 

  const getIPAddress = async () => {
  
    try {
		const response = await axios.get("https://api.ipify.org?format=json");
		const statut = response.status;
  
     // console.log ( "getIPAddress " , response.data.ip );
  
     setIpAddress ( response.data.ip );
	 getVotes     ( response.data.ip );
	} 
	catch (error) {
	}
  }

  const getUrl = (fileName:string, thumbnail: boolean) => {
      
    const serveur    = new String ( "https://photos.lovelightchallenge.com/" );   
    const extension1 = new String ( "_mini.jpeg" );   
    const extension2 = new String ( ".jpeg" );   
    
	return ( serveur.concat(fileName.toString()).concat( thumbnail ? extension1.toString() : extension2.toString() ) );
  }

  const getVotes = async ( ipAddress: string ) => {
			
    var temp: number[]  = []; 
			
	const url = `${API_URLS.GetVotes}?ipAddress=${ipAddress}`;

   // console.log ( "getVotes " , url );

	try {
		const response = await axios.get(url);
		const statut = response.status;

        // console.log ( "getVotes " , response );

		if ( statut == 200 )  {
		
			const userVotes = response.data['hydra:member'];

            userVotes.map( (traceVote:TraceVote) => {

                // console.log (" - " , traceVote.photo.substr(12) );
				
				temp.push ( parseInt ( traceVote.photo.substr(12) ) ); 
				
				});
				
			//console.log ( temp );
				
			setIdPhotos ( temp );
        }
	} 
	catch (error) {
	}

  };
		
		
  const getPhotos = async () => {
						   
	const url = `${API_URLS.GetPhotosForVote}`;

    var temp: Image[]  = []; 
    var rang: number[] = [];
	
	try {
		console.log (url)
		
		const response = await axios.get(url);
		
		console.log (response)
		
		const statut = response.status;

		if ( statut == 200 )  {

			const userPhotos = response.data['hydra:member'];

    		for (let i = 0; i < userPhotos.length; i++) {
		
			   dispatch ( 
					addPhoto (
                        userPhotos[i].id                              ,
						userPhotos[i].file                            ,
						userPhotos[i].name                            ,
						userPhotos[i].description                     ,
					  ( userPhotos[i].choosen             == "true" ) ,
					  ( userPhotos[i].authorCertification == "true" ) ,
						userPhotos[i].cameraBrand                     ,
						userPhotos[i].cameraModel                     ,
						userPhotos[i].urlThumbnail                    ,
						userPhotos[i].urlMagnified                    ,
						userPhotos[i].widthThumbnail                  ,
						userPhotos[i].heightThumbnail                 ,
						userPhotos[i].firstname                       ,
						userPhotos[i].lastname
					)); 

               rang.push (i);

               //console.log (userPhotos[i])

              }
			
            rang.sort(() => Math.random() - 0.5);

			for (let i = 0; i < rang.length; i++) {
			
			    const r = rang[i];
			
		        temp.push ( 
					{
					  width:      ensemble.photos[r].width,
					  height:     ensemble.photos[r].height,
                      src:        ensemble.photos[r].urlThumbnail,
                      isSelected: idPhotos.includes(ensemble.photos[r].id),
                     // caption:    ensemble.photos[r].name

                      customOverlay: (
                                        <div className={classNames(styles.custom_overlay_caption, className)}>
                                          <div>{ensemble.photos[r].name}</div>
                                        </div>
                                     )
                    }
				  );
				
				dispatch ( updatePhotoSelection (
				                       r ,
									   idPhotos.includes(ensemble.photos[r].id)
				                     ) );
				
               //patchPhoto (r) ;

            }
		
		
         setImages  ( temp );
         setIndexes ( rang );
		}
				
	} 
	catch (error) {
	}

  };
    
  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

  const handleSelect = async (index: number) => {
  
    if ( index != -1 )
	{
  
    console.log ("handleSelect " , index , indexes [index] , indexes.length, ensemble.photos[indexes [index]] );
  
    const newSelected:boolean = ( typeof ( images[index].isSelected ) == "undefined" ? false : !images[index].isSelected );
  
    dispatch ( updatePhotoSelection ( indexes [index] , newSelected ) );
	
	const baseUrl = `${API_URLS.PatchPhotoVote}`; 
    const id      = ensemble.photos[indexes [index]].id;

    const url     = `${baseUrl}${id}`;
    const action  = ( newSelected ? "oui" : "non" );
 
    const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json'
      };
	  
	console.log ("handleSelect " , url , action );
  
	  
    try {

      const response = await axios.patch (
        
                                            url,
                              
                                            { 
											  ipAddress ,
                                              action 
											},
                                            
                                            {
                                              headers: headers
                                            }
											
                                         );
										 
    	console.log ( response );

      } 
      catch (error) {

      }
  
    const nextImages = images.map((image, i) =>
      i === index ? { ...image, isSelected: newSelected } : image
    );
	
    setImages(nextImages);
	}
	
	setRenderKey(prevKey => prevKey + 1);
  };



  const patchPhoto = async (index: number) => {
  
    // console.log ("patchPhoto " , index , );
  
	const baseUrl = "http://192.168.1.4:8001/api/patch2/"; 
    const id      = ensemble.photos[index].id;

    const url     = `${baseUrl}${id}`;
	
	const urlThumbnail    = `https://photos.lovelightchallenge.com/${ensemble.photos[index].file}_mini.jpeg`;
    const urlMagnified    = `https://photos.lovelightchallenge.com/${ensemble.photos[index].file}.jpeg`;
    const widthThumbnail  = ensemble.photos[index].width;
    const heightThumbnail = ensemble.photos[index].height;

    const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json'
      };
	  
	// console.log ("patchPhoto " , url , urlThumbnail, urlMagnified, widthThumbnail, heightThumbnail );
  
	  
    try {

      const response = await axios.patch (
        
                                            url,
                              
                                            { 
											  urlThumbnail, 
											  urlMagnified, 
											  widthThumbnail, 
											  heightThumbnail 
											},
                                            
                                            {
                                              headers: headers
                                            }
											
                                         );

      } 
      catch (error) {

      }
	  
  };
  

  
  const handleClick = (index: number) => {
  
    // console.log ("handleClick " , index , images[index].isSelected );
	
    setShowZoom_index( index );

  };

  const handleClosePhotoZoomClick = () => {
   
    setShowZoom_index( -1 );
  }

const imgs = [
   {
      src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      width: 320,
      height: 174,
      isSelected: true,
      caption: "After Rain (Jeshu John - designerspics.com)",
   },
   {
      src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
      width: 320,
      height: 212,
      tags: [
         { value: "Ocean", title: "Ocean" },
         { value: "People", title: "People" },
      ],
      alt: "Boats (Jeshu John - designerspics.com)",
   },
   {
      src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
      width: 320,
      height: 212,
   },
];



  return (
  
        <>

          {
            showCookiesParameters &&
            ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
          }
		  
          { 
            ( showZoom_index !== -1 ) && 
            ( <WindowPhoto2 onClickOnCloseButton  = {handleClosePhotoZoomClick} 
			                onClickOnSelectButton = {handleSelect} 
			                indexParam                 = {showZoom_index} 
							indexes               = {indexes} /> )
          }

          <Menus />

          <div className={classNames(styles.root, className)}>

           <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>

                <div className={classNames(styles.gauche, className)}>
                 <img src="/images/LovelightChallenge_long.png" className={classNames(styles.logo_ll, className)} />
                 <br/>
                 <div className={classNames(styles.compteur, className)}>
			      <FlipClockCountdown to        = {new Date('2024-12-02T22:00:00').getTime()}
				                      className = {classNames(styles.flip_clock, className)}
									  labels    = {['Jours', 'Heures', 'Minutes', 'Secondes']}            />
                  </div>
                  <br/>
                  <p  className={classNames(styles.text_bas, styles.texte_gras, styles.texte_avant_cloture, className)}>
 				   avant la clôture des votes
				  </p>
                </div>

                <div className={classNames(styles.droite, className)}>
                 <br/>
                 <p  className={classNames(styles.texte_gras, styles.texte_votez, className)}>Comment voter ?</p>
                 <br/>
                 <p><span className={classNames(styles.texte_gras, className)}>Avec un smartphone ou une tablette :</span> 
				    cliquez sur la photo de votre choix, cliquez sur l'icône <img src="/coche.png" alt="coche" className={classNames(styles.coche)}/> pour donner votre voix.
                    <span className={classNames(styles.texte_gras, className)}>Pour une meilleure expérience utilisateur, il est 
					conseillé d'orienter votre smartphone en mode paysage.</span></p>
                 <br/>
                 <p><span className={classNames(styles.texte_gras, className)}>Avec un laptop ou un poste fixe :</span> 
				    déplacez votre souris sur la photo de votre choix puis cliquez sur l'icône <img src="/coche.png" alt="coche" className={classNames(styles.coche)}/> pour donner votre voix.</p>
                 <br/>
                 <p>Les photos dans la grille sont dans un format compressé, cliquez sur la photo pour la visualiser en haute définition, 
				    puis cliquez sur le "coeur", situé en haut à droite, si vous souhaitez donner votre voix ou la retirer.</p>
                 <br/>
                 <p>Vous pouvez voter pour le nombre de photos que vous souhaitez.</p>
                 <br/>
                 Retrouvez toute l'actu du lovelight challenge sur&nbsp;
                       <Link to="https://www.instagram.com/lovelight_challenge/" target="__blank">
                          <img src="/images/Instagram.png" className={classNames(styles.minilogo, className)} alt="Instagram"/>
                       </Link>
                       &nbsp;et&nbsp;
                       <Link to="https://www.facebook.com/profile.php?id=61551359803735" target="__blank">
                          <img src="/images/Facebook.png" className={classNames(styles.minilogo, className)} alt="Facebook"/>
                       </Link>
                 <br/>
                 <br/>
                </div>

              </div>
    

              <Gallery images={images} onSelect={handleSelect} onClick={handleClick} margin={8} rowHeight={600}/>
  
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>
            
  );
  
  
  
  /*
  return (
  
    <>
      <div className={classNames(styles.root)}>
        <Gallery images={images} onSelect={handleSelect} onClick={handleClick} margin={8} rowHeight={600}/>
	  </div>
	</>
  )
  */
};

export default Vote;
