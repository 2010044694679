import   React            , 
       { useState         }  from "react";
import   classNames          from 'classnames';

import   styles              from './album2.module.scss';
import { useTypedSelector }  from '../../hooks/useTypeSelector';
import { Photo2           }  from '../bloc_photo2/photo2';

export interface Album2Props {
  className?:     string;
}

export const Album2 = ({ className  }: Album2Props) => {

  const { registration } = useTypedSelector((state) => state.registrationManagment );


  return (
   
    <div className={classNames(styles.bloc, className)}>
 
      {
	    registration.photos.map ( ( photo, index ) => (
	  
          <Photo2 photo     = {photo} 
		          firstname = {registration.firstname} 
				  lastname  = {registration.lastname}   />
          )
	    )
	   
	  }

	  
    </div>
	
   );
};

export default Album2;
