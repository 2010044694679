export enum ActionType
 {
  UPDATE_CHOICES = 'UPDATE_CHOICES'
 }

export interface Choices
 {
  cookies1: boolean,
  cookies2: boolean,
  cookies3: boolean
 }

interface actionUpdateChoices
 {
  type:    ActionType.UPDATE_CHOICES;
  choices: Choices;
 }

export type Action = actionUpdateChoices;