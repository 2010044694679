import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import { Link                 , 
         useNavigate          }  from 'react-router-dom';
		 import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



import   styles                  from './cookies_information_leaflet.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';
import { Trigger              }  from '../widget_trigger/trigger';

export interface CookiesInformationLeafletProps {
  className?: string;
}

export const CookiesInformationLeaflet = ({ className }: CookiesInformationLeafletProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {

    console.log ("CookiesInformationLeaflet.useEffect : " , showCookiesParameters, firstRender);

    if ( firstRender )
     {
      console.log ("CookiesInformationLeaflet.useEffect => firstRender");
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
	 
    console.log ("CookiesInformationLeaflet.useEffect : " , showCookiesParameters, firstRender);
  }); 
  

  const handleOpenCookiesManagerClick = () => {
  
      console.log ("CookiesInformationLeaflet.open");
    setCookiesParameters( true );

    console.log ("CookiesInformationLeaflet.open : " , showCookiesParameters, firstRender);
  };

  const handleCloseCookiesManagerClick = () => {
      console.log ("CookiesInformationLeaflet.close");

    setCookiesParameters( false );

    console.log ("CookiesInformationLeaflet.close : " , showCookiesParameters, firstRender);
  };


  return (
  
        <>
              { 
                showCookiesParameters &&
              
                ( <WindowCookiesManager onClickOnCloseButton = {handleCloseCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
        <h1>Notice d’information sur les cookies</h1>
		
        <br/>

        <p className={classNames(styles.texte, className)} >
			Nous attachons une grande importance à la confidentialité et nous nous efforçons 
			d’améliorer la convivialité de nos sites Internet. C’est pourquoi nous tenons à vous 
			expliquer comment et pourquoi nous utilisons des cookies. Nous, Lovelight Factory 
			plaçons des cookies, des balises Web, Javascript et un stockage local (les « cookies ») 
			sur votre appareil lorsque vous visitez nos sites Internet. Les cookies font en sorte 
			que votre appareil collecte des informations (via Javascript). Votre appareil les marque 
			ensuite avec de petits fichiers en texte simple (cookies et stockage local), puis les 
			envoie au moment adapté (balises Web). De cette manière, les cookies nous permettent de 
			reconnaître votre appareil lors de votre prochaine visite sur l’un de nos sites Internet.
        </p>
		
		
        <br/>
		
		<Accordion allowZeroExpanded className={classNames(styles.accordion, className)}>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						1. Quels cookies déposons-nous et pourquoi
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Cookies fonctionnels
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Ces cookies sont nécessaires au fonctionnement de base du site et sont donc 
						placés sur votre appareil lorsque vous consultez nos sites Internet. Ces 
						cookies mémorisent vos préférences lorsque vous utilisez nos sites Internet. 
						Ils sont également utilisés pour répartir la charge sur nos serveurs, afin 
						que le site soit toujours disponible, et également pour des raisons de sécurité.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Les cookies fonctionnels sont placés aux fins suivantes :
						<ul>
							<li>Reconnaître vos paramètres lors d’une prochaine visite ;</li>
							<li>Servir pour la connexion à plusieurs demandes émises lors d’une seule 
							session ;</li>
							<li>Mémoriser vos préférences, par exemple un filtre produit, des outils de 
							comparaison, une langue, une géolocalisation ainsi que le nombre souhaité de 
							résultats de recherche ;</li>
							<li>Les articles que vous ajoutez à votre liste d’achat en ligne pendant le 
							shopping en ligne sont mémorisés et stockés ;</li>
							<li>Envoyer une notification en cas de commande non achevée, pour vous 
							éviter de perdre votre liste d’achat ;</li>
							<li>Effectuer des achats en toute sécurité sur les sites Internet de 
							Lovelight Factory ;</li>
							<li>Les sites Internet et les applications sont chargés de manière homogène, 
							afin de garantir leur bon fonctionnement et leur accessibilité ;</li>
							<li>Détecter des abus ou des problèmes potentiels sur nos sites Internet, 
							applications et services, par exemple en enregistrant le nombre de 
							tentatives de connexion successives infructueuses.</li>
						</ul>
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Votre consentement n’est pas requis pour l’utilisation des cookies fonctionnels, 
						qui servent au fonctionnement de base des sites Internet et n’ont pas 
						d’incidence (ou seulement une incidence limitée) sur votre vie privée.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Cookies d’analyse
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Ces cookies sont utilisés pour collecter des informations sur la manière dont 
						les visiteurs utilisent les sites Internet de Lovelight Factory, y compris des 
						informations sur les pages les plus visitées et les messages d’erreur affichés. 
						Les cookies d’analyse nous permettent de collecter des statistiques relatives 
						aux utilisateurs de nos sites Internet. Ces cookies aident Lovelight Factory à 
						améliorer ses sites Internet.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Nous utilisons Google Analytics pour générer des statistiques agrégées 
						concernant les visites sur nos sites Internet. Dans ce contexte, nous avons 
						conclu un accord de traitement avec Google. Les données obtenues via Google 
						Analytics sont partagées avec d’autres services Google.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Les données suivantes sont stockées au moyen de cookies dans le système 
						d’analyse :
						<ul>
							<li>Adresse IP ;</li>
							<li>Fonctionnalités techniques telles que le navigateur que vous utilisez 
							(par exemple Chrome, Internet Explorer ou Firefox) et la résolution de 
							votre écran d’ordinateur ;</li>
							<li>Page d’origine vous ayant conduit au site Internet de Lovelight Factory ;</li>
							<li>Date/heure et durée de votre visite ou de votre utilisation des sites 
							Internet Lovelight Factory ;</li>
							<li>Utilisation ou non des fonctionnalités des sites Internet. Par exemple 
							la création d’une liste d’achat, le placement d’une commande en ligne ou la 
							consultation vos achats précédents ;</li>
							<li>Pages visitées sur nos sites Internet ;</li>
							<li>Votre interaction avec les produits offerts par Lovelight Factory et les 
							promotions qui vous sont proposées ;</li>
							<li>Vos recherches, y compris leurs résultats et les étapes suivies pendant un 
							processus de commande ;</li>
							<li>Vos transactions (avec consentement) ;</li>
							<li>Votre utilisation des services Lovelight Factory sur divers appareils 
							(avec consentement) ;</li>
							<li>Identificateurs uniques, par exemple l’identifiant de visiteur unique 
							attribué à votre appareil quand vous consultez nos sites Internet, 
							l’identifiant de transaction haché et l’identifiant de membre haché.</li>
						</ul>
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Nous utilisons ces informations aux fins suivantes :
						<ul>
							<li>Suivre le nombre de visiteurs sur nos pages Web ;</li>
							<li>Mesurer le temps que chaque visiteur passe sur nos pages Web ;</li>
							<li>Déterminer l’ordre dans lequel un visiteur consulte les différentes 
							pages de nos sites Internet ;</li>
							<li>Repérer les parties de nos sites et applications qui ont besoin 
							d’être ajustées ;</li>
							<li>Utiliser les données collectées pour d’autres services Google 
							(uniquement après consentement).</li>
						</ul>
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Cookies de marketing
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Ces cookies sont généralement placés sur les sites Internet de Lovelight 
						Factory par des partenaires marketing, des réseaux publicitaires et des 
						plates-formes de réseaux sociaux. Ces parties tierces jouent le rôle 
						d’intermédiaires pour vous présenter notre contenu, par exemple des messages, 
						des offres, des contenus de réseaux sociaux et des annonces publicitaires. 
						Ces parties tierces collectent également des données via nos sites Internet 
						en utilisant des cookies. Le traitement de ces données est soumis à leurs 
						politiques de confidentialité.
					</p>	
                    <p className={classNames(styles.texte, className)} >
						Les objectifs des cookies de marketing incluent :
						<ul>
							<li>La connexion à des réseaux sociaux pour que vous puissiez accéder à 
							des contenus et les partager depuis nos sites Internet via les réseaux 
							sociaux ;</li>
							<li>Collecter des informations pour nous permettre de personnaliser le 
							marketing selon vos centres d’intérêt, à la fois sur nos sites Internet 
							et ailleurs ;</li>
							<li>Limiter le nombre d’occurrences d’affichage de nos annonces ;</li>
							<li>Atteindre nos visiteurs en fournissant des contenus personnalisés 
							pertinents sur différents types de supports, par exemple e-mail, réseaux 
							sociaux et bannières publicitaires en fonction de vos actions sur nos 
							sites Internet ;</li>
							<li>Mesurer l’efficacité de nos campagnes marketing.</li>
						</ul>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						2. Contrôler les cookies que nous enregistrons et utilisons
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Si vous souhaitez révoquer votre consentement, vous pouvez régler les paramètres 
						de votre navigateur afin de supprimer nos cookies et ceux des parties tierces. 
						Vous pouvez également ajuster les paramètres de votre navigateur pour empêcher 
						les sites Internet de déposer aussi bien leurs cookies que ceux des tiers. 
						Si vous nous empêchez de placer certains cookies, certaines fonctionnalités 
						risquent de ne pas être disponibles ou certaines parties du site Internet 
						risquent de ne pas pouvoir être chargées. Découvrez comment ajuster les 
						paramètres de :
						<ul>
							<li><Link to="https://support.mozilla.org/fr-FR/kb/cookies-information-websites-store-on-your-computer" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Firefox</Link></li>
							<li><Link to="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Edge</Link></li> 
							<li><Link to="https://support.apple.com/kb/PH21411?locale=en_US&viewlocale=en_US" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Safari</Link></li>
							<li><Link to="https://www.sizmek.com/privacy-policy/optedout/"  
							          className = { classNames(styles.link, className) } 
							          target="__blank">Sizmek</Link></li>
							<li><Link to="https://radiumone.com/opt/" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Radiumone</Link></li>
							<li><Link to="https://www.appnexus.com/fr/platform-privacy-policy" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Appnexus</Link></li>
							<li><Link to="https://www.facebook.com/settings/?tab=ads" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Facebook</Link></li>
							<li><Link to="https://adssettings.google.com/" 
							          className = { classNames(styles.link, className) } 
							          target="__blank">Google</Link></li>
						</ul>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						3. Notre notice d’information sur les données personnelles
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Pour plus d’informations sur la manière dont Lovelight Factory traite vos 
						données personnelles, veuillez consulter notre Notice d’information sur les 
						données personnelles.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
						4. Coordonnées en cas de question sur la protection des données personnelles
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
						Lovelight Factory – 1 place de la Fontaine – 06610 La Gaude – France
                    </p>
                    <p className={classNames(styles.texte, className)} >
						E-mail : team@lovelightchallenge.com
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

        </Accordion>
		
		<br/>
		
        <p className={classNames(styles.texte, className)} >
			Nous avons très récemment mis à jour cet avis novembre 2023 et celui-ci remplace les 
			versions antérieures.
        </p>


                    <Trigger name        = "Centre de préférences de la confidentialité"
                             link        = "#"
                             onClick     = {handleOpenCookiesManagerClick}
			                 className   = {classNames(styles.trigger_registration, className)} />


		
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>

            
  );
};

export default CookiesInformationLeaflet;
