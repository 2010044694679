import React      from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { faAsterisk      }  from '@fortawesome/free-solid-svg-icons';


import styles     from './select.module.scss';

export interface FieldProps {
  className?:    string;                                                   // classe CSS complémentaire
  name:          string;                                                   // Nom du champ écrit au dessus de la zone de saisie
  choices:       string[];
  value?:        string;                                                   // La valeur de l'input
  error?:        string;                                                   // Le message d'erreur
  required?:     string | undefined;                                      // éditable ou non
  disabled?:     boolean | undefined;                                      // éditable ou non
  onChange?:     (event: React.ChangeEvent<HTMLSelectElement>) => void;     // Ajout d'une prop pour gérer le changement de l'input
}

export const Select = ({ className   , 
                         name        ,
                         choices     ,						 
                         value       , 
                         error       , 
                         disabled    ,
						 required    ,
                         onChange    }: FieldProps) => {

  return (
  
           <div className={classNames(styles.select, className)}>
 

            { 
              ( name === "" )
              ?
			  ( <></> )
			  :
			  (
               <div className={classNames(styles.select_nom, className)} >
	 		     {name}

			     { ( required === "true" ) 
			       ?
			       ( <i className={classNames(styles.required, className)}>&nbsp;
				    <FontAwesomeIcon icon={faAsterisk} className={classNames(styles.redIcon, className)}/></i> ) 
                   :
			       ( <></> ) }

                   </div>
			   )
			}

	         <select className   = { classNames(styles.select_input, className) }
				        value       = { value                                      }
                        disabled    = { disabled                                   }
                        onChange    = { onChange                                   } >
				
				  { choices.map ( (choice) => ( <option className={classNames(styles.option, className)} value={choice}>{choice}</option> ) ) } 
      
	         </select>
	   
   		     <div className={classNames(styles.select_info, className)} >{error}</div>

      
           </div>
           
         );
};

export default Select;
