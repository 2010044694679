import   React      , 
       { useEffect  ,
         useState   }  from 'react';
import   classNames    from 'classnames';

import   styles        from './window_photo.module.scss';

export interface WindowPhotoProps {
  className?:             string;
  onClickOnCloseButton:   (() => void)
  onClickOnSelectButton?: ((index:number) => void)
  url:                    string
  nom?:                   string
  isSelected?:            boolean
  index?:                 number
  firstname?:                   string
  lastname?:                   string
}

export const WindowPhoto = ({ className, 
                              onClickOnCloseButton, 
							  onClickOnSelectButton,
							  url , 
							  nom ,
							  isSelected,
							  index ,
                              firstname,
                              lastname }: WindowPhotoProps) => {

  const [ select , setSelect ] = useState(isSelected);
  const [ hover  , setHover  ] = useState(false);


  const handleClick = () => {
       onClickOnCloseButton();
  };

  const handleClickSVG = () => {
	   setSelect (!select);
	   
	   if ( typeof (onClickOnSelectButton) !== "undefined" )
	    {
	     onClickOnSelectButton (index??-1);
		}
  };

  const handleHover = () => {
	   setHover (true);
  };

  const handleMouseOut = () => {
	   setHover (false);
  };



/*
      <div className={classNames(styles.divCoeur, className)}>
		<svg width="32"
             height="32"
             viewBox="0 0 32 32" 
			 onClick = {handleClickSVG}
			 xmlns="http://www.w3.org/2000/svg">
  
           <circle fill="#FFFFFFFF" cx="15" cy="15" r="15" />
	
	       <path fill= {select?"#FF0000FF":"#000000FF"} d="M13.53014 10.46972C12.13268 9.07226 9.86694 9.07226 8.46948 10.46972C7.07202 11.8672 7.07202 14.13292 8.46948 15.5304L14.99982 22.9276L21.5302 15.53038C22.9276 14.13292 22.9276 11.8672 21.5302 10.46972C20.1326 9.07226 17.86694 9.07226 16.46948 10.46972L14.99982 11.9394L13.53014 10" />
		   
        </svg>
		
      </div>
*/
  return (

    <div className = { classNames(styles.cadrePhoto, styles.bloc, className) } >

      <div className = { classNames(styles.bloc_ligne_info, className) } >
	   <span className = { classNames(styles.titre, className) }>{nom}</span><br/>Crédit photo : {firstname} {lastname}<br/>
	  </div>
	  
      <div className = { classNames(styles.bloc_ligne_photo, className) } >
       <img src       = {url} 
            className = { classNames(styles.photo, className) }  
            alt       = "zoom"                                 />
	  </div>
		
      <div className={classNames(styles.divCroix, className)}>

		<svg width="32"
             height="32"
             viewBox="0 0 32 32"
			 onClick = {handleClick}
             onMouseOver={handleHover}
             onMouseOut={handleMouseOut}
 			 xmlns="http://www.w3.org/2000/svg">

             <circle fill="#FFFFFFFF" cx="15" cy="15" r="15" />
              <polygon points="512,59.076 452.922,0 256,196.922 59.076,0 0,59.076 196.922,256 0,452.922 59.076,512 256,315.076 452.922,512 512,452.922 315.076,256"
                       fill={hover?"#FF0000FF":"#000000FF"}
			           transform="scale(0.04)  translate(120, 120)"/>
        </svg>

      </div>




		
	</div>
    );

};

export default Window;
