import   React        from 'react';
import   classNames   from 'classnames';

import   styles       from './window_question.module.scss';

import { Trigger    } from '../widget_trigger/trigger';

export interface WindowQuestionProps {
  className?:           string;
  message:              string
  onClickOnCancelButton: (() => void)
  onClickOnValidButton: (() => void)
}

export const WindowQuestion = ({ className, 
                                message, 
								onClickOnCancelButton ,
								onClickOnValidButton }: WindowQuestionProps) => {

  const handleClickCancel = () => {

       onClickOnCancelButton();
  };

  const handleClickValid = () => {

       onClickOnValidButton();
  };


  return (
            <div className={classNames(styles.window, className)}>

              <p className   = {classNames(styles.message, className)}>{message}</p>

			  <Trigger link      = "#"
                       name      = "Annuler"
                       onClick   = {handleClickCancel}
                       className = {classNames(styles.trigger_window, className)}/>

			  <Trigger link      = "#"
                       name      = "Valider"
                       onClick   = {handleClickValid}
                       className = {classNames(styles.trigger_window, className)}/>

            </div>
         );
};

export default WindowQuestion;
