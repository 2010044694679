import   React      , 
       { useState   } from 'react';
import   classNames   from 'classnames';
import   axios        from 'axios';
import   styles       from './window_forgotten_password.module.scss';

import { Trigger    } from '../widget_trigger/trigger';
import { Field      } from '../widget_field/field';

import { API_URLS   } from '../../urls/urls';

export interface WindowForgottenPasswordProps {
  className?:           string;
  onClickOnCloseButton: (() => void)
}

export const WindowForgottenPassword = ({ className, onClickOnCloseButton }: WindowForgottenPasswordProps) => {

  const [ email            , setEmail              ] = useState('');
  
  const [ showMessageError , setShowMessageError   ] = useState(0);
  const [ showMessageAck   , setShowMessageAck     ] = useState(false);
  
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleClickAccept = () => {
  
       checkValue (email);
  };

  const handleClickRefuse = () => {

       onClickOnCloseButton();
  };

  const handleClickClose = () => {

       onClickOnCloseButton();
  };

  const checkValue = async (email: string): Promise<boolean> => {

    const baseUrl  = `${API_URLS.GetPasswordResetRequest}?email=`; // "https://api.lovelightfactory.com/api/password_reset_request?email=";
    const val = encodeURIComponent (email);

    const url = `${baseUrl}${val}`;

    var resultat = false;

    try {

      const response = await axios.get(url);
      const statut   = response.status;

      console.log (response);

      if ( statut === 200 )
       {
        resultat = true;

        setShowMessageError (0);
        setShowMessageAck   (true);

        console.log ("showMessageAck" , showMessageAck);

       }
    }
    catch (error) {

      setShowMessageError (1);
      setShowMessageAck   (false);

    }

    return resultat;
  };


  return (
            <>
			
            <div className={classNames(styles.window, className)}>
			  
			  <p>Veuillez saisir votre adresse e-mail</p>
			
			   <Field   name        = "Adresse E-mail"
		                type        = "email"
			     		placeholder = "Entrez votre adresse e-mail"
                        value       = {email}
                        error       = ""
                        onChange    = {handleEmailChange}
						autocomplete = "on"
               />
			   
              { 
                ( showMessageAck ) 
				
				?
				
                ( 
				  <>
				  
                  <Trigger link      = "#"
                           name      = "Fermer" 
                           onClick   = {handleClickClose}
                           className = {classNames(styles.trigger_window, className)}/>

				  <div>
                   <p className   = {classNames(styles.message_ack, className)}>
                    Si votre adresse e-mail est référencée, vous recevrez un message pour modifier votre mot de passe.
                   </p>
                  </div>

                  </>				  
				)
                
				:
				
				(
				  <>

                  <Trigger link      = "#"
                           name      = "Annuler" 
                           onClick   = {handleClickRefuse}
                           className = {classNames(styles.trigger_window, className)}/>

                  <Trigger link      = "#"
                           name      = "Envoyer" 
                           onClick   = {handleClickAccept}
                           className = {classNames(styles.trigger_window, className)}/>
				
                  </>				  
				)
				
              }

              { 
                ( showMessageError !== 0 ) && 
                ( 
				  <div>
                   <p className = {classNames(styles.message_error, className)}>
                    Le serveur a rencontré une erreur interne lors de votre demande de réinitialisation.
                   </p>
                  </div> 
				)  
              }
					   
            </div>
			
			</>
         );
};

export default WindowForgottenPassword;
