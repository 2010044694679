import   React              ,
       { useEffect          ,
	     useState           }  from 'react';
import   classNames          from 'classnames';

import { Link               ,
         useNavigate        ,
         redirect         ,
         useLocation        ,
         useSearchParams    } from "react-router-dom";

import { useDispatch        }   from 'react-redux';

import { Dispatch           }   from 'redux';

import   axios              , 
       { AxiosError         }   from 'axios';
import { Container          ,
         Typography         ,
         Grid               ,
         Switch             }   from '@mui/material';

import   styles                 from './page_password_reset.module.scss';

import { updatePassword     ,
         updatePassword2    }   from '../../redux/registrationManagment/actionCreators';

import { useTypedSelector   }   from '../../hooks/useTypeSelector';
import { store              }   from '../../redux/store';

import { Field              }   from '../widget_field/field';
import { Trigger            }   from '../widget_trigger/trigger';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';


type LocationState = {
  from: {
    path: string;
  }
}

export interface PasswordResetProps {
  className?: string;
}

export const PasswordReset = ({ className }: PasswordResetProps) => {
  
  const location                = useLocation();
  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector((state) => state.registrationManagment   );

  const [ searchParams          , setSearchParams ] = useSearchParams();

  const [ token                 , setToken                ] = useState('');
  const [ email                 , setEmail                ] = useState('');
  const [ password              , setPassword             ] = useState('');
  const [ password2             , setPassword2            ] = useState('');

  const [ messagePassword       , setMessagePassword      ] = useState('');
  const [ messagePassword2      , setMessagePassword2     ] = useState('');

  const [ showMessageToken      , setShowMessageToken     ] = useState(false);
  const [ showMessageError      , setShowMessageError     ] = useState(false);
  const [ showMessageAck        , setShowMessageAck       ] = useState(false);

  useEffect(() => {

   // https://api.lovelightfactory.com/api/verif?signature=qvirRHMllgigpVKxOtXzuBaAD8yCxOLBQnZt0ErdjuPuntO8fk

   if ( token == '' )
    {
     const temp = new URLSearchParams(location.search).get("token");

     if ( temp )
           {
            setToken ( temp );
           }
      else {
            setShowMessageToken (true);
           }
    }
  });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword (e.target.value)
    dispatch ( updatePassword (e.target.value) );

    if ( registration.password.length > 0 && registration.password.length < 8)
          {
           setMessagePassword ("Le mot de passe fait moins de 8 caractères.");
          }
     else {
           setMessagePassword ("");
          }
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2 (e.target.value)
    dispatch ( updatePassword2 (e.target.value) );

    if ( registration.password2 != registration.password )
          {
           setMessagePassword2 ("Les deux mots de passe sont différents");
          }
     else {
           setMessagePassword2 ("");
          }
  };



  const handlePasswordUpdate = async () => {

    const baseUrl  = `${API_URLS.GetPasswordReset}?`; // "https://api.lovelightfactory.com/api/password_reset?";
    const nomInfo1 = "token=";
    const nomInfo2 = "password=";
    const valInfo1 = encodeURIComponent (token);
    const valInfo2 = encodeURIComponent (password);

    const url = `${baseUrl}${nomInfo1}${valInfo1}&${nomInfo2}${valInfo2}`;

    try {

      const response = await axios.get(url);
      const statut = response.status;
      const exist  = response.data.exist;

      if ( statut == 200 && exist == true )
            {
		     setShowMessageAck ( true );
            }
       else {
		     setShowMessageError ( true );
            }
    }
    catch (error) {

		setShowMessageError ( true );

    }
  };

  return (

            <>

              <br/>
              <br/>
              <br/>
              <br/>

              <div className={classNames(styles.root, className)}>


                <Container maxWidth="sm" className={classNames(styles.root, className)}>

                  <Typography variant="h4">Réinitialisation du mot de passe</Typography>

                  <Grid container spacing={0}>


                  {
                    ( showMessageToken )
                    ?
                    (
                        <>
                        <Grid item xs={12}>
				          <p className   = {classNames(styles.message_error, className)}>
					        Le token est absent. Vous pouvez cliquer sur le bouton ci-dessous pour vous rendre sur la page de connexion et faire une nouvelle demande de réinitialisation.
					      </p>
                        </Grid>

                        <Grid item xs={12}>

                          <Trigger name        = "Page de connexion"
                                   link        = "/connexion"
                                   className   = {classNames(styles.trigger_reset, className)}
                          />

                        </Grid>
                        </>
                    )
                    :
                    (

			          ( showMessageAck )
                      ?
                      (
                        <>
                        <Grid item xs={12}>
				          <p className   = {classNames(styles.message_ack, className)}>
					        Votre mot de passe a été modifié. Vous pouvez cliquer sur le bouton ci-dessous pour vous rendre sur la page de connexion.
					      </p>
                        </Grid>

                        <Grid item xs={12}>

                          <Trigger name        = "Page de connexion"
                                   link        = "/connexion"
                                   className   = {classNames(styles.trigger_reset, className)}
                          />

                        </Grid>
                        </>
                      )
                      :
                      (
                      <>
                      <Grid item xs={12}>

                        <Field  name        = "Mot de passe"
                                type        = "password"
                                placeholder = ""
                                value       = {password}
                                error       = {messagePassword}
                                onChange    = {handlePasswordChange}
                        />

                      </Grid>

                      <Grid item xs={12}>

                        <Field   name        = "Confirmation du mot de passe"
                                 type        = "password"
                                 placeholder = ""
                                 value       = {password2}
                                 error       = {messagePassword2}
                                 onChange    = {handlePassword2Change}
                        />

                      </Grid>

                      <Grid item xs={12}>

                        <Trigger name        = "Mettre à jour"
                                 link        = "#"
                                 onClick     = {handlePasswordUpdate}
                                 className   = {classNames(styles.trigger_reset, className)}
                        />

                      </Grid>
                      </>
                      )
                    )
			      }

                  </Grid>

                </Container>

				<br/>


			    {
			      ( showMessageError ) &&
                  ( <div>
				     <p className = {classNames(styles.message_error, className)}>
					  Le serveur a rencontré une erreur interne lors de la modification de votre mot de passe
					 </p>
                    </div> )
			    }

			  <br/>

		      </div>

            </>

         );

};

export default PasswordReset;
