import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';



import   styles                  from './challenge_rules.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';

export interface ChallengeRulesProps {
  className?: string;
}

export const ChallengeRules = ({ className }: ChallengeRulesProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender       ] = useState(true);

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
  }); 
  

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };


  return (
  
        <>

              {
                showCookiesParameters &&
                ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
              }

              <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
        <h1>REGLEMENT DU JEU-CONCOURS Lovelight Challenge</h1>
        <br/>
		
		<Accordion allowZeroExpanded className={classNames(styles.accordion, className)}>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 1 : Organisation
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
La SARL LOVELIGHT FACTORY au capital de 1000 €, ci-après désignée sous le nom « L'organisatrice », dont le siège social est situé 1 Place de la fontaine 06610 La Gaude, immatriculée sous le numéro RCS GRASSE 978665511, organise un jeu gratuit sans obligation d'achat du 12/07/2024 à 20h00 au 30/09/2024 à 22h00.

La participation au Concours implique l'acceptation expresse et sans réserve du présent règlement, en toutes ses stipulations, des règles de déontologie en vigueur sur Internet (nétiquette, charte de bonne conduite…) ainsi que des lois et règlements applicables en France. Tout litige concernant son interprétation sera tranché souverainement et sans appel par l'Organisateur.

Ce concours est ouvert aux personnes physiques majeures en France métropolitaine. Sont exclues toutes les personnes ayant participé à l'élaboration du concours de même que leur famille. Il s'agit notamment du personnel de Lovelight Factory et toutes les autres personnes liées directement à l'organisation de ce concours.

Le participant doit s'inscrire sur le Site lovelightchallenge.com. L'enregistrement des inscriptions s'effectue de façon continue du 12/07/2024 à 20h00 au 30/09/2024 à 22h00. L'heure de la réception de l'enregistrement de son inscription sur le serveur informatique dédié au Concours et hébergé chez le prestataire choisi par l'Organisateur, faisant foi. Aucun autre moyen de participation (notamment par courrier) ne sera pris en compte.
Le Participant est informé et accepte que les informations saisies dans le formulaire d'inscription vaillent preuve de son identité. Les informations saisies par le participant l'engagent dès leur validation. L'Organisateur se réserve le droit de vérifier l'exactitude des données fournies par les participants.

Le Participant s'engage à compléter de bonne foi le formulaire d'inscription mis à sa disposition et à transmettre à l'Organisateur des informations exactes et non contrefaites. Le Participant doit renseigner l'ensemble des zones de saisie, excepté celles mentionnées comme n'étant pas obligatoires.

Les Participations au Concours seront annulées si elles sont incorrectes, incomplètes, contrefaites ou réalisées de manière contrevenante au présent règlement.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 2 : Participants
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Ce jeu gratuit sans obligation d'achat est exclusivement ouvert aux personnes majeures, à la date du début du jeu, résidant en France métropolitaine (Corse comprise).

Sont exclues du jeu les personnes ne répondant pas aux conditions ci-dessus, ainsi que les membres du personnel de « L'organisatrice », et toute personne ayant directement ou indirectement participé à la conception, à la réalisation ou à la gestion du jeu ainsi que leur conjoint et les membres de leurs familles : ascendants et descendants directs ou autres parents vivant ou non sous leur toit.

« L'organisatrice » se réserve le droit de demander à tout participant de justifier des conditions ci-dessus exposées. Toute personne ne remplissant pas ces conditions ou refusant de les justifier sera exclue du jeu et ne pourra, en cas de gain, bénéficier de son lot.

Il n'est autorisé qu'une seule participation par personne (même nom, même adresse). « L'organisatrice » se réserve le droit de procéder à toute vérification pour le respect de cette règle.

La participation au jeu implique l'entière acceptation du présent règlement.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 3 : Modalités de participation
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Les participants doivent se rendre à l'adresse URL suivante :

• www.lovelightchallenge.com


Chaque participant (photographe principal) est identifié selon son adresse courriel, nom, prénom, adresse et numéro de téléphone. L'utilisation d'un pseudonyme pour la diffusion publique est autorisée, il est à indiquer lors de l'inscription.

Chaque photographe devra téléverser entre 1 et 3 photos.
La photo doit être proposée sous un format Jpeg (.jpg et .jpeg), d'une résolution minimale de 920x768 pixels.

Toute photo ne remplissant pas ses conditions sera exclue du Concours.
Le Participant garantit que les photos proposées sont originales et qu'il est le seul détenteur des droits de propriété intellectuelle attachés à ces photos. À ce titre, le Participant fera son affaire des autorisations de tous tiers ayant directement ou indirectement participé à leur réalisation et/ou qui estimeraient avoir un droit quelconque à faire valoir à son égard, notamment leur droit à l'image, et assumera la charge de tous les éventuels paiements en découlant.

De façon générale, le Participant garantit l'organisateur du présent Concours contre tous recours, actions ou réclamations que pourraient former, à un titre quelconque, tous tiers, à l'occasion de l'exercice des droits cédés aux présentes et plus généralement au titre de toutes les garanties et engagements pris au titre du présent accord. Lovelight Factory se réserve le droit de procéder à toute vérification pour l'application du présent article.

Du seul fait de leur participation, les participants autorisent expressément et à titre gracieux l'Organisateur à diffuser les photos sélectionnées pour la promotion du Lovelight Challenge sur tous supports de diffusion connus ou inconnus à ce jour à travers le monde et dans toute manifestation publique en Europe pour une durée de 36 mois à compter de la date d'annonce des lauréats. L'Organisateur peut également éditer un album contenant les photos des participants où 20% de la valeur de vente hors-taxe seront reversés au participant en cas de commercialisation.

L'Organisateur se réserve le droit de refuser toute demande de retrait d'une photo dans le délai de 12 mois à compter de la date de publication du contenu et de conserver une copie pour ses archives.

Le participant s'interdit de mettre en œuvre ou de chercher à mettre en œuvre tout procédé de participation qui ne serait pas strictement conforme au respect des principes du Concours et de ce présent règlement. L'usage de l'intelligence artificielle générative est interdit.

L'Organisateur se réserve le droit de disqualifier tout participant qui altère le fonctionnement du Concours ou du Site ou encore qui viole les règles officielles du Concours. L'Organisateur se réserve le droit de poursuivre quiconque tenterait de frauder ou de nuire au bon déroulement de ce Concours.

L'Organisateur se réserve le droit de procéder à toute vérification pour le respect du présent article comme de l'ensemble du règlement, notamment pour écarter tout participant ayant commis un abus quelconque ou une tentative de fraude, sans toutefois qu'il ait l'obligation de procéder à une vérification systématique de l'ensemble des formulaires d'inscription reçus, mais pouvant éventuellement limiter cette vérification aux formulaires des gagnants potentiels.

L'Organisateur se réserve le droit de proroger, d'écourter, de modifier ou d'annuler le présent Concours en raison d'événements indépendants de sa volonté. Ainsi, si pour quelque raison que ce soit, ce Concours ne devait pas se dérouler comme prévu par suite par exemple d'un virus informatique, d'un bug, d'une intervention, ou d'une intrusion extérieure et non autorisée sur le système informatique, d'une fraude y compris l'utilisation d'un robot permettant de multiplier le nombre d'inscriptions au Concours, ou d'une défaillance technique ou tout autre motif dépassant le contrôle de l'Organisateur et corrompant ou affectant la gestion, la sécurité, l'équité, la bonne tenue du Concours, l'Organisateur se réserve alors le droit discrétionnaire d'annuler, de modifier ou suspendre le Concours ou encore d'y mettre fin sans délai, sans que les participants ne puissent rechercher sa responsabilité de ce fait.

L'Organisateur pourra décider d'annuler le Concours ou une participation s'il apparaît que des fraudes manifestes sont intervenues sous quelque forme que ce soit, notamment de manière informatique dans le cadre de la participation au Concours ou de la détermination des gagnants.

En s'inscrivant au Concours, le Participant s'engage à ce que l'intégralité des contenus déposés sur le Site respecte l'ensemble des législations en vigueur. L'organisateur prohibe plus particulièrement, sans que cela ne soit limitatif, tout type de contenu :

Ayant un caractère pornographique, pédophile, xénophobe ou raciste ;
Ayant un caractère de propagande pour adhérer à une pensée politique ou religieuse ;
Incitant au crime, à la violence, à la haine ou à la discrimination envers une personne ou un groupe de personnes, qu'elle soit basée sur le sexe, la religion, la nationalité, l'invalidité, l'orientation sexuelle, l'âge ou les opinions politiques ;
Relayant des informations fausses ou trompeuses dans le but de nuire ;
Portant atteinte aux droits de propriété intellectuelle d'une œuvre protégée ;
Portant atteinte à la réputation, à la vie privée et à l'image de tiers ;
Contenant des contenus liés à un intérêt manifestement commercial ou à but promotionnel ;
Contenant des mentions de marques déposées affichées de façon ostentatoire ;
Incitant à commettre un crime, un délit ou un acte de terrorisme ;
Portant atteinte à la sécurité ou à l'intégrité d'un État ou d'un territoire ;
Faisant l'apologie, la négation ou banalisant un crime contre l'humanité ;
Faisant l'apologie ou la promotion de groupes ou d'individus terroristes/extrémistes violents ; Cette liste n'est pas exhaustive.

L'Organisateur se réserve le droit, sans avoir à le justifier, de ne pas publier ou de retirer du Site, sans délai ou notification préalable les contenus (photos et/ou messages) qui relèveraient ou qu'il estimerait relever des catégories ci-avant listées, ainsi que tous contenus pouvant porter atteinte à son image, ses activités, ses valeurs, sa réputation ou sa notoriété.

Certains contenus peuvent ne pas être adaptés à tous les publics. Afin d'apporter un niveau complémentaire de protection, l'Organisation se réserve le droit de mettre en place un filtre pour empêcher des publics vulnérables d'accéder à des contenus soumis à une restriction d'âge. Cela concerne en particulier les sujets suivants :

Des scènes de nudité et/ou des comportements et références sexuel(le)s ;
Des actes ou propos inappropriés et/ou pouvant être considérés comme choquant pour les jeunes publics (incluant mais ne se limitant pas à des scènes obscènes ou violentes) ;
Des scènes dangereuses ou des activités à risque susceptibles d'être imitées par des mineurs ;

Cette liste n'est pas exhaustive.

Le participant doit remplir totalement et correctement le formulaire de renseignements pour que son inscription soit validée. Le joueur est informé et accepte que les informations saisies dans le formulaire d'inscription vaillent preuve de son identité.

Toute participation effectuée contrairement aux dispositions du présent règlement rendra la participation invalide. Tout participant suspecté de fraude pourra être écarté du jeu-concours par « L'organisatrice » sans que celle-ci n'ait à s'en justifier. Toute identification ou participation incomplète, erronée ou illisible, volontairement ou non, ou réalisée sous une autre forme que celle prévue dans le présent règlement sera considérée comme nulle. La même sanction s'appliquera en cas de multi-participation.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 4 : Gains
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Les dotations mises en jeu sont réparties comme suit :

• Du 1er au 10ème lot : Participation au tournage de l'émission des présélections au Lovelight Challenge

Détails :
Le participant, en cas de présélection s'engage à se déplacer à l'endroit, qui aura été communiqué par l'Organisateur en France, entre les mois de janvier et février 2025, pour le tournage des épisodes de présélection. Les dates précises des tournages seront communiquées entre le 1er décembre et le 23 décembre 2024. Les frais de déplacement et d'hébergement sont à la charge du candidat présélectionné. Seuls les frais de restauration du déjeuner seront pris en charge par la production.

Si le candidat fait partie des 5 sélectionnés à l'issu des épreuves de présélection, il sera reconvoqué au minimum 30 jours à l'avance, pour participer aux tournages des phases finales. A ce titre, les frais de déplacement, d'hébergement et de restauration du déjeuner seront pris en charge par la production.

Si le candidat ne pouvait pas se déplacer aux dates proposées par la production, le candidat sera éliminé d'office du jeu concours pour le motif d'abandon.


Tous les frais exposés postérieurement au jeu notamment pour l'entretien et l'usage de ces lots sont entièrement à la charge du gagnant.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 5 : Désignation des gagnants
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
LA PRÉSÉLECTION DES 10 FINALISTES :

Les Dix finalistes seront présélectionnés grâce à un vote du public sur le site du LOVELIGHT CHALLENGE (www.lovelightchallenge/com). Les votes commenceront le 07/10/2024 à 13h00 et se termineront le 02/12/2024 à 22h00 (Heure de Paris).

La liste des 10 finalistes présélectionnés sera annoncée le 16/12/2024 à 18h00 sur le site www.lovelightchallenge.com et sera relayée dans une vidéo diffusée sur la chaine YouTube https://www.youtube.com/@lovelight_tv et le site https://lovelight.tv

Les participants finalistes autorisent, à titre gracieux, la diffusion de tout ou partie de leurs photos par les partenaires de l'événement pour une durée de 24 mois à compter de la date d'annonce des lauréats.

VOTE DU PUBLIC pour les PRÉSÉLECTIONS DES 10 FINALISTES : CONDITIONS DE PARTICIPATION AU VOTE :

Le vote se fera exclusivement sur le Site. Le vote est ouvert à toute personne physique à l'exception des personnes physiques en charge de l'organisation du Concours.
Chaque votant ne peut voter via le bouton « Voter pour ce photographe » qu'une seule fois pour chaque photo en compétition pendant toute la durée de la présélection.

DEROULEMENT DU SCRUTIN SUR INTERNET :

Afin d'enregistrer son vote chaque internaute doit reconnaître avoir pris connaissance et accepter le règlement.

L'Organisateur se réserve le droit de procéder à toute vérification pour le respect du présent article notamment pour écarter tout participant ayant commis un abus quelconque ou une tentative de fraude, sans toutefois qu'il ait l'obligation de procéder à une vérification systématique de l'ensemble des votes.

Si pour quelque raison que ce soit, les votes ne devaient pas se dérouler comme prévu par suite par exemple d'un virus informatique, d'un bug, d'une intervention, ou d'une intrusion extérieure et non autorisée sur le système informatique, d'une fraude y compris l'utilisation d'un robot permettant de multiplier le nombre de votes, ou d'une défaillance technique ou tout autre motif dépassant le contrôle de l'Organisateur et corrompant ou affectant la gestion, la sécurité, l'équité, la bonne tenue du Concours, l'Organisateur se réserve alors le droit d'annuler des votes, d'annuler, modifier ou suspendre le Concours ou encore d'y mettre fin sans délai, sans que les participants ne puissent rechercher sa responsabilité de ce fait.

DATES DU VOTE :

Les votes se déroulent du 12/07/2024 à 20h00 au 30/09/2024 à 22H00 (heure de Paris). Seuls les votes enregistrés pendant cette période seront pris en compte dans le classement final.

En cas d'ex aequo : Le gagnant sera celui désigné par un membre de l'organisation.

Date(s) de désignation : le 16 décembre 2024 à 18h00.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 6 : Annonce des gagnants
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
• Les gagnants seront informés par e-mail à l'adresse indiquée lors de l'inscription au jeu-concours
• La liste des gagnants sera disponible sur le site du jeu-concours
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 7 : Remise des lots
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Le lot étant la participation aux présélections du Lovelight challenge, aucune remise ne sera faite physiquement et les gagnants seront informés par e-mail de leur présélection.

Le lot restera à disposition du participant pendant 15 jours. Après ce délai, il ne pourra plus y prétendre.

Les gagnants s'engagent à accepter les lots tels que proposés sans possibilité d'échange notamment contre des espèces, d'autres biens ou services de quelque nature que ce soit ni transfert du bénéfice à une tierce personne. De même, ces lots ne pourront faire l'objet de demandes de compensation.

« L'organisatrice » se réserve le droit, en cas de survenance d'un événement indépendant de sa volonté, notamment lié à ses fournisseurs ou à des circonstances imprévisibles, de remplacer les lots annoncés, par des lots de valeur équivalente. Le gagnant sera tenu informé des éventuels changements.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 8 : Utilisation des données personnelles des participants
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Les informations des participants sont enregistrées et utilisées par « L'organisatrice » pour mémoriser leur participation au jeu-concours et permettre l'attribution des lots.

Les participants peuvent, pour des motifs légitimes, s'opposer à ce que leurs données personnelles communiquées dans le cadre de ce jeu fassent l'objet d'un traitement. Ils disposent également d'un droit d'opposition à ce qu'elles soient utilisées à des fins de prospection commerciale, en dehors de la participation à ce jeu-concours, qu'ils peuvent faire valoir dès l'enregistrement de leur participation en s'adressant par courrier à « L'organisatrice » dont l'adresse est mentionnée à l'article 1.

Le/les gagnant(s) autorisent « L'organisatrice » à utiliser à titre publicitaire ou de relations publiques leurs coordonnées (nom, prénom), sur quelque support que ce soit, sans que cela ne leur confère une rémunération, un droit ou un avantage quelconque, autre que l'attribution de leur lot.

Conformément à la Loi Informatique et Libertés dans sa dernière version, ainsi qu'au Règlement n°2016/679 du parlement européen et conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (RGPD), le participant peut exercer son droit d'accès, de rectification, d'effacement des données, de limitation du traitement, son droit à la portabilité des données, son droit d'opposition, ainsi que son droit au retrait de son consentement en s'adressant par courrier à « L'organisatrice » dont l'adresse est mentionnée à l'article 1.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 9 : Règlement du jeu
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Le règlement pourra être consulté sur le site suivant :

• www.lovelightchallenge.com/reglement-du-lovelight-challenge


Il peut être adressé à titre gratuit à toute personne qui en fait la demande auprès de « L'organisatrice ».

« L'organisatrice » se réserve le droit de prolonger, écourter, modifier ou annuler le jeu à tout moment, notamment en cas de force majeure, sans qu'il puisse être prétendu à aucune indemnité par les participants. Le règlement modifié par avenant(s), sera déposé, le cas échéant à la SELARL ACTA - PIERSON et ASSOCIES titulaire d'un office d'huissier de justice domiciliée 15 rue de Sarre BP 15126 57074 METZ Cedex 3.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 10 : Propriété industrielle et intellectuelle
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
La reproduction, la représentation ou l'exploitation de tout ou partie des éléments composant le jeu, le présent règlement compris sont strictement interdites.

Toutes les marques, logos, textes, images, vidéos et autres signes distinctifs reproduits sur le site ainsi que sur les sites auxquels celui-ci permet l'accès par l'intermédiaire de liens hypertextes, sont la propriété exclusive de leurs titulaires et sont protégés à ce titre par les dispositions du Code de la propriété intellectuelle et ce pour le monde entier. Leur reproduction non autorisée constitue une contrefaçon passible de sanctions pénales.

Toute reproduction, totale ou partielle, non autorisée de ces marques, logos et signes constitue une contrefaçon passible de sanctions pénales.

La participation à ce jeu implique l'acceptation pleine et entière du présent règlement par les participants.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 11 : Responsabilité
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
La responsabilité de « L'organisatrice » ne saurait être engagée en cas de force majeure ou de cas fortuit indépendant de sa volonté.

« L'organisatrice » ne saurait être tenue pour responsable des retards, pertes, vols, avaries des courriers, manque de lisibilité des cachets du fait des services postaux. Elle ne saurait non plus être tenue pour responsable et aucun recours ne pourra être engagé contre elle en cas de survenance d'événements présentant les caractères de force majeure (grèves, intempéries...) privant partiellement ou totalement les participants de la possibilité de participer au jeu et/ou les gagnants du bénéfice de leurs gains.

« L'organisatrice » ainsi que ses prestataires et partenaires ne pourront en aucun cas être tenus pour responsables des éventuels incidents pouvant intervenir dans l'utilisation des dotations par les bénéficiaires ou leurs invités dès lors que les gagnants en auront pris possession.

De même « L'organisatrice », ainsi que ses prestataires et partenaires, ne pourront être tenus pour responsables de la perte ou du vol des dotations par les bénéficiaires dès lors que les gagnants en auront pris possession. Tout coût additionnel nécessaire à la prise en possession des dotations est à l'entière charge des gagnants sans que ceux-ci ne puissent demander une quelconque compensation à « L'organisatrice », ni aux sociétés prestataires ou partenaires.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 12 : Litige & Réclamation
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
Le présent règlement est régi par la loi française.

« L'organisatrice » se réserve le droit de trancher sans appel toute difficulté pouvant survenir quant à l'interprétation ou à l'application du présent règlement, étant entendu qu'aucune contestation ne sera admise notamment sur les modalités du jeu, sur les résultats, sur les gains ou leur réception, un mois après la fin du jeu. Sauf en cas d'erreurs manifestes, il est convenu que les informations résultant des systèmes de jeu de « L'organisatrice » ont force probante dans tout litige quant aux éléments de connexion et au traitement informatique des dites informations relatives au jeu.

Toute réclamation doit être adressée dans le mois suivant la date de fin du jeu à « L'organisatrice ». Passée cette date, aucune réclamation ne sera acceptée. La participation au jeu entraîne l'entière acceptation du présent règlement.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem className={classNames(styles.accordion__item, className)}>
                <AccordionItemHeading className={classNames(styles.accordion__heading, className)}>
                    <AccordionItemButton className={classNames(styles.accordion__button, className)} >
Article 13 : Convention de preuve
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={classNames(styles.accordion__panel, className)} >
                    <p className={classNames(styles.texte, className)} >
De convention expresse entre le participant et « L'organisatrice », les systèmes et fichiers informatiques de « L'organisatrice » feront seuls foi.

Les registres informatisés, conservés dans les systèmes informatiques de « L'organisatrice », dans des conditions raisonnables de sécurité et de fiabilité, sont considérés comme les preuves des relations et communications intervenues entre « L'organisatrice » et le participant.

Il est en conséquence convenu que, sauf erreur manifeste, « L'organisatrice » pourra se prévaloir, notamment aux fins de preuve de tout acte, fait ou omission, des programmes, données, fichiers, enregistrements, opérations et autres éléments (tels que des rapports de suivi ou autres états) de nature ou sous format ou support informatiques ou électroniques, établis, reçus ou conservés directement ou indirectement par « L'organisatrice », notamment dans ses systèmes informatiques.

Les éléments considérés constituent ainsi des preuves et s'ils sont produits comme moyens de preuve par « L'organisatrice » dans toute procédure contentieuse ou autre, ils seront recevables, valables et opposables entre les parties de la même manière, dans les mêmes conditions et avec la même force probante que tout document qui serait établi, reçu ou conservé par écrit.

Les opérations de toute nature réalisées à l'aide de l'identifiant et du code attribués à un participant, à la suite de l'inscription, sont présumées de manière irréfragable, avoir été réalisées sous la responsabilité du participant.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>


        </Accordion>
		
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>
            
  );
};

export default ChallengeRules;
