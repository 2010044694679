import   React                               from 'react';
import   ReactDOM                            from 'react-dom/client';
import { createBrowserRouter              ,
         RouterProvider                   }  from 'react-router-dom';
import { Provider                         }  from 'react-redux';


import { Home                             }  from './components/page_home/home';
import { Home2                            }  from './components/page_home2/home2';
import { Home3                            }  from './components/page_home3/home3';
import { Home4                            }  from './components/page_home4/home4';
import { Home5                            }  from './components/page_home5/home5';
import { Home6                            }  from './components/page_home6/home6';
import { Home7                            }  from './components/page_home7/home7';
import { Home8                            }  from './components/page_home8/home8';
import { Home9                            }  from './components/page_home9/home9';
import { Home10                           }  from './components/page_home10/home10';
/*
import { Jury                             }  from './components/page_jury/jury';
import { Trial                            }  from './components/page_trial/trial';
import { Prices                           }  from './components/page_prices/prices';
*/
import { Login                            }  from './components/page_login/login';
import { Registration                     }  from './components/page_registration/registration';
import { Account                          }  from './components/page_account/account';
import { Account2                         }  from './components/page_account2/account2';

import { ChallengeRules                   }  from './components/page_challenge_rules/challenge_rules';
import { LegalNotice                      }  from './components/page_legal_notice/legal_notice';
import { TermsOfService                   }  from './components/page_terms_of_service/terms_of_service';
import { CookiesInformationLeaflet        }  from './components/page_cookies_information_leaflet/cookies_information_leaflet';
import { PersonnalDatasInformationLeaflet }  from './components/page_personnal_datas_information_leaflet/personnal_datas_information_leaflet';
import { Faq                              }  from './components/page_faq/faq';

import { Vote                             }  from './components/page_vote/vote';

import { PasswordReset                    }  from './components/page_password_reset/page_password_reset';

import { NotMatch                         }  from './components/page_not_match/not_match';

import { store                            }  from './redux/store'; 

import './index.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  
  /*
  
    {
    path: "/le-jury",
    element: <Jury />,
  },
  {
    path: "/les-epreuves",
    element: <Trial />,
  },
  {
    path: "/les-prix",
    element: <Prices />,
  },
  
  */
  
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/inscription",
    element: <Registration />,
  },
  {
    path: "/compte",
    element: <Account />,
  },


  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  {
    path: "/reglement-du-lovelight-challenge",
    element: <ChallengeRules />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },

  {
    path: "/password-reset",
    element: <PasswordReset />,
  },
  
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);


const router2 = createBrowserRouter([
  {
    path: "/",
    element: <Home2 />,
  },
  
  

  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);


const router3 = createBrowserRouter([
  {
    path: "/",
    element: <Home3 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/password-reset",
    element: <PasswordReset />,
  },
  {
    path: "/vote",
    element: <Vote />,
  },

  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);

const router4 = createBrowserRouter([
  {
    path: "/",
    element: <Home4 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);


const router5 = createBrowserRouter([
  {
    path: "/",
    element: <Home5 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);


const router6 = createBrowserRouter([
  {
    path: "/",
    element: <Home6 />,
  },
  {
    path: "/archives-2024",
    element: <Home5 />,
  },
  {
    path: "/compte",
    element: <Account />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/inscription",
    element: <Registration />,
  },
  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  {
    path: "/reglement-du-lovelight-challenge",
    element: <ChallengeRules />,
  },
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);

const router7 = createBrowserRouter([
  {
    path: "/",
    element: <Home7 />,
  },
  {
    path: "/archives-2024",
    element: <Home5 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/inscription",
    element: <Registration />,
  },
  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },
  {
    path: "/reglement-du-lovelight-challenge",
    element: <ChallengeRules />,
  },
  {
    path: "*",
    element: <NotMatch />,
  },
  

]);


const router8 = createBrowserRouter([
  {
    path: "/",
    element: <Home8 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/archives-2024",
    element: <Home5 />,
  },
  {
    path: "/password-reset",
    element: <PasswordReset />,
  },
  {
    path: "/vote",
    element: <Vote />,
  },

  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },

  {
    path: "*",
    element: <NotMatch />,
  },


]);

const router9 = createBrowserRouter([
  {
    path: "/",
    element: <Home9 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/archives-2024",
    element: <Home5 />,
  },
  {
    path: "/password-reset",
    element: <PasswordReset />,
  },

  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },

  {
    path: "*",
    element: <NotMatch />,
  },


]);

const router10 = createBrowserRouter([
  {
    path: "/",
    element: <Home10 />,
  },
  {
    path: "/compte",
    element: <Account2 />,
  },
  {
    path: "/connexion",
    element: <Login />,
  },
  {
    path: "/archives-2024",
    element: <Home5 />,
  },
  {
    path: "/password-reset",
    element: <PasswordReset />,
  },

  {
    path: "/mentions-legales",
    element: <LegalNotice />,
  },
  {
    path: "/conditions-generales-d-utilisation",
    element: <TermsOfService />,
  },
  {
    path: "/notice-d-information-sur-les-cookies",
    element: <CookiesInformationLeaflet />,
  },
  {
    path: "/notice-d-information-sur-les-donnees-personnelles",
    element: <PersonnalDatasInformationLeaflet />,
  },

  {
    path: "*",
    element: <NotMatch />,
  },


]);



const root = ReactDOM.createRoot ( document.getElementById('root') as HTMLElement );

root.render (

 <React.StrictMode>
   <Provider store={store}>
     <RouterProvider router={router10} />
   </Provider>
 </React.StrictMode>
 
);
