import   React             from 'react';
import { Link }            from 'react-router-dom';
import   classNames        from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition }  from '@fortawesome/fontawesome-common-types'; // Importez le type d'icône

import   styles            from './trigger.module.scss';

export interface TriggerProps {
  className?: string; 
  name:       string; 
  link:       string; 
  icon?:      IconDefinition | undefined;
  touch?:     string;
  disabled?:  boolean | undefined; // disponible ou non
  onClick?:  (() => void) | undefined;     
}

export const Trigger = ({ className, name, icon, link, touch, disabled, onClick }: TriggerProps) => {


  const handleKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if ( typeof (touch) != "undefined" ) {
      if (event.key === touch) {
	    if ( typeof (onClick) != "undefined" ) {
          onClick();
	    }
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

	  if ( typeof (onClick) != "undefined" )
	   {
        onClick();
	   }
  };

  if ( link === "#" )
        {
		 if ( typeof (icon) === 'undefined')
		       {
		        return (
                         <button className  = {classNames(className, styles.trigger)}
                                 disabled   = {disabled}
                                 onClick    = {handleClick}  
                                 onKeyPress = {handleKeyPress}								 
								 >{name}</button>
                       );
		       }
          else {
		        const nomIcone: IconDefinition = icon!;
		  
                return (
                         <button className  = {classNames(className, styles.trigger, styles.trigger_icone)}
                                 disabled   = {disabled}
                                 onClick    = {handleClick}  
                                 onKeyPress = {handleKeyPress}								 
								 ><FontAwesomeIcon icon={nomIcone}/></button>
                       );
				 
		       }
		}
   else {
         return (
                  <Link to={link}>
                    <button className  ={classNames(className, styles.trigger)}
                            disabled   ={disabled}
                            onClick    = {handleClick}  
                            onKeyPress = {handleKeyPress}								 
							>{name}</button>
                  </Link>
                );
		}
};

export default Trigger;
