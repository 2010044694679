import   React                , 
       { useEffect            ,
         useState             }  from 'react';

import { StickyMenu           }  from '../sticky_menu/sticky_menu';
import { StickyMenu2          }  from '../sticky_menu2/sticky_menu2';
import { StickyMenu3          }  from '../sticky_menu3/sticky_menu3';
import { StickyMenu4          }  from '../sticky_menu4/sticky_menu4';
import { StickyFooter         }  from '../sticky_footer/sticky_footer';
import { BurgerMenu           }  from '../sticky_burger_menu/sticky_burger_menu';
import { BurgerMenu2          }  from '../sticky_burger_menu2/sticky_burger_menu2';
import { BurgerMenu3          }  from '../sticky_burger_menu3/sticky_burger_menu3';
import { BurgerMenu4          }  from '../sticky_burger_menu4/sticky_burger_menu4';

import './menu_deroulant.css';

export interface MenusProps {
  className?: string;
  page?:      string;  
}

export const Menus = ({ className , page }: MenusProps) => {

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
     setIsSmallScreen(window.innerWidth <= 460);
    };

    // Ajoute un écouteur d'événements pour détecter les changements de taille d'écran
    window.addEventListener('resize', handleResize);

    // Appelle handleResize une fois au montage du composant pour définir l'état initial
    handleResize();

    // Nettoie l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 
  
  /*
  
  return (
   
                      <>
            
            
                  { 
                    ( 
                      isSmallScreen 
                    ) 
                    ?
                    ( 
                      <>
                        <BurgerMenu />
                        <StickyFooter />
                      </>
                      
                    )  
                    :
                    ( 
                      <>
                        <StickyMenu />
                        <StickyFooter />
                      </>
                    ) 
                  }
            
                      </>
            
          
         );
		 
*/		 

  return (
   
                      <>
            
            
                  { 
                    ( 
                      isSmallScreen 
                    ) 
                    ?
                    ( 
                      <>
                        <BurgerMenu4 />
                        <StickyFooter />
                      </>
                      
                    )  
                    :
                    ( 
                      <>
                        <StickyMenu4 />
                        <StickyFooter />
                      </>
                    ) 
                  }
            
                      </>
            
          
         );
		 
};

export default Menus;
