import React      from 'react';
import classNames from 'classnames';

import styles     from './textarea.module.scss';

export interface TextAreaProps {
  className?:    string;                                                   // classe CSS complémentaire
  classNameT?:    string;                                                   // classe CSS complémentaire
  name:          string;                                                   // Type du champ (text, password...)
  placeholder?:  string;                                                   // Le placeholder de l'input
  value?:        string;                                                   // La valeur de l'input
  error?:        string;                                                   // Le message d'erreur
  autocomplete?: string;
  readOnly?:     boolean | undefined;                                      // éditable ou non
  onChange?:     (event: React.ChangeEvent<HTMLTextAreaElement>) => void;  // Ajout d'une prop pour gérer le changement du textarea
}

export const TextArea = ({ className    , 
                           classNameT    , 
                           name         , 
						   placeholder  , 
						   value        , 
						   error        , 
						   autocomplete ,
						   readOnly     ,
						   onChange     }: TextAreaProps) => {

  var completion = "new-password";

  if ( typeof (autocomplete) == 'string' )
   {
    if ( autocomplete === "on" )
	 {
	  completion = "on";
	 }
   }
   
  return (
  
           <div className={classNames(styles.textarea, className)}>
 
                         { 
                  ( name === "" )
                   ?
				   ( <></> )
				   :
				   (
				    <div className={classNames(styles.textarea_nom, className)} >{name}</div>
                   )
				   }
				   
   	         <textarea className    = { ( ( !readOnly ) ? classNames(styles.textarea_input, classNameT) : classNames(styles.textarea_input_read_only, classNameT) ) }
                       placeholder  = { placeholder                                  }
                       value        = { value                                        }
					   readOnly     = { readOnly                                     }
                       onChange     = { onChange                                     } 
					   autoComplete = { completion                                   } />
			 
		     <div className={classNames(styles.textarea_info, className)} >{error}</div>

	  
           </div>
		   
         );
};

export default TextArea;
