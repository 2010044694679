import   React           ,
       { useState        }  from "react";
import   classNames         from 'classnames';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { faAsterisk      ,
         faEye           ,
         faEyeSlash      }  from '@fortawesome/free-solid-svg-icons';

import   styles             from './field.module.scss';

export interface FieldProps {
  className?:    string;                                                   // classe CSS complémentaire
  name?:         string;                                                   // Nom du champ écrit au dessus de la zone de saisie
  type:          string;                                                   // Type du champ (text, password...)
  placeholder?:  string;                                                   // Le placeholder de l'input
  value?:        string;                                                   // La valeur de l'input
  error?:        string;                                                   // Le message d'erreur
  autocomplete?: string;
  required?:     string | undefined;                                      // éditable ou non
  readOnly?:     boolean | undefined;                                      // éditable ou non
  onChange?:     (event: React.ChangeEvent<HTMLInputElement>) => void;     // Ajout d'une prop pour gérer le changement de l'input
}

export const Field = ({ className    , 
                        name         , 
                        type         , 
                        placeholder  , 
                        value        , 
                        error        , 
                        autocomplete ,
                        readOnly     ,
                        required     ,
                        onChange     }: FieldProps) => {

  const [ eyeSlashed , setEyeSlashed  ] = useState (true);

  var completion = "new-password";

  if ( typeof (autocomplete) == 'string' )
   {
    if ( autocomplete === "on" )
     {
      completion = "on";
     }
   }
   
  const handleEyeClick = () => {

    setEyeSlashed ( !eyeSlashed );
  }

   /*
                <div className={classNames(styles.field_info, className)} >{error}</div>

   */
   
  return (
  
           <div className={classNames(styles.field, className)}>
 
            { 
                  ( name === "" )
                   ?
				   ( <></> )
				   :
				   (
             <div className={classNames(styles.field_nom, className)} >
              {name}

              { ( required === "true" ) 
                ?
                ( <i className={classNames(styles.required, className)}>&nbsp;
                  <FontAwesomeIcon icon={faAsterisk} className={classNames(styles.redIcon, className)}/></i> ) 
                :
                ( <></> ) }

             </div>
			      )
			}
       
             <div className = { classNames(styles.field_bloc_input, className) } >
             
                <input className    = { ( ( !readOnly ) ? classNames(styles.field_input, className) : classNames(styles.field_input_read_only, className) ) }
                       type         = { ( ( type !== 'password' ) ? type : ( eyeSlashed ? 'password' : 'text' ) ) }
                       placeholder  = { placeholder                                                              }
                       value        = { value                                                                    }
                       readOnly     = { readOnly                                                                 }
                       onChange     = { onChange                                                                 } 
                       autoComplete = { completion                                                               } />
             
                { 
                  ( type === 'password' ) 
                  ?
                  ( 
				    
                    ( eyeSlashed ) 
                    ?
                    ( <span className = { classNames(styles.span_eye, styles.slash, className) } onClick = {handleEyeClick}>&#x1F441;</span> ) 
                    :
                    ( <span className = { classNames(styles.span_eye, className) }               onClick = {handleEyeClick}>&#x1F441;</span> )                
                  ) 
                  :
                  ( <></> ) 
                }

             </div> 

		   
		     <div className={classNames(styles.field_info, className)} >{error}</div>
      
           </div>
           
         );
};

export default Field;
