import   React            ,  
       { useEffect        , 
         useState         ,  
         useCallback      , 
         forwardRef       }  from "react";
import { Link             ,
         useNavigate      }  from 'react-router-dom';
import   classNames          from 'classnames';
import { useCookies       }  from 'react-cookie';
import   Toggle              from "react-native-toggle-element";   //  https://github.com/mymai91/react-native-toggle-element
import { Dispatch         }  from 'redux';
import { useDispatch      }  from 'react-redux';

import { initAuthentication   ,
         setLogin             , 
         setToken             }  from '../../redux/authenticationManagment/actionCreators';
		 
import { initRegistration   }  from '../../redux/registrationManagment/actionCreators';
		 
import { useTypedSelector }  from '../../hooks/useTypeSelector';
import { store            }  from '../../redux/store';
import   styles              from './top_banner_account.module.scss';

import { Trigger          }  from '../widget_trigger/trigger';

export interface TopBannerAccountProps {
  className?:         string;                 // classe CSS complémentaire
  firstname:          string;                 // prénom
  lastname:           string;                 // nom
  email:              string;                 // e-mail
  pageState:          number;                 // état de la page "Profil"
  onPageStateChange:  (num: number) => void;  // gérer le click sur le bouton "Modifier profil"
}

export const TopBannerAccount = ({ className         , 
                                   firstname         , 
                                   lastname          , 
                                   email             , 
                                   pageState         , 
                                   onPageStateChange }: TopBannerAccountProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );

  const handleProfilEditionClick = () => {
    onPageStateChange (1);
  }
  
  const handlePasswordEditionClick = () => {
    onPageStateChange (2);
  }




  return (
  
    <div className={classNames(styles.root, className)}>
 
      <div className={classNames(styles.left, className)}>
             
        <p className={classNames(styles.firstname_lastname, className)}>
          {registration.firstname} {registration.lastname}
        </p>

        <p className={classNames(styles.email, className)}>
          {registration.email}
        </p>
               
      </div>
       
      <div className={classNames(styles.right, className)}>
             
		{ 
		  ( pageState === 0 || pageState === 2 ) &&

            <Trigger name        = "Editer le profil"
                     link        = "#"
                     onClick     = {handleProfilEditionClick}
                     className   = {classNames(styles.trigger, className)} />
		}
		
		{ 
		  ( pageState === 1 ) &&

            <Trigger name        = "Changer le mot de passe"
                     link        = "#"
                     onClick     = {handlePasswordEditionClick}
                     className   = {classNames(styles.trigger, className)} />
		}

            
             
      </div>
      
    </div>
           
  );
};

export default TopBannerAccount;
