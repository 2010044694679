import   React               from "react";
import { Link                 , 
         useNavigate          }  from 'react-router-dom';
import   classNames          from 'classnames';

import   styles              from './partners.module.scss';


export interface PartnersProps {
  className?:         string;                 // classe CSS complémentaire
}

export const Partners = ({ className  }: PartnersProps) => {

  return (
  
    <div className={classNames(styles.root, className)}>

      <div className={classNames(styles.contain, className)}>

        <p className={classNames(styles.titre, className)}>
            Les partenaires de Lovelight
        </p>

	  </div>

    <div className={classNames(styles.Sigma, styles.zoom,  className)}>
	 <Link to="https://www.sigma-photo.fr/" target="__blank">
	 <img src="/images/Partenaires/Sigma.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Sigma" />
	 </Link>
    </div>

    <div className={classNames(styles.Canon, styles.zoom,  className)}>
	 <Link to="https://www.camara.net/" target="__blank">
	   <img src="/images/Partenaires/Camara.png"
	        className={classNames(styles.logo_partenaire, className)}
		    alt="Camara" />
	 </Link>
    </div>

    <div className={classNames(styles.Fujifilm, styles.zoom,  className)}>
	 <Link to="https://www.fujifilm.com/fr/fr/consumer/digitalcameras" target="__blank">
	 <img src="/images/Partenaires/Fujifilm.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Fujifilm" />
	 </Link>
    </div>

    <div className={classNames(styles.Leica, styles.zoom,  className)}>
	 <Link to="https://leica-camera.com/fr-FR" target="__blank">
	 <img src="/images/Partenaires/Leica.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Leica" />
	 </Link>
    </div>

    <div className={classNames(styles.PhaseOne, styles.zoom,  className)}>
	 <Link to="https://www.phaseone.com/" target="__blank">
	 <img src="/images/Partenaires/PhaseOne.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="PhaseOne" />
	 </Link>
    </div>

    <div className={classNames(styles.Profoto, styles.zoom,  className)}>
	 <Link to="https://profoto.com/fr" target="__blank">
	 <img src="/images/Partenaires/Profoto.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Profoto" />
	 </Link>
    </div>

    <div className={classNames(styles.Canon, styles.zoom,  className)}>
	 <Link to="https://www.canon.fr/" target="__blank">
	   <img src="/images/Partenaires/Canon.png"
	        className={classNames(styles.logo_partenaire, className)}
		    alt="Canon" />
	 </Link>
    </div>

    <div className={classNames(styles.Ilford, styles.zoom,  className)}>
	 <Link to="https://www.ilfordphoto.com/" target="__blank">
	 <img src="/images/Partenaires/Ilford.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Ilford" />
	 </Link>
    </div>

    <div className={classNames(styles.Leofoto, styles.zoom,  className)}>
	 <Link to="https://www.leofoto.eu/fr/" target="__blank">
	 <img src="/images/Partenaires/Leofoto.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Leofoto" />
	 </Link>
    </div>

    <div className={classNames(styles.ThinkTank, styles.zoom, className)}>
	 <Link to="https://www.thinktankphoto.com/" target="__blank">
	 <img src="/images/Partenaires/Think-Tank.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Think-Tank" />
	 </Link>
    </div>

    <div className={classNames(styles.Laowa, styles.zoom,  className)}>
	 <Link to="https://laowa.fr/" target="__blank">
	 <img src="/images/Partenaires/Laowa.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Laowa" />
	 </Link>
    </div>

    <div className={classNames(styles.DxO, styles.zoom,  className)}>
	 <Link to="https://www.dxo.com/fr/" target="__blank">
	 <img src="/images/Partenaires/DxO.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="DxO" />
	 </Link>
    </div>

    <div className={classNames(styles.Zhiyun, styles.zoom,  className)}>
	 <Link to="https://www.zhiyun-tech.com/fr" target="__blank">
	 <img src="/images/Partenaires/Zhiyun.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Zhiyun" />
	 </Link>
    </div>
	
    <div className={classNames(styles.Sony, styles.zoom,  className)}>
	 <Link to="https://www.sony.fr/store/digital-imaging" target="__blank">
	 <img src="/images/Partenaires/Sony.webp"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Sony" />
	 </Link>
    </div>
    
    <div className={classNames(styles.Nikon, styles.zoom,  className)}>
	 <Link to="https://www.nikon.fr/fr_FR" target="__blank">
	 <img src="/images/Partenaires/Nikon.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Nikon" />
	 </Link>
    </div>
    
    <div className={classNames(styles.Lumix, styles.zoom,  className)}>
	 <Link to="https://www.panasonic.com/fr/consumer/appareils-photo-et-camescopes-savoir/lumix-g/tout-l-univers-photo-video-lumix.html" target="__blank">
	 <img src="/images/Partenaires/Lumix.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Lumix" />
	 </Link>
    </div>
	
	



    {/*
    <div className={classNames(styles.NationPhoto, styles.zoom,  className)}>
	 <Link to="https://www.nationphoto.com/" target="__blank">
	 <img src="/images/Partenaires/Nation-Photo.png"
	      className={classNames(styles.logo_partenaire, className)}
		  alt="Nation-Photo" />
	 </Link>
    </div>
    */}



    </div>
           
  );
};

