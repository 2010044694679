import   React                ,  
       { useEffect            ,   
         useState             }  from "react";
import { Link                 , 
         useNavigate          }  from 'react-router-dom';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                , 
       { AxiosError           }  from 'axios';
import { differenceInYears    }  from 'date-fns';
import { FontAwesomeIcon      }  from '@fortawesome/react-fontawesome';
import { faAsterisk           }  from '@fortawesome/free-solid-svg-icons';
import   styles                  from './registration.module.scss';

import { initRegistration     ,
         updateEmail          , 
         updatePassword       , 
         updatePassword2      , 
         updateFirstname      , 
         updateLastname       , 
         updateSex            , 
         updateBirthday       , 
         updateAddress        , 
         updateAddress2       , 
         updateZipCode        ,
         updateCity           , 
         updateCountry        ,
         updatePhoneNumber    ,
         updateWebsite        , 
         updateInstagram      , 
         updateFacebook       , 
         updateTiktok         , 
         updateBio            , 
         verifyRegistration   }  from '../../redux/registrationManagment/actionCreators';

import { setLogin             , 
         setToken             }  from '../../redux/authenticationManagment/actionCreators';

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';
                                          
import { RegistrationTitle    }  from '../bloc_registration_title/registration_title';
import { Field                }  from '../widget_field/field';
import { Select               }  from '../widget_select/select';
import { TextArea             }  from '../widget_textarea/textarea';
import { Trigger              }  from '../widget_trigger/trigger';
import { Toggle               }  from '../widget_toggle/toggle';
import { Menus                }  from '../bloc_menus/menus';
import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { WindowMessage        }  from '../window_message/window_message';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

export interface RegistrationProps {
  className?: string;
}

export const Registration = ({ className }: RegistrationProps) => {

  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment );

  const [ email                 , setEmail              ] = useState('');
  const [ password              , setPassword           ] = useState('');
  const [ password2             , setPassword2          ] = useState('');
  const [ firstname             , setFirstname          ] = useState('');
  const [ lastname              , setLastname           ] = useState('');
  const [ sex                   , setSex                ] = useState('Aucune réponse');
  const [ birthday              , setBirthday           ] = useState('');
  const [ address               , setAddress            ] = useState('');
  const [ address2              , setAddress2           ] = useState('');
  const [ zipCode               , setZipCode            ] = useState('');
  const [ city                  , setCity               ] = useState('');
  const [ country               , setCountry            ] = useState('France');
  const [ phoneNumber           , setPhoneNumber        ] = useState('');
  const [ website               , setWebsite            ] = useState('');
  const [ instagram             , setInstagram          ] = useState('');
  const [ facebook              , setFacebook           ] = useState('');
  const [ tiktok                , setTiktok             ] = useState('');
  const [ bio                   , setBio                ] = useState('');
  const [ verify                , setVerify             ] = useState(true);
  
  const [ messageEmail          , setMessageEmail       ] = useState('');
  const [ messagePassword       , setMessagePassword    ] = useState('');
  const [ messagePassword2      , setMessagePassword2   ] = useState('');
  const [ messageFirstname      , setMessageFirstname   ] = useState('');
  const [ messageLastname       , setMessageLastname    ] = useState('');
  const [ messageSex            , setMessageSex         ] = useState('');
  const [ messageBirthday       , setMessageBirthday    ] = useState('');
  const [ messageAddress        , setMessageAddress     ] = useState('');
  const [ messageAddress2       , setMessageAddress2    ] = useState('');
  const [ messageZipCode        , setMessageZipCode     ] = useState('');
  const [ messageCity           , setMessageCity        ] = useState('');
  const [ messageCountry        , setMessageCountry     ] = useState('');
  const [ messagePhoneNumber    , setMessagePhoneNumber ] = useState('');
  const [ messageWebsite        , setMessageWebsite     ] = useState('');
  const [ messageInstagram      , setMessageInstagram   ] = useState('');
  const [ messageFacebook       , setMessageFacebook    ] = useState('');
  const [ messageTiktok         , setMessageTiktok      ] = useState('');
  const [ messageBio            , setMessageBio         ] = useState('');
    
  const [ rgpdConsent           , setRgpdConsent        ] = useState(false);
  const [ messageRgpdConsent    , setMessageRgpdConsent ] = useState('');

  const [ cookies               , setCookie             ] = useCookies(['CookiesConsent']);
  const [ showCookiesParameters , setCookiesParameters  ] = useState(false);

  const [ showMessageError      , setShowMessageError   ] = useState(0);
  const [ showMessageAck        , setShowMessageAck     ] = useState(0);

  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {
     
    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      init ();

      setFirstRender (false);
     }
     
     
  }); 

  const init = () => {
  
    setEmail       ('');
    setPassword    ('');
    setPassword2   ('');
    setFirstname   ('');
    setLastname    ('');
    setSex         ('Aucune réponse');
    setBirthday    ('');
    setAddress     ('');
    setAddress2    ('');
    setZipCode     ('');
    setCity        ('');
    setCountry     ('France');
    setPhoneNumber ('');
    setWebsite     ('');
    setInstagram   ('');
    setFacebook    ('');
    setTiktok      ('');
    setBio         ('');
    setVerify      (true);
    
    initRegistration ();
   }

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setEmail (e.target.value) 
    dispatch ( updateEmail (e.target.value) );
        
    if ( registration.email == "" )
          {
           setMessageEmail ("Il manque l'adresse e-mail");
          }
     else {
           const check = await checkValue ( "email" , registration.email );
      
           if ( check ) 
                 {
                  setMessageEmail ("Un compte existe déjà avec cet e-mail.");
                 }
            else {
                  setMessageEmail ("");
                 }
          }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword (e.target.value) 
    dispatch ( updatePassword (e.target.value) );

    if ( registration.password == "" )
          {
           setMessagePassword ("Il manque le mot de passe");
          }
     else {
           if ( registration.password.length > 0 && registration.password.length < 8) 
                 {
                  setMessagePassword ("Le mot de passe fait moins de 8 caractères.");
                 }
            else {
                  setMessagePassword ("");
                 }
          }        
          
    if ( registration.password2 != registration.password ) 
          {
           setMessagePassword2 ("Les deux mots de passe sont différents");
          }
     else {
           setMessagePassword2 ("");
          }
  };

  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setPassword2 (e.target.value) 
    dispatch ( updatePassword2 (e.target.value) );

    if ( registration.password2 != registration.password ) 
          {
           setMessagePassword2 ("Les deux mots de passe sont différents");
          }
     else {
           setMessagePassword2 ("");
          }
  };

  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname (e.target.value) 
    dispatch ( updateFirstname (e.target.value) );
    
    if ( registration.firstname == "" )
          {
           setMessageFirstname ("Il manque le prénom");
          }
     else {
           setMessageFirstname ("");
          }
 };

  const handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname (e.target.value) 
    dispatch ( updateLastname (e.target.value) );
    
    if ( registration.lastname == "" )
          {
           setMessageLastname ("Il manque le nom");
          }
     else {
           setMessageLastname ("");
          }
  };

  const handleSexChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSex (e.target.value) 
    dispatch ( updateSex (e.target.value) );
    
    if ( registration.sex == "" )
          {
           setMessageSex ("Il manque le sexe");
          }
     else {
           setMessageSex ("");
          }
  };

  const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setBirthday (e.target.value) 
    dispatch ( updateBirthday (e.target.value) );
    
    console.log ("birthday ",e.target.value);
    
    if ( registration.birthday == "" )
          {
           setMessageBirthday ("Il manque la date de naissance");
          }
     else {
           const birthdayDate            = new Date(registration.birthday);
           const currentDate             = new Date();
           const differenceInYearsResult = differenceInYears(currentDate, birthdayDate);

           console.log ("differenceInYearsResult ",differenceInYearsResult);

           if ( differenceInYearsResult < 18 )
                 {
                  setMessageBirthday ("Il faut être âgé d'au moins 18 ans");
				 }
			else {
                  setMessageBirthday ("");
                 }			
          }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress (e.target.value) 
    dispatch ( updateAddress (e.target.value) );

    if ( registration.address == "" )
          {
           setMessageAddress ("Il manque l'adresse");
          }
     else {
           setMessageAddress ("");
          }

  };

  const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress2 (e.target.value) 
    dispatch ( updateAddress2 (e.target.value) );
     setMessageAddress2 ("");
  };
    
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setZipCode (e.target.value) 
    dispatch ( updateZipCode (e.target.value) );
    
    if ( registration.zipCode == "" )
          {
           setMessageZipCode ("Il manque le code postal");
          }
     else {
           setMessageZipCode ("");
          }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity (e.target.value) 
    dispatch ( updateCity (e.target.value) );
    
    if ( registration.city == "" )
          {
           setMessageCity ("Il manque la ville");
          }
     else {
           setMessageCity ("");
          }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setCountry (e.target.value)
    dispatch ( updateCountry (e.target.value) );

    if ( registration.country == "" )
          {
           setMessageCountry ("Il manque le pays");
          }
     else {
           setMessageCountry ("");
          }
  };

  const handlePhoneNumberChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setPhoneNumber (e.target.value) 
    dispatch ( updatePhoneNumber (e.target.value) );
      
    if ( registration.phoneNumber == "" )
          {
           setMessagePhoneNumber ("Il manque le numéro de téléphone");
          }
     else {
           const check = await checkValue ( "phoneNumber" , registration.phoneNumber );
      
           if ( check ) 
                 {
                  setMessagePhoneNumber ("Un compte existe déjà avec ce numéro de téléphone.");
                 }
            else {
                  setMessagePhoneNumber ("");
                 }
          }
  };

  const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setWebsite (e.target.value) 
    dispatch ( updateWebsite (e.target.value) );
     setMessageWebsite ("");
  };

  const handleInstagramChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setInstagram (e.target.value) 
    dispatch ( updateInstagram (e.target.value) );
     setMessageInstagram ("");
  };

  const handleFacebookChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFacebook (e.target.value) 
    dispatch ( updateFacebook (e.target.value) );
     setMessageFacebook ("");
  };

  const handleTiktokChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setTiktok (e.target.value) 
    dispatch ( updateTiktok (e.target.value) );
     setMessageTiktok ("");
  };

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setBio (e.target.value) 
    dispatch ( updateBio (e.target.value) );
     setMessageBio ("");
  };

  const handleRgpdConsentChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRgpdConsent(e.target.checked);
	
	if (e.target.checked)
	      {
	       setMessageRgpdConsent ("");
		  }
	 else {
	       setMessageRgpdConsent ("Validation obligatoire");
	      }
  };

  const checkValue = async (info1: string, info2: string): Promise<boolean> => {

    const baseUrl  =  `${API_URLS.GetExistenceCheck}?`;  // "https://api.lovelightfactory.com/api/existence_check?";

    const nomInfo1 = "champ="; 
    const nomInfo2 = "valeur=";
    const valInfo1 = encodeURIComponent (info1);      
    const valInfo2 = encodeURIComponent (info2);      

    const url = `${baseUrl}${nomInfo1}${valInfo1}&${nomInfo2}${valInfo2}`;

    console.log ( "checkValue => url=" , url );

    var resultat = false;

    try {

      const response = await axios.get(url);
      const statut   = response.status;
      const exist    = response.data.exist;

      if ( statut == 200 && exist == true )
       {
        resultat = true;
       }
    } 
    catch (error) {

      setShowMessageAck   ( 0 );  
      setShowMessageError ( 1 );
    }

    return resultat;
  };
     
  const checkInputs = async () => {

    var test = true;

    if ( registration.email == '' )
          {
           test = false;
           setMessageEmail ("Il manque l'adresse e-mail")
          }
     else {
           const checkEmail = await checkValue ( "email" , registration.email );

           if ( checkEmail )
            {
               test = false;
             setMessageEmail ("Il existe déjà un compte avec cette adresse e-mail")
            }
          }
          
    if ( registration.password == '' )
          {
           test = false;
           setMessagePassword ("Il manque le mot de passe")
          }
     else {
           if ( registration.password.length < 8 )
            {
             test = false;
             setMessagePassword ("Le mot de passe fait moins de 8 caractères")
            }
          }
          
    if ( registration.password != registration.password2 )
     {
      test = false;
      setMessagePassword2 ("Les 2 mots de passe sont différents")
     }

    if ( registration.firstname == '' )
     {
      test = false;
      setMessageFirstname ("Il manque le prénom")
     }

    if ( registration.lastname == '' )
     {
      test = false;
      setMessageLastname ("Il manque le nom")
     }

    if ( registration.sex == '' )
     {
      test = false;
      setMessageSex ("Il manque le sexe")
     }

    if ( registration.birthday == '' )
          {
           test = false;
           setMessageBirthday ("Il manque la date de naissance")
          }	  
     else {
           const birthdayDate            = new Date(birthday);
           const currentDate             = new Date();
           const differenceInYearsResult = differenceInYears(currentDate, birthdayDate);

           if ( differenceInYearsResult < 18 )
            {
             test = false;
             setMessageBirthday ("Il faut être âgé d'au moins 18 ans");
			}
          }

    if ( registration.address == '' )
     {
      test = false;
      setMessageAddress ("Il manque l'adresse")
     }

    if ( registration.zipCode == '' )
     {
      test = false;
      setMessageZipCode ("Il manque le code postal")
     }

    if ( registration.city == '' )
     {
      test = false;
      setMessageCity ("Il manque la ville")
     }

    if ( registration.country == '' )
     {
      test = false;
      setMessageCountry ("Il manque le pays")
     }

    if ( registration.phoneNumber == '' )
          {
           test = false;
           setMessagePhoneNumber ("Il manque le numéro de téléphone")
          }
     else {
           const checkPhoneNumber = await checkValue ( "phoneNumber" , registration.phoneNumber );

           if ( checkPhoneNumber )
            {
             test = false;
             setMessagePhoneNumber ("Il existe déjà un compte avec ce numéro de téléphone")
            }
          }
          
    if ( !rgpdConsent )
     {
      test = false;
      setMessageRgpdConsent ("Validation obligatoire");
     }

    verifyRegistration (test);
    setVerify          (test);
    
    return test;
  }

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };


  const handleOkClick = () => {
  
   navigate ("/connexion");
  }

  const handleRegistrationClick = async () => {
 
    const test = await checkInputs ();

    if ( test )
     {
      const email       = registration.email
      const password    = registration.password
      const firstname   = registration.firstname
      const lastname    = registration.lastname
      const sex         = registration.sex
      const birthday    = registration.birthday
      const address     = registration.address
      const address2    = registration.address2
      const zipCode     = registration.zipCode
      const city        = registration.city
      const country     = registration.country
      const phoneNumber = registration.phoneNumber
      const website     = registration.website
      const instagram   = registration.instagram
      const facebook    = registration.facebook
      const tiktok      = registration.tiktok
      const bio         = registration.bio

/*
      console.log ( "handleRegistrationClick : " );
      console.log ( " - email       : " , email       );
      console.log ( " - password    : " , password    );
      console.log ( " - firstname   : " , firstname   );
      console.log ( " - lastname    : " , lastname    );
      console.log ( " - sex         : " , sex         );
      console.log ( " - birthday    : " , birthday    );
      console.log ( " - address     : " , address     );
      console.log ( " - address2    : " , address2    );
      console.log ( " - zipCode     : " , zipCode     );
      console.log ( " - city        : " , city        );
      console.log ( " - country     : " , country     );
      console.log ( " - phoneNumber : " , phoneNumber );
      console.log ( " - website     : " , website     );
      console.log ( " - instagram   : " , instagram   );
      console.log ( " - facebook    : " , facebook    );
      console.log ( " - tiktok      : " , tiktok      );
      console.log ( " - bio         : " , bio         );
*/
      const headers = {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/ld+json'
      };
 
      try {

        const response = await axios.post (
        
                                            // 'https://webhook.site/292cb8db-a372-4805-8bf6-93e0768063ab',
                             
                                            //   'https://api.lovelightfactory.com/api/users',

                                               `${API_URLS.PostUser}`,
                              
                                               { 
                                                 email       ,
                                                 password    ,
                                                 firstname   ,
                                                 lastname    ,
                                                 sex         ,
                                                 birthday    ,
                                                 address     ,
                                                 address2    ,
                                                 zipCode     ,
                                                 city        ,
                                                 country     ,
                                                 phoneNumber ,
                                                 website     ,
                                                 instagram   ,
                                                 facebook    ,
                                                 tiktok      ,
                                                 bio      
                                               },
                                            
                                               {
                                                 headers: headers
                                               }
                                            );

        console.log (response);

        setShowMessageAck   ( 1 );  
        setShowMessageError ( 0 );
        
       // handleLogin();

      } 
      catch (error) {

        console.log (error);
      
        setShowMessageAck   ( 0 );  
        setShowMessageError ( 2 );
      }
    }
  };
     
  const handleLogin = async () => {

    const email    = registration.email
    const password = registration.password

    try {

      const response = await axios.post (
                                           `${API_URLS.PostAuth}`,

                                   //        'https://api.lovelightfactory.com/auth',

                                          { email    ,
                                            password }
                                         );
                                          
      console.log (response);
                                         
      if ( response.status == 200 )
            {
             dispatch ( setLogin (email) );
             dispatch ( setToken (response.data.token) );

             logConnection ( email , "succes" );

             navigate("/compte");
            }
       else {
             logConnection ( email , "autre" );
                  
             console.log ("autre " , response.status);
            }
    } 
    catch (error) {
    
      console.log ("error");
    
      if (axios.isAxiosError(error) && error.response)
            {
             logConnection ( email ,  error.response.data.message );
            }
       else {
             logConnection ( email , "erreur de connexion" );
            }
    
      setShowMessageError ( 3 )

    }
  };

  const logConnection = async ( login:string , status:string ) => {
  
      const headers = {
      'Accept': 'application/ld+json',
      'Content-Type': 'application/ld+json'
    };
  
        try 
         {
          const response = await axios.post (
  //                                             'https://api.lovelightfactory.com/api/connections',

                                               `${API_URLS.PostConnection}` ,

                                              { login  ,
                                                status },
                                            
                                             {
                                               headers: headers
                                             }
                                            );
         } 
        catch (error) {
           setShowMessageError ( 3 )

        }
  
  }
     
	 
  const handleCloseUploadOkMessageClick = () => {

   setShowMessageAck (2);

  }	 
 
  return (

        <>
          
          {
             showCookiesParameters &&
             ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
          }

          <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
			  
			  <p className={classNames(styles.texte_1, className)}>
			   S’inscrire pour participer au Lovelight Challenge
			  </p>

			  <p className={classNames(styles.texte_2, className)}>
                En vous inscrivant vous reconnaissez avoir lu, compris et accepté le règlement disponible au format PDF 
				<Link to="/docs/reglement-Lovelight-Challenge.pdf" 
				      target="__blank"  
					  className = { classNames(styles.link, className) } >ici</Link>
			  </p>

              <br/>

			  <p className={classNames(styles.texte_2, className)}>
                INFORMATIONS PERSONNELLES
			  </p>

			  <p className={classNames(styles.texte_2, className)}>
               Les informations personnelles sont obligatoires pour participer et vous permettront de vous identifier lors de votre prochaine visite.
			  </p>

			 <br/>

              <div className={classNames(styles.grid, className)}>
           
                <div className={classNames(styles.grid_email_nom,  styles.nom, className)}>
				  Adresse E-mail <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
				</div>
                <div className={classNames(styles.grid_email_data,  className)}>
                  <Field   name        = ""
                           type        = "email"
                           placeholder = ""
                           value       = {email}
                           error       = {messageEmail}
                           onChange    = {handleEmailChange}    />
				</div>
                    
                <div className={classNames(styles.grid_password_nom,  styles.nom,  className)}>
                  Mot de passe <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
				</div>
                <div className={classNames(styles.grid_password_data,  className)}>
                  <Field   name        = ""
                           type        = "password"
                           placeholder = ""
                           value       = {password}
                           error       = {messagePassword}
                           onChange    = {handlePasswordChange} />
                </div>
                    
                <div className={classNames(styles.grid_password2_nom,  styles.nom,  className)}>
                  Confirmation du mot de passe
                </div>
                <div className={classNames(styles.grid_password2_data,  className)}>
                  <Field   name        = ""
                           type        = "password"
                           placeholder = ""
                           value       = {password2}
                           error       = {messagePassword2}
                           onChange    = {handlePassword2Change} />
                </div>
                    
                <div className={classNames(styles.grid_firstname_nom,  styles.nom,  className)}>
				  Prénom <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_firstname_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {firstname}
                           error       = {messageFirstname}
                           onChange    = {handleFirstnameChange} />
                </div>
                    
                <div className={classNames(styles.grid_lastname_nom,  styles.nom,  className)}>
				  Nom <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_lastname_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {lastname}
                           error       = {messageLastname}
                           onChange    = {handleLastnameChange} />
			    </div>
                    
                <div className={classNames(styles.grid_sex_nom,  styles.nom,  className)}>
				  Sexe <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_sex_data,  className)}>
                  <Select  name        = ""
                           choices    = { [
                                               "Aucune réponse",
                                               "Homme",
                                               "Femme"
                                          ] }
                           value       = {sex}
                           error       = {messageSex}
                           onChange    = {handleSexChange}     />
                </div>
                    
                <div className={classNames(styles.grid_birthday_nom,  styles.nom,  className)}>
				  Date de naissance <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_birthday_data,  className)}>
                  <Field   name        = ""
                           type        = "date"
                           placeholder = ""
                           value       = {birthday}
                           error       = {messageBirthday}
                           onChange    = {handleBirthdayChange}  />
                </div>
                    
                <div className={classNames(styles.grid_address_nom,  styles.nom,  className)}>
				  Adresse <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_address_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {address}
                           error       = {messageAddress}
                           onChange    = {handleAddressChange}  />
                </div>
                    
                <div className={classNames(styles.grid_address2_nom,  styles.nom,  className)}>
				  Complément d'adresse
                </div>
                <div className={classNames(styles.grid_address2_data,  className)}>
                  <Field name        = ""
                         type        = "text"
                         placeholder = ""
                         value       = {address2}
                         onChange    = {handleAddress2Change}
						 
						 />
                </div>
                    
                <div className={classNames(styles.grid_zipCode_nom,  styles.nom,  className)}>
				  Code postal <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_zipCode_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {zipCode}
                           error       = {messageZipCode}
                           onChange    = {handleZipCodeChange}  />
                </div>
                    
                <div className={classNames(styles.grid_city_nom,  styles.nom,  className)}>
				  Ville <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_city_data,  className)}>
                   <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {city}
                           error       = {messageCity}
                           onChange    = {handleCityChange}   />
                </div>
                    
                <div className={classNames(styles.grid_country_nom,  styles.nom,  className)}>
				  Pays <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_country_data,  className)}>
                   <Select  name        = ""
                           choices    = { [
                                               "France",
                                               "Belgique",
                                               "Suisse"
                                          ] }
                           value       = {country}
                           error       = {messageCountry}
                           onChange    = {handleCountryChange}     />
                </div>
                    
                <div className={classNames(styles.grid_phoneNumber_nom,  styles.nom,  className)}>
				  Numéro de téléphone <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_phoneNumber_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {phoneNumber}
                           error       = {messagePhoneNumber}
                           onChange    = {handlePhoneNumberChange} />
                </div>
                    
                <div className={classNames(styles.grid_website_nom,  styles.nom,  className)}>
				  Site web
                </div>
                <div className={classNames(styles.grid_website_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {website}
                           error       = {messageWebsite}
                           onChange    = {handleWebsiteChange} />
                </div>

                <div className={classNames(styles.grid_instagram_nom,  styles.nom,  className)}>
				  Instagram
                </div>
                <div className={classNames(styles.grid_instagram_data,  className)}>
                   <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {instagram}
                           error       = {messageInstagram}
                           onChange    = {handleInstagramChange} />
                </div>
                    
                <div className={classNames(styles.grid_facebook_nom,  styles.nom,  className)}>
				  Facebook
                </div>
                <div className={classNames(styles.grid_facebook_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {facebook}
                           error       = {messageFacebook}
                           onChange    = {handleFacebookChange} />
                </div>
                    
                <div className={classNames(styles.grid_tiktok_nom,  styles.nom,  className)}>
				  Tiktok
                </div>
                <div className={classNames(styles.grid_tiktok_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {tiktok}
                           error       = {messageTiktok}
                           onChange    = {handleTiktokChange} />
                </div>

                <div className={classNames(styles.grid_bio_nom,  styles.nom,  className)}>
				  Qui êtes-vous ?
                </div>
                <div className={classNames(styles.grid_bio_data,  className)}>
                  <TextArea name        = ""
                            placeholder = ""
                            value       = {bio}
                            error       = {messageBio}
                            onChange    = {handleBioChange} />
                </div>
                    
                <div className={classNames(styles.grid_rgpd, className)}>

                   <div className = { classNames(styles.text_rgpd, className) } >                          
                      Je reconnais avoir lu, compris et accepté le &nbsp;
					  <Link to={WWW_URLS.Reglement} 
					        target="_blank" 
							className = { classNames(styles.link, className) } >règlement du concours</Link>.
                      En envoyant ce formulaire, j'accepte également les &nbsp;
					  <Link to={WWW_URLS.ConditionsGenerales} 
					        target="_blank" 
							className = { classNames(styles.link, className) } >Conditions d’utilisation du site Internet</Link>.
					  Nous traitons vos données personnelles conformément à notre &nbsp;
                      <Link to={WWW_URLS.Confidentialite} 
					        target="_blank" 
							className = { classNames(styles.link, className) } >Politique de confidentialité Lovelight Challenge</Link>.
					  Vous avez le droit de refuser certaines activités de traitement de vos données personnelles. 
					  Consultez la section « Vos droits » de la Politique de confidentialité pour en savoir plus. 
                   </div>
                      
                   <Toggle checked    = {rgpdConsent}
                           onChange   = {handleRgpdConsentChange} 
						   error      = {messageRgpdConsent}      />

                </div>

                <div className={classNames(styles.grid_button, className)}>

                { 
                  ( showMessageAck >= 1 ) 
                  ?				  
                  ( 
                    <Trigger name        = "Ok"
                             link        = "#"
                             onClick     = {handleOkClick}
			                 className   = {classNames(styles.trigger_registration, className)} />
				  )
                  :
                  (
                    <Trigger name        = "Etape suivante"
                             link        = "#"
                             onClick     = {handleRegistrationClick}
                             disabled    = {!rgpdConsent}
                             className   = {classNames(styles.trigger_registration, className)} />
				  )				  
                }


                </div>

              </div>
              
              <br/>
              
                { 
                  ( showMessageAck == 1 ) && 
                  ( 
				    

                    <WindowMessage onClickOnCloseButton = {handleCloseUploadOkMessageClick} 
		                 message = "Pour valider la création de votre compte, merci de cliquer sur le lien reçu par mail. Pensez à vérifier vos spams si vous ne trouvez pas l'email dans votre boite de réception." /> 					
				  ) 
                }

                { 
                  ( showMessageError == 1 || showMessageError == 2 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_error, className)}>
                      Le serveur a rencontré une erreur interne lors de votre inscription.
                     </p>
                    </div> )  
                }

                { 
                  ( showMessageError == 3 || showMessageError == 4 ) && 
                  ( <div>
                     <p className = {classNames(styles.message_error, className)}>
                      Le serveur a rencontré une erreur lors de la connexion à votre compte.
                     </p>
                    </div> )  
                }
                
               <div className={classNames(styles.espace_bas, className)}>
               </div>
        
            </div>
			
          </div>
        
        </>
          
         );
};

export default Registration;
