import   React                    , 
       { useEffect                ,
	     useRef                   }  from 'react';

import { useNavigate              }  from 'react-router-dom';

import { Dispatch                 }  from 'redux';
import { useDispatch              }  from 'react-redux';
	   
import   ReactPlayer                 from 'react-player/lazy'

import   classNames                  from 'classnames';
import   screenfull                  from 'screenfull';

import   styles                      from './video_viewer.module.scss';


import { Video                    }  from '../datas/video';




export interface VideoViewerProps {
  video:        Video;
  onClose:      ()=>void;
}

export const VideoViewer = ({ video   ,
                              onClose }: VideoViewerProps ) => {

  const navigate = useNavigate();

  const playerRef = useRef<ReactPlayer>(null);

  const WWW_Video:string = "https://media.lovelightfactory.fr/Videos/";

  var urlVideo: string = `${WWW_Video}${video.videoFile}/index.m3u8`;
   
  useEffect(() => {

    if (screenfull.isEnabled) 
	      {
	       console.log ("Mode fullscreen automatique autorisé");
		   
		   const element = document.querySelector('#viewer');

           if (element) 
		         {
                  screenfull.request(element).catch((err) => {
        
		            console.error('Error attempting to enable fullscreen mode:', err);
                  });
                 } 
		    else {
                  console.error('Element #viewer not found');
                 }

          }
	 else {
	       console.log ("Mode fullscreen automatique non autorisé");
	      }
  }, []);
  
  useEffect(() => {


  }, [] );
  
  const handleClick = () => {
   
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	
    
    onClose()
  }
  
  const handleStart = () => {

    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    
  }

  const handleEnded = () => {
  
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    
  }

  const handlePlay = () => {
  
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    
  }
  
  const handlePause = () => {
  
    let currentTime: number = 0;
    let duration:    number = 0;
  
    if (playerRef.current)  
	 {
      currentTime = playerRef.current.getCurrentTime ();
      duration    = playerRef.current.getDuration    ();
     }
	  
    
  }
  
  return (
 
    <div id="viewer" className = {classNames(styles.root)} >

      <div className = {classNames(styles.back_button)} >

        <button onClick={handleClick}>Fermer</button>

      </div>

      { 
	    (urlVideo !== null) 
		&&
        (
		 <ReactPlayer ref        = {playerRef}
                      url        = {urlVideo}
                      className  = {classNames(styles.player)}
                      controls   = {true}
                      playing    = {true}
                      width      = '100%'
                      height     = '100%' 
					  onStart    = {handleStart}
					  onEnded    = {handleEnded}
					  onPlay     = {handlePlay}
					  onPause    = {handlePause}  />
        )
      }
	  
	</div>
 
  );
  
};

export default VideoViewer;

