import   React          from "react";
import   classNames     from 'classnames';
import { Link        , 
         useNavigate }  from 'react-router-dom';
         
import   styles         from './presentation.module.scss';


export interface PresentationProps {
  className?:         string;                 // classe CSS complémentaire
}

export const Presentation = ({ className  }: PresentationProps) => {

/*
            <p className={classNames(styles.texte_2, className)}>
            Venez vous « Challenger » en participant à une suite d’épreuves
            photographiques et éliminatoires jusqu’à la victoire finale.
           </p>
		   
		   */

  return (

    <div className={classNames(styles.root, className)}>

      <div className={classNames(styles.espace_haut, className)}>
      </div>
      
      
      <div className={classNames(styles.contain, className)}>

        <div className={classNames(styles.grille, className)}>

          <div className={classNames(styles.ligne1, className)}>
		             <p className={classNames(styles.texte_1, className)}>
              Le Lovelight Challenge est une série de 8 épisodes qui seront diffusés sur
              YouTube et la chaine Lovelight, sous la forme d’un
              jeu concours photo entièrement gratuit.
            </p>
		<br/>
            <p className={classNames(styles.texte_1, className)}>
              L’ambition de ce jeu est de mettre en lumière de nouveaux talents de la
              photographie et la découverte des techniques de photographes
              professionnels.
            </p><br/>
            <p className={classNames(styles.texte_1, className)}>
              Grâce aux partenariats avec de nombreuses marques, les candidats
              auront l’opportunité d’utiliser du matériel professionnel que ce soit pour
              les appareils photo, les optiques, les flashs, la bagagerie et les accessoires.
            </p><br/>
		     <p className={classNames(styles.texte_1, className)}>
		      Le Lovelight Challenge est ouvert à toute personne majeure résidant en France métropolitaine, Suisse ou Belgique, 
			  peu importe le matériel utilisé.<br/>
             </p>
			 <p className={classNames(styles.texte_1, className)}>
              Pour participer, il faut s'inscrire sur ce site pendant la période d'appel à photos puis compléter son profil, 
			  les informations sur la photo et télécharger cette dernière avant la date de clôture des participations, qui prendra 
			  effet le 22 décembre 2023. 
             </p>   
			 <p className={classNames(styles.texte_1, className)}>
              Les modalités d’acceptation des images se trouvent dans le <Link to="/reglement-du-lovelight-challenge"  className = { classNames(styles.link, className) } >règlement du jeu</Link>. 
			  À signaler que l’utilisation de l’IA n’est pas acceptée.
	         </p>	  
		  
		     <br/>
		  
            <p className={classNames(styles.texte_2, className)}>
            Venez vous « Challenger » en participant à une suite d’épreuves
            photographiques et éliminatoires jusqu’à la victoire finale.
           </p>
		   <br/>
          </div>
		  
          <div className={classNames(styles.left, className)}>

          
 

            <br/>
            <p className={classNames(styles.texte_1b, className)}>
              De nombreux lots prestigieux offerts aux finalistes par les partenaires !
            </p>
            <br/>
            <p className={classNames(styles.texte_1, className)}>
              Les photos du lauréat paraitront dans un magazine photo et une
              émission consacrée à son travail sera diffusée sur la chaine Lovelight.
            </p>
            <p className={classNames(styles.texte_1, className)}>
              Pour participer aux présélections, il suffit de soumettre 1 à 3 de vos
              photos en vous inscrivant sur ce site.
            </p>
            <br/>
            <p className={classNames(styles.texte_1, className)}>
              Règlement et questions consulter notre <Link to="/Faq" className = { classNames(styles.link, className) } >FAQ</Link>
            </p>
          </div>

          <div className={classNames(styles.right, className)}>
          </div>

        </div>

      </div>

      <div className={classNames(styles.espace_bas, className)}>
      </div>

    </div>
           
  );
};

export default Presentation;
