import   React               from "react";
import   classNames          from 'classnames';

import   styles              from './keydates.module.scss';

import   {Carousel      }      from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export interface KeyDatesProps {
  className?:         string;                 // classe CSS complémentaire
}

export const KeyDates = ({ className  }: KeyDatesProps) => {

  return (

    <div className={classNames(styles.root, className)}>

      <div className={classNames(styles.espace_haut, className)}>
      </div>

      <p className={classNames(styles.titre, className)}>
            Les dates clés
      </p>

      <div className={classNames(styles.espace_haut_bis, className)}>
      </div>

      <div className={classNames(styles.contain, className)}>

         <Carousel showArrows    ={true} 
		           showIndicators={false}
				   showStatus    ={false} 
				   autoPlay      ={true}
				   infiniteLoop  ={true}>
                <div>
                    <p className={classNames(styles.date, className)}>
					 31 octobre 2024 à 22h00
					</p>
					<br/>
					<br/>
					<br/>
					<p className={classNames(styles.texte, className)}>
					 Date de clôture<br/>des candidatures
					</p>
					<br/>
					<br/>
					<br/>
                </div>
                <div>
                    <p className={classNames(styles.date, className)}>
					 7 novembre 2024 à 13h00
					</p>
					<br/>
					<br/>
					<br/>
					<p className={classNames(styles.texte, className)}>
					 Début des votes<br/>du public
				    </p>
					<br/>
					<br/>
					<br/>
                </div>
                <div>
                    <p className={classNames(styles.date, className)}>
					 2 décembre 2024 à 22h00
					</p>
					<br/>
					<br/>
					<br/>
					<p className={classNames(styles.texte, className)}>
					 Fin des votes
					</p>
					<br/>
					<br/>
					<br/>
                </div>
                <div>
                    <p className={classNames(styles.date, className)}>
					 9 décembre 2024 à 18h00
					</p>
					<br/>
					<br/>
					<br/>
					<p className={classNames(styles.texte, className)}>
					 Annonce des<br/>pré-sélectionnés
					</p>
					<br/>
					<br/>
					<br/>
                </div>
            </Carousel>
	  </div>

      <div className={classNames(styles.espace_bas, className)}>
      </div>

    </div>
           
  );
};

export default KeyDates;
