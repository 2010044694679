import   React               from "react";
import { Link               ,
         useNavigate        ,
         useLocation        }  from 'react-router-dom';
		 
import   classNames          from 'classnames';

import   ImageGallery            from "react-image-gallery";
import   "react-image-gallery/styles/css/image-gallery.css";

import   styles                  from './welcome7.module.scss';


import { BlocCountdown8        }  from '../bloc_countdown8/bloc_countdown8';

export interface Welcome7Props {
  className?:         string;                 // classe CSS complémentaire
}

export const Welcome7 = ({ className  }: Welcome7Props) => {

  const navigate = useNavigate();

  const images = [
  
  
    {
      original: "/images/Partenaires/Sigma.png",
      thumbnail: "/images/Partenaires/Sigma.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Camara.png",
      thumbnail: "/images/Partenaires/Camara.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	{
      original: "/images/Partenaires/Fujifilm.png",
	  thumbnail: "/images/Partenaires/Fujifilm.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Leica.png",
      thumbnail: "/images/Partenaires/Leica.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
    {
      original: "/images/Partenaires/PhaseOne.png",
      thumbnail: "/images/Partenaires/PhaseOne.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Profoto.png",
      thumbnail: "/images/Partenaires/Profoto.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	/*
    {
      original: "/images/Partenaires/Nation-Photo.png",
      thumbnail: "/images/Partenaires/Nation-Photo.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    */
  
    {
      original: "/images/Partenaires/Canon.png",
      thumbnail: "/images/Partenaires/Canon.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Ilford.png",
      thumbnail: "/images/Partenaires/Ilford.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Leofoto.png",
      thumbnail: "/images/Partenaires/Leofoto.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Think-Tank.png",
      thumbnail: "/images/Partenaires/Think-Tank.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	

	{
      original: "/images/Partenaires/Laowa.png",
	  thumbnail: "/images/Partenaires/Laowa.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },

	{
      original: "/images/Partenaires/DxO.png",
	  thumbnail: "/images/Partenaires/DxO.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },

	{
      original: "/images/Partenaires/Zhiyun.png",
	  thumbnail: "/images/Partenaires/Zhiyun.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
	{
      original: "/images/Partenaires/Sony.webp",
	  thumbnail: "/images/Partenaires/Sony.webp",
      originalClass: classNames(styles.logo_partenaire, className)
    },

    {
      original: "/images/Partenaires/Nikon.png",
	  thumbnail: "/images/Partenaires/Nikon.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Lumix.png",
      thumbnail: "/images/Partenaires/Lumix.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
	
	
  ];

  const handleClick = () => {
  
    navigate ("/vote");
  }

  return (

              <div className={classNames(styles.root, className)}>

                <div className={classNames(styles.contain, className)}>
    
                    <div className={classNames(styles.espace, className)}></div>

                  <BlocCountdown8 />

                    <div className={classNames(styles.espace, className)}></div>
                  
                </div>

                <div className={classNames(styles.blocDroite, className)}>
                  
                    <div className={classNames(styles.accroche, className)}>
                     L’émission qui révèle les nouveaux talents de la photographie !
                    </div>

                    <div className={classNames(styles.accroche2, className)}
					     onClick={handleClick}>
                     VENEZ VOTER POUR LES MEILLEURES PHOTOS<br/>
                     JUSQU'AU 2 DÉCEMBRE À 22H00<br/>
                    </div>
                  
                    <div className={classNames(styles.partenaires, className)}>

                      <ImageGallery items                = {images} 
                                    infinite             = {true}
                                    showNav              = {false}
                                    showThumbnails       = {false}
                                    showFullscreenButton = {false}
                                    showPlayButton       = {false}
                                    showBullets          = {false}
                                    showIndex            = {false}
                                    autoPlay             = {true}
                                    slideDuration        = {450}     
                                    additionalClass      = {classNames(styles.album, className)} />
                    </div>

                </div>

              </div>
           
  );
};


export default Welcome7;
