import   React                 from "react";
import   classNames            from 'classnames';
import { Link               ,
         useNavigate        ,
         useLocation        }  from 'react-router-dom';
import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';
import { HashLink           }  from 'react-router-hash-link';

import { stack as BGMenu    } from 'react-burger-menu'

import { useTypedSelector   }  from '../../hooks/useTypeSelector';


import { Menu               ,
         MenuItem           , 
		 MenuButton         }  from '@szhsin/react-menu';

import   styles                from './sticky_burger_menu4.module.scss';

import { initAuthentication }  from '../../redux/authenticationManagment/actionCreators';
import { initRegistration   }  from '../../redux/registrationManagment/actionCreators';


export interface BurgerMenu4Props {
  className?: string;
}

export const BurgerMenu4 = ({ className }: BurgerMenu4Props) => {

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );

  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch();
  const navigate                = useNavigate();

  const currentPathWithoutHash = location.pathname;

  const handleLogoutClick = () => {
    
	dispatch ( initRegistration () );
	dispatch ( initAuthentication () );
    navigate ("/");
  }


var stylesLocal = {
  bmBurgerButton: {
    position: 'fixed',
    width: '25px',
    height: '25px',
    right: '10px',
    top: '10px',
	background: '#000000',
	margin: '3px'
  },
  bmBurgerBars: {
    background: '#ffffff'
  },
  bmBurgerBarsHover: {
    background: '#000000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#000000',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}


  // https://github.com/negomi/react-burger-menu

  
  return (
  
       <BGMenu styles={ stylesLocal } right>

                  
                    <Link to="/">
                             <button className={classNames(styles.button2)}>
							 <img src="/images/LovelightChallenge.png" className={classNames(styles.logo, className)} alt="logo"/>
							 </button>
                    </Link>

                    <br/>

                  <hr className={classNames(styles.ligne)}/>

                  <br/>

                    <Link to="/archives-2024"   target="__blank">
                        <button className={classNames(styles.button)}>ARCHIVES 2024</button>
                    </Link>

                  <br/>

                    <Link to="https://lovelight.tv/"   target="__blank">
                        <button className={classNames(styles.button)}>DÉCOUVREZ LA CHAÎNE LOVELIGHT</button>
                    </Link>

                  <br/>

                  {
                    ( ( currentPathWithoutHash === "/"                                                  ||
                        currentPathWithoutHash === "/archives-2024"                                     ||
                        currentPathWithoutHash === "/mentions-legales"                                  ||
                        currentPathWithoutHash === "/conditions-generales-d-utilisation"                ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-cookies"              ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-donnees-personnelles" ||
                        currentPathWithoutHash === "/vote"                                              ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (

                            <Link to="/connexion">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>
                        )
                        :
                        (
                          <>
                            <Link to="/compte">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>
                          
						    <br/>

                            <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>

                          </>
                        )
                      )
                    )
                  }


                  {
                    ( ( currentPathWithoutHash === "/compte" ) &&
                      (
                        <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>
                      )
                    )
                  }
               

       </BGMenu>
          
    );
};

export default BurgerMenu4;


/*


  
{
                    ( ( currentPathWithoutHash === "/"                                                  ||
                        currentPathWithoutHash === "/mentions-legales"                                  ||
                        currentPathWithoutHash === "/conditions-generales-d-utilisation"                ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-cookies"              ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-donnees-personnelles" ||
                        currentPathWithoutHash === "/vote"                                              ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (

                            <Link to="/connexion">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>
                        )
                        :
                        (
                          <>
                            <Link to="/compte">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>
                          
						    <br/>

                            <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>

                          </>
                        )
                      )
                    )
                  }


                  {
                    ( ( currentPathWithoutHash === "/compte" ) &&
                      (
                        <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>
                      )
                    )
                  }


*/