import   React            , 
       { useEffect        ,
         useState         }  from 'react';
import   classNames              from 'classnames';
import { useCookies           }  from 'react-cookie';
import { Link                 }  from 'react-router-dom';

import   styles                  from './not_match.module.scss';

import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { Menus                }  from '../bloc_menus/menus';

export interface NotMatchProps {
  className?: string;
}

export const NotMatch = ({ className }: NotMatchProps) => {

  const [ showCookiesParameters , setCookiesParameters ] = useState(false);
  const [ cookies               , setCookie            ] = useCookies(['CookiesConsent']);
  const [ firstRender           , setFirstRender        ] = useState(true);

  useEffect(() => {

    if ( firstRender )
     {
      if ( document.cookie )
            {
              setCookiesParameters (false);
              }
       else {
              setCookiesParameters (true);
            }

      setFirstRender (false);
     }
  }); 
  

  const handleTriggerCookiesManagerClick = () => {
    setCookiesParameters( !showCookiesParameters );
  };

  return (
  
        <>

          {
            showCookiesParameters &&
            ( <WindowCookiesManager onClickOnCloseButton = {handleTriggerCookiesManagerClick }/> )
          }

          <Menus />

          <div className={classNames(styles.root, className)}>

            <div className={classNames(styles.contain, className)}>

              <div className={classNames(styles.espace_haut, className)}>
              </div>
    
	          <div className={classNames(styles.texte, className)}>
                <h1>La page que vous recherchez n'existe pas</h1>
              </div>
  
              <div className={classNames(styles.espace_bas, className)}>
              </div>
        
            </div>
            
          </div>
        
        </>
            
  );
};

export default NotMatch;
