import  React       ,
       { useState   ,
         useEffect }  from 'react';
import { Link       } from 'react-router-dom';
import   classNames   from 'classnames';
import { useCookies } from 'react-cookie';

import { Tab        , 
         Tabs       , 
		 TabList    , 
		 TabPanel   } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import   styles       from './window_cookies_manager_2.module.scss';

import { Trigger    } from '../widget_trigger/trigger';
import { Toggle     } from '../widget_toggle/toggle';

import { WWW_URLS   } from '../../urls/urls';

export interface WindowCookiesManager2Props {
  className?:           string;
  onClickOnCloseButton: (() => void)
}

export const WindowCookiesManager2 = ({ className, onClickOnCloseButton }: WindowCookiesManager2Props) => {

  const [ cookies               , setCookie ] = useCookies(['CookiesConsent','fonctionnement','analyse','marketing']);
  
  const [ cookiesFonctionnement , setCookiesFonctionnement ] = useState(true);
  const [ cookiesAnalyse        , setCookiesAnalyse        ] = useState(cookies.analyse == '1');
  const [ cookiesMarketing      , setCookiesMarketing      ] = useState(cookies.marketing == '1');

  useEffect(() => {
   console.log ("WindowCookiesManager2");
  });
  
  const handleClick = () => {

/*
    if ( cookiesFonctionnement ) 
          { 
           setCookie('fonctionnement', '1');
          }
     else {
           setCookie('fonctionnement', '-1');
          }
*/          
    if ( cookiesAnalyse ) 
           { 
           setCookie('analyse', '1');
          }
     else {
           setCookie('analyse', '-1');
          }
          
    if ( cookiesMarketing ) 
           { 
           setCookie('marketing', '1');
          }
     else {
           setCookie('marketing', '-1');
          }

    onClickOnCloseButton();
  };
  
     const handleCookiesFonctionnementConsentChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setCookiesFonctionnement(e.target.checked);
  };
   
    const handleCookiesAnalyseConsentChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCookiesAnalyse(e.target.checked);
  };

     const handleCookiesMarketingConsentChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCookiesMarketing(e.target.checked);
  };
   

  return (
            <>

            <div className={classNames(styles.window, className)}>
              
              <div className={classNames(styles.bloc, className)}>

                <Tabs>
				
                 <TabList>
                  <Tab>Cookies fonctionnels</Tab>
                  <Tab>Cookies d’analyse</Tab>
                  <Tab>Cookies de marketing</Tab>
                 </TabList>

                 <TabPanel>
                   <p className={classNames(styles.text_rgpd, className)}>
				    Toujours actif
				   </p>
                   <p className={classNames(styles.text_rgpd, className)}>
                    Ces cookies sont indispensables au bon fonctionnement du site web 
                    et ne peuvent pas être désactivés de nos systèmes. Ils ne sont 
                    généralement qu'activés en réponse à des actions que vous 
                    effectuez et qui correspondent à une demande de services, comme 
                    la configuration de vos préférences de confidentialité, la 
                    connexion ou le remplissage de formulaires. Vous pouvez configurer 
                    votre navigateur pour bloquer ou être alerté de l'utilisation de 
                    ces cookies. Cependant, si cette catégorie de cookies - qui ne 
                    stocke aucune donnée personnelle - est bloquée, certaines parties 
                    du site ne pourront pas fonctionner.
                   </p>
                   <p className={classNames(styles.text_rgpd, className)}>
				    <Link to={WWW_URLS.CookiesInfos} target="_blank" className = { classNames(styles.link, className) }>Détails des cookies</Link>
				   </p>
                 
                   <Toggle checked  = {cookiesFonctionnement}
                           onChange = {handleCookiesFonctionnementConsentChange} />                 
                 </TabPanel>
               
                 <TabPanel>
                   <p className={classNames(styles.text_rgpd, className)}>
                    Ces cookies nous permettent de déterminer le nombre de visites 
                    et les sources du trafic sur notre site web, afin d'en mesurer et 
                    d’en améliorer les performances. Ils nous aident également à 
                    identifier les pages les plus / moins visitées et à évaluer 
                    comment les visiteurs naviguent sur le site. Toutes les 
                    informations, collectées par ces cookies, sont agrégées et donc 
                    anonymisées. Si vous n'acceptez pas cette catégorie de cookies, 
                    nous ne pourrons pas savoir quand vous avez réalisé votre visite 
                    sur notre site web.
				   </p>
                   <p className={classNames(styles.text_rgpd, className)}>
				    <Link to={WWW_URLS.CookiesInfos} target="_blank" className = { classNames(styles.link, className) }>Détails des cookies</Link>
				   </p>
                 
                   <Toggle checked  = {cookiesAnalyse}
                           onChange = {handleCookiesAnalyseConsentChange} />
                 </TabPanel>
               
                 <TabPanel>
                   <p className={classNames(styles.text_rgpd, className)}>
                    Ces cookies peuvent être activés sur notre site web par nos 
                    partenaires publicitaires. Ils peuvent être utilisés par ces 
                    entreprises pour établir des profils sur vos intérêts, et afin de 
                    vous proposer des publicités ciblées sur d’autres sites. 
                    Ils fonctionnement uniquement en identifiant votre navigateur et 
                    votre appareil. Si vous n'acceptez pas cette catégorie de cookies, 
                    des publicités moins ciblées sur vos intérêts vous seront 
                    proposées lors de votre navigation sur d'autres sites web.</p>
                   <p className={classNames(styles.text_rgpd, className)}>
				    <Link to={WWW_URLS.CookiesInfos} 
					      target="_blank" 
						  className = { classNames(styles.link, className) }>Détails des cookies</Link>
				   </p>
                 
                   <Toggle checked  = {cookiesMarketing}
                           onChange = {handleCookiesMarketingConsentChange} />
                 </TabPanel>
               
                </Tabs>

              </div>
              
              <br/>

              <div className={classNames(styles.buttons, className)}>
            
                <Trigger link      = "#"
                         name      = "Valider les réglages" 
                         onClick   = {handleClick}
                         className = {classNames(styles.trigger_window, className)}/>
              </div>             
            
            </div>
            
            </>
         );
};

export default WindowCookiesManager2;
