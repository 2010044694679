import   React            , 
       { useState         }  from "react";
import   classNames          from 'classnames';

import   styles              from './album.module.scss';
import { useTypedSelector }  from '../../hooks/useTypeSelector';
import { Photo            }  from '../bloc_photo/photo';

export interface AlbumProps {
  className?:     string;
  onAlbumChange:  () => void;
}

export const Album = ({ className         ,
                        onAlbumChange }: AlbumProps) => {

  const { registration } = useTypedSelector((state) => state.registrationManagment );

  const [key, setKey] = useState(0);

  const handleNewPhotoUpload = () => {
    
   setKey((prevKey) => prevKey + 1);
  }

  const handlePhotoRemove = () => {
    
   setKey((prevKey) => prevKey + 1);
  }
 

  return (
   
    <div className={classNames(styles.bloc, className)}>
 
    		  <p className={classNames(styles.texte_1, className)}>
                 ENVOYEZ VOS PHOTOS
              </p>

              <p className={classNames(styles.texte_2, className)}>
               1 photo miminum jusqu’à trois maximum sont à télécharger pour valider votre inscription au Lovelight  Challenge
              </p>
 
      {
	    registration.photos.map ( ( photo, index ) => (
	  
          <Photo index={index} 
		         onPhotoRemove = {handlePhotoRemove} />
          )
	    )
	   
	  }

      {
	    ( registration.photos.length < 3 ) ?
        ( <Photo onNewPhotoUpload = {handleNewPhotoUpload} /> ) :
		( <></> )
      }
	  
    </div>
	
   );
};

export default Album;
