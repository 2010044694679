import   React             , 
       { useEffect         , 
         useState          }  from 'react';
import { Dispatch          }  from 'redux';
import { useDispatch       }  from 'react-redux';

import   axios                from 'axios';

import   classNames           from 'classnames';



import   styles               from './photo2.module.scss';

         
import { Registration,
         Photo             }  from '../../redux/registrationManagment/actionTypes';

import { useTypedSelector  }  from '../../hooks/useTypeSelector';
import { store             }  from '../../redux/store';

import { Field             }  from '../widget_field/field';
import { Select            }  from '../widget_select/select';
import { Toggle            }  from '../widget_toggle/toggle';
import { TextArea          }  from '../widget_textarea/textarea';
import { Trigger           }  from '../widget_trigger/trigger';
import { WindowPhoto       }  from '../window_photo/window_photo';
import { WindowQuestion    }  from '../window_question/window_question';
import { WindowMessage     }  from '../window_message/window_message';

import { API_URLS          ,
         WWW_URLS          }  from '../../urls/urls';

/*****************/
/* Le bloc photo */
/*****************/
 
export interface Photo2Props {
  className?:        string;
  photo:             Photo;
  firstname?:        string;
  lastname?:         string;
  }
 
export const Photo2 = ({ className , 
                         photo     ,
						 firstname ,
						 lastname  }: Photo2Props) => {
                        
  const dispatch: Dispatch<any> = useDispatch()

  
  const [ showZoom     , setShowZoom     ] = useState ( false );

  const [ urlMagnified , setUrlMagnified ] = useState ( "" );
  const [ urlThumbnail , setUrlThumbnail ] = useState ( "" );

  useEffect(() => {

    generateUrl ();	
	  
  
  } ); 


  const handlePhotoZoomClick = () => {
   
    setShowZoom( true );
  }

  const handleClosePhotoZoomClick = () => {
   
    setShowZoom( false );
  }
  
  const generateUrl = () => {
  
    const fileName   = photo.file;   

    const serveur    = new String ( "https://photos.lovelightchallenge.com/" );   
    const extension1 = new String ( "_mini.jpeg" );   
    const extension2 = new String ( ".jpeg" );   
    
    setUrlThumbnail ( serveur.concat(fileName.toString()).concat(extension1.toString()) );
    setUrlMagnified ( serveur.concat(fileName.toString()).concat(extension2.toString()) );
  }

  return (
  
    <div className={classNames(styles.root, className)}>
    
      { 
        showZoom && 
        ( <WindowPhoto onClickOnCloseButton = {handleClosePhotoZoomClick} 
		               url                  = {urlMagnified} 
					   nom                  = {photo.name}
                       firstname            = {firstname}
                       lastname             = {lastname}   /> ) 
      }

      <div className={classNames(styles.bloc, className)}>

        <div className={classNames(styles.bloc_ligne_photo, className)}>
          
          <img src={urlThumbnail} 
               className={classNames(styles.vignette, className)} 
               onClick= {handlePhotoZoomClick}/>    
          
        </div>
     
        <div className={classNames(styles.bloc_ligne_info, className)}>

          <div className={classNames(styles.grid, className)}>
                
            <div className={classNames(styles.name_nom, className)}>
              Nom de la photo
            </div> 

            <div className={classNames(styles.name_data, className)}>
               {photo.name}
            </div> 

            <div className={classNames(styles.description_nom, className)}>
               Description
            </div>

            <div className={classNames(styles.description_data, className)}>
               {photo.description}
            </div>

            <div className={classNames(styles.cameraBrand_nom, className)}>
               Marque de l'appareil photo
            </div>

            <div className={classNames(styles.cameraBrand_data, className)}>
               {photo.cameraBrand}
            </div>

            <div className={classNames(styles.cameraModel_nom, className)}>
               Modèle de l'appareil photo
            </div>

            <div className={classNames(styles.cameraModel_data, className)}>
               {photo.cameraModel}
            </div>

            <div className={classNames(styles.certification_nom, className)}>
               Certification de la photo
            </div>

            <div className={classNames(styles.certification_data, className)}>
               {photo.authorCertification ? "oui":"non"}
            </div>

            <div className={classNames(styles.etat_nom, className)}>
               Etat
            </div>

            <div className={classNames(styles.etat_data, className)}>
               {photo.reviewState}
            </div>

{/*
            <div className={classNames(styles.nbVotes_nom, className)}>
               Nombre de votes
            </div>

            <div className={classNames(styles.nbVotes_data, className)}>
               {photo.nbVotes == -1 ? "Hors concours" : `${photo.nbVotes}` }
            </div>

            <div className={classNames(styles.rang_nom, className)}>
               Position
            </div>

            <div className={classNames(styles.rang_data, className)}>
               
			   {
			    photo.rang == -1 
                ?
                "Non classée"
                :
                <>
			   {photo.rang}
               <sup>{photo.rang == 1 ? "ère" : "ème"}</sup>
			   &nbsp;
			   { photo.exAequo > 0 ? ( photo.exAequo == 1 ? "ex aequo avec un autre participant" : `ex aequo avec  ${photo.exAequo} autres participants` ) : "" }
                </>				
			   }
			   
            </div>
			
			*/}
			
          </div>

        </div>
            
      </div>
	
    </div>
  );
};

export default Photo2;
