import   React        from 'react';
import   classNames   from 'classnames';

import   styles       from './window_message.module.scss';

import { Trigger    } from '../widget_trigger/trigger';

export interface WindowMessageProps {
  className?:           string;
  message:              string
  onClickOnCloseButton: (() => void)
}

export const WindowMessage = ({ className, message, onClickOnCloseButton }: WindowMessageProps) => {

  const handleClickClose = () => {

       onClickOnCloseButton();
  };


  return (
            <div className={classNames(styles.window, className)}>

              <p className   = {classNames(styles.message, className)}>{message}</p>

			  <Trigger link      = "#"
                       name      = "Fermer"
                       onClick   = {handleClickClose}
                       className = {classNames(styles.trigger_window, className)}/>

            </div>
         );
};

export default WindowMessage;
