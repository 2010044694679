import   React                 from "react";
import   classNames            from 'classnames';
import { Link               ,
         useNavigate        ,
         useLocation        }  from 'react-router-dom';
import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';
import { HashLink           }  from 'react-router-hash-link';

import { Menu               , 
         MenuItem           , 
		 MenuButton         }  from '@szhsin/react-menu';


/* https://szhsin.github.io/react-menu/
   https://codesandbox.io/p/sandbox/react-menu-react-router-example-dw4ku?file=%2Findex.js */

import   styles                from './sticky_menu4.module.scss';

import { initAuthentication }  from '../../redux/authenticationManagment/actionCreators';
import { initRegistration   }  from '../../redux/registrationManagment/actionCreators';

import { useTypedSelector   }  from '../../hooks/useTypeSelector';

export interface StickyMenu4Props {
  className?: string;
  page?:      string;  
}

export const StickyMenu4 = ({ className , page }: StickyMenu4Props) => {

  const { authentication } = useTypedSelector((state) => state.authenticationManagment );

  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch();
  const navigate                = useNavigate();

  const currentPathWithoutHash = location.pathname;
  
  const handleLogoutClick = () => {
    
	dispatch ( initRegistration () );
	dispatch ( initAuthentication () );
    navigate ("/");
  }

/*

                   <Menu className={classNames(styles.menu, className)}
				         menuButton={<MenuButton className={styles.button}>LE JEU</MenuButton>}>
                      <MenuItem>
                         <HashLink to="/#presentation"
                             scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })} >
                             <p className={classNames(styles.button2)}>A propos</p>
                         </HashLink>
                      </MenuItem>
                      <MenuItem>
                         <HashLink to="/#les-dates-clees"
                             scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })} >
                             <p className={classNames(styles.button2)}>Les dates clés</p>
                         </HashLink>
                      </MenuItem>
                      <MenuItem>
                         <HashLink to="/#les-partenaires"
                             scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" })} >
                             <p className={classNames(styles.button2)}>Les partenaires</p>
                         </HashLink>
                      </MenuItem>
                   </Menu>

*/

  return (
  
              <div className={classNames(styles.sticky, className)}>

                <div className={classNames(styles.left, className)}>
                    <Link to="/">
                       <img src="/images/LovelightChallenge_long.png" className={classNames(styles.logo, className)} alt="logo"/>
                    </Link>
                </div>

                <div className={classNames(styles.right, className)}>


                    <Link to="/archives-2024"   target="__blank">
                        <button className={classNames(styles.button)}>ARCHIVES 2024</button>
                    </Link>

                    <Link to="https://lovelight.tv/"   target="__blank">
                        <button className={classNames(styles.button)}>DÉCOUVREZ LA CHAÎNE LOVELIGHT</button>
                    </Link>
					

  				  {
                    ( ( currentPathWithoutHash === "/"                                                  ||
                        currentPathWithoutHash === "/archives-2024"                                     ||
                        currentPathWithoutHash === "/mentions-legales"                                  ||
                        currentPathWithoutHash === "/conditions-generales-d-utilisation"                ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-cookies"              ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-donnees-personnelles" ||
                        currentPathWithoutHash === "/vote"                                              ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (

                            <Link to="/connexion">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>
                        )
                        :
                        (
                          <>
                            <Link to="/compte">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>

                            <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>

                          </>
                        )
                      )
                    )
                  }


                  {
                    ( ( currentPathWithoutHash === "/compte" ) &&
                      (
                        <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>
                      )
                    )
                  }
				  
                </div>

              </div>

          
         );
};

export default StickyMenu4;


/*

				  {
                    ( ( currentPathWithoutHash === "/"                                                  ||
                        currentPathWithoutHash === "/mentions-legales"                                  ||
                        currentPathWithoutHash === "/conditions-generales-d-utilisation"                ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-cookies"              ||
                        currentPathWithoutHash === "/notice-d-information-sur-les-donnees-personnelles" ||
                        currentPathWithoutHash === "/vote"                                              ) &&
                      (
                        ( authentication.login === "" )
                        ?
                        (

                            <Link to="/connexion">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>
                        )
                        :
                        (
                          <>
                            <Link to="/compte">
                              <button className={classNames(styles.button)}>ESPACE CANDIDAT</button>
                            </Link>

                            <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>

                          </>
                        )
                      )
                    )
                  }


                  {
                    ( ( currentPathWithoutHash === "/compte" ) &&
                      (
                        <button className={classNames(styles.button)} onClick={handleLogoutClick}>SE DECONNECTER</button>
                      )
                    )
                  }


*/