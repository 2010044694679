import   React               from "react";
import   classNames          from 'classnames';

import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import   styles              from './bloc_countdown3.module.scss';

import { Trigger              }  from '../widget_trigger/trigger';


export interface BlocCountdown3Props {
  className?: string;
}

export const BlocCountdown3 = ({ className  }: BlocCountdown3Props) => {

  return (
        
           <div className={classNames(styles.root, className)}>
		   
             <div className={classNames(styles.photo_overlay, className)}>
               
			   <img src="/images/LovelightChallenge_decale.png" 
			        className={classNames(styles.logo_challenge, className)}
			        alt="logo LovelightChallenge"/>
               
			   <div className={classNames(styles.text_overlay, className)}>
			    
				
			      <FlipClockCountdown to        = {new Date('2024-01-21T22:00:00').getTime()}
				                      className = {classNames(styles.flip_clock, className)}
									  labels    = {['Jours', 'Heures', 'Minutes', 'Secondes']}            />
									  
				  <p  className={classNames(styles.text_bas, className)}>
 				   avant la clôture des votes
				  </p>

                  <Trigger name        = "VOTEZ"
                           link        = "/vote"
                           className   = {classNames(styles.trigger_registration, className)} />


		   	   </div>
			   
             </div>
			 
           </div>
          
		 );
};

export default BlocCountdown3;
