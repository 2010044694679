import   React               from "react";
import   classNames          from 'classnames';
import { Link               ,
         useNavigate        ,
         useLocation        }  from 'react-router-dom';
import   ImageGallery            from "react-image-gallery";
import   "react-image-gallery/styles/css/image-gallery.css";

import   styles                  from './welcome3.module.scss';


import { BlocCountdown3        }  from '../bloc_countdown3/bloc_countdown3';

export interface Welcome3Props {
  className?:         string;                 // classe CSS complémentaire
}

export const Welcome3 = ({ className  }: Welcome3Props) => {

  const images = [
  
    {
      original: "/images/Partenaires/Nikon.png",
	  thumbnail: "/images/Partenaires/Nikon.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Sigma.png",
      thumbnail: "/images/Partenaires/Sigma.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Leica.png",
      thumbnail: "/images/Partenaires/Leica.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Camara.png",
      thumbnail: "/images/Partenaires/Camara.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
    {
      original: "/images/Partenaires/Lumix.png",
      thumbnail: "/images/Partenaires/Lumix.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
    {
      original: "/images/Partenaires/PhaseOne.png",
      thumbnail: "/images/Partenaires/PhaseOne.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Profoto.png",
      thumbnail: "/images/Partenaires/Profoto.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Nation-Photo.png",
      thumbnail: "/images/Partenaires/Nation-Photo.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
  
  
    {
      original: "/images/Partenaires/Canon.png",
      thumbnail: "/images/Partenaires/Canon.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Ilford.png",
      thumbnail: "/images/Partenaires/Ilford.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Leofoto.png",
      thumbnail: "/images/Partenaires/Leofoto.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
    {
      original: "/images/Partenaires/Think-Tank.png",
      thumbnail: "/images/Partenaires/Think-Tank.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },
	
	{
      original: "/images/Partenaires/Fujifilm.png",
	  thumbnail: "/images/Partenaires/Fujifilm.png",
      originalClass: classNames(styles.logo_partenaire, className)
    },

  ];


  return (

              <div className={classNames(styles.root, className)}>

                <div className={classNames(styles.contain, className)}>
    
                    <div className={classNames(styles.espace, className)}></div>

                         <BlocCountdown3 />

                    <div className={classNames(styles.espace, className)}></div>

						<Link to="https://www.instagram.com/lovelight_challenge/" target="__blank">
                          <img src="/images/Instagram.png" className={classNames(styles.minilogo, className)} alt="Instagram"/>
                        </Link>
                         &nbsp;&nbsp;&nbsp;
                        <Link to="https://www.facebook.com/profile.php?id=61551359803735" target="__blank">
                          <img src="/images/Facebook.png" className={classNames(styles.minilogo, className)} alt="Facebook"/>
                        </Link>
						
                </div>

                <div className={classNames(styles.blocDroite, className)}>
                  
                    <div className={classNames(styles.accroche, className)}>
                     L’émission qui révèle les nouveaux talents de la photographie !
                    </div>

                    <div className={classNames(styles.accroche2, className)}>
                     VENEZ VOTER POUR LES MEILLEURES PHOTOS<br/>
                     JUSQU'AU 21 JANVIER À 22H00<br/>
                    </div>
                  
                    <div className={classNames(styles.partenaires, className)}>

                      <ImageGallery items                = {images} 
                                    infinite             = {true}
                                    showNav              = {false}
                                    showThumbnails       = {false}
                                    showFullscreenButton = {false}
                                    showPlayButton       = {false}
                                    showBullets          = {false}
                                    showIndex            = {false}
                                    autoPlay             = {true}
                                    slideDuration        = {450}     
                                    additionalClass      = {classNames(styles.album, className)} />
                    </div>
 			    
				    <br/>

                </div>

              </div>
           
  );
};


export default Welcome3;
