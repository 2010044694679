import   React                ,  
       { useEffect            , 
         useState             }  from "react";
import   classNames              from 'classnames';
import { Dispatch             }  from 'redux';
import { useDispatch          }  from 'react-redux';
import   axios                , 
       { AxiosError           }  from 'axios';
import { differenceInYears    }  from 'date-fns';
	   
import   styles                  from './profil_edition.module.scss';

import { initRegistration     ,
         updateId             ,  
         updateEmail          ,  
         updatePassword       , 
         updatePassword2      , 
         updateFirstname      , 
         updateLastname       , 
         updateSex            , 
         updateBirthday       , 
         updateAddress        , 
         updateAddress2       , 
         updateZipCode        ,
         updateCity           , 
         updateCountry        , 
         updatePhoneNumber    ,
         updateWebsite        , 
         updateInstagram      , 
         updateFacebook       , 
         updateTiktok         , 
         updateBio            ,
         verifyRegistration   }  from '../../redux/registrationManagment/actionCreators';
		 
import { Field                }  from '../widget_field/field';
import { Select               }  from '../widget_select/select';
import { TextArea             }  from '../widget_textarea/textarea';
import { Trigger              }  from '../widget_trigger/trigger';
import { StickyMenu           }  from '../sticky_menu/sticky_menu';
import { WindowCookiesManager }  from '../window_cookies_manager/window_cookies_manager';
import { WindowMessage                   }  from '../window_message/window_message';

import { useTypedSelector     }  from '../../hooks/useTypeSelector';
import { store                }  from '../../redux/store';

import { API_URLS ,
         WWW_URLS } from '../../urls/urls';

export interface ProfilEditionProps {
  className?:         string;  
  onPageStateChange:  (num: number) => void;  // gérer le click sur le bouton "Modifier profil"
}

export const ProfilEdition = ({ className         ,
                                onPageStateChange }: ProfilEditionProps) => {

  const dispatch: Dispatch<any> = useDispatch()

  const { authentication } = useTypedSelector ((state) => state.authenticationManagment );
  const { registration   } = useTypedSelector ((state) => state.registrationManagment   );

  const [ firstname             , setFirstname          ] = useState( registration.firstname   );
  const [ lastname              , setLastname           ] = useState( registration.lastname    );
  const [ sex                   , setSex                ] = useState( registration.sex         );
  const [ birthday              , setBirthday           ] = useState( registration.birthday    );
  const [ address               , setAddress            ] = useState( registration.address     );
  const [ address2              , setAddress2           ] = useState( registration.address2    );
  const [ zipCode               , setZipCode            ] = useState( registration.zipCode     );
  const [ city                  , setCity               ] = useState( registration.city        );
  const [ country               , setCountry            ] = useState( registration.country     );
  const [ phoneNumber           , setPhoneNumber        ] = useState( registration.phoneNumber );
  const [ website               , setWebsite            ] = useState( registration.website     );
  const [ instagram             , setInstagram          ] = useState( registration.instagram   );
  const [ facebook              , setFacebook           ] = useState( registration.facebook    );
  const [ tiktok                , setTiktok             ] = useState( registration.tiktok      );
  const [ bio                   , setBio                ] = useState( registration.bio         );
  const [ verify                , setVerify             ] = useState( true                     );

  const [ messageFirstname      , setMessageFirstname   ] = useState('');
  const [ messageLastname       , setMessageLastname    ] = useState('');
  const [ messageSex            , setMessageSex         ] = useState('');
  const [ messageBirthday       , setMessageBirthday    ] = useState('');
  const [ messageAddress        , setMessageAddress     ] = useState('');
  const [ messageAddress2       , setMessageAddress2    ] = useState('');
  const [ messageZipCode        , setMessageZipCode     ] = useState('');
  const [ messageCity           , setMessageCity        ] = useState('');
  const [ messageCountry        , setMessageCountry     ] = useState('');
  const [ messagePhoneNumber    , setMessagePhoneNumber ] = useState('');
  const [ messageWebsite        , setMessageWebsite     ] = useState('');
  const [ messageInstagram      , setMessageInstagram   ] = useState('');
  const [ messageFacebook       , setMessageFacebook    ] = useState('');
  const [ messageTiktok         , setMessageTiktok      ] = useState('');
  const [ messageBio            , setMessageBio         ] = useState('');

  const [ showMessageError      , setShowMessageError   ] = useState(false);
  const [ showMessageAck        , setShowMessageAck     ] = useState(false);
  const [ showUpdateOk          , setShowUpdateOk       ] = useState(false);

  useEffect(() => {
  
//    console.log ("profil edit" , registration, zipCode);
  });

  
  const handleFirstnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFirstname (e.target.value) 
    dispatch ( updateFirstname (e.target.value) );
    
    if ( registration.firstname == "" )
          {
           setMessageFirstname ("Il manque le prénom");
          }
     else {
           setMessageFirstname ("");
          }
 };

  const handleLastnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setLastname (e.target.value) 
    dispatch ( updateLastname (e.target.value) );
    
    if ( registration.lastname == "" )
          {
           setMessageLastname ("Il manque le nom");
          }
     else {
           setMessageLastname ("");
          }
  };

  const handleSexChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setSex (e.target.value) 
    dispatch ( updateSex (e.target.value) );
    
    if ( registration.sex == "" )
          {
           setMessageSex ("Il manque le sexe");
          }
     else {
           setMessageSex ("");
          }
  };

  const handleBirthdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setBirthday (e.target.value) 
    dispatch ( updateBirthday (e.target.value) );
    
    if ( registration.birthday == "" )
          {
           setMessageBirthday ("Il manque la date de naissance");
          }
     else {
           const birthdayDate            = new Date(registration.birthday);
           const currentDate             = new Date();
           const differenceInYearsResult = differenceInYears(currentDate, birthdayDate);

           console.log ("differenceInYearsResult ",differenceInYearsResult);

           if ( differenceInYearsResult < 18 )
                 {
                  setMessageBirthday ("Il faut être âgé d'au moins 18 ans");
				 }
			else {
                  setMessageBirthday ("");
                 }			
          }
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress (e.target.value) 
    dispatch ( updateAddress (e.target.value) );

    if ( registration.address == "" )
          {
           setMessageAddress ("Il manque l'adresse");
          }
     else {
           setMessageAddress ("");
          }

  };

  const handleAddress2Change = (e: React.ChangeEvent<HTMLInputElement>) => {

    setAddress2 (e.target.value) 
    dispatch ( updateAddress2 (e.target.value) );
     setMessageAddress2 ("");
  };
    
  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setZipCode (e.target.value) 
    dispatch ( updateZipCode (e.target.value) );
    
    if ( registration.zipCode == "" )
          {
           setMessageZipCode ("Il manque le code postal");
          }
     else {
           setMessageZipCode ("");
          }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setCity (e.target.value) 
    dispatch ( updateCity (e.target.value) );
    
    if ( registration.city == "" )
          {
           setMessageCity ("Il manque la ville");
          }
     else {
           setMessageCity ("");
          }
  };

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {

    setCountry (e.target.value)
    dispatch ( updateCountry (e.target.value) );

    if ( registration.country == "" )
          {
           setMessageCountry ("Il manque le pays");
          }
     else {
           setMessageCountry ("");
          }
  };
  
  const handlePhoneNumberChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    setPhoneNumber (e.target.value) 
    dispatch ( updatePhoneNumber (e.target.value) );
      
    if ( registration.phoneNumber == "" )
          {
           setMessagePhoneNumber ("Il manque le numéro de téléphone");
          }
     else {
           const check = await checkValue ( "phoneNumber" , registration.phoneNumber , registration.email );
      
           if ( check ) 
                 {
                  setMessagePhoneNumber ("Un compte existe déjà avec ce numéro de téléphone.");
                 }
            else {
                  setMessagePhoneNumber ("");
                 }
          }
  };

  const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setWebsite (e.target.value) 
    dispatch ( updateWebsite (e.target.value) );
     setMessageWebsite ("");
  };

  const handleInstagramChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setInstagram (e.target.value) 
    dispatch ( updateInstagram (e.target.value) );
     setMessageInstagram ("");
  };

  const handleFacebookChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setFacebook (e.target.value) 
    dispatch ( updateFacebook (e.target.value) );
     setMessageFacebook ("");
  };

  const handleTiktokChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setTiktok (e.target.value) 
    dispatch ( updateTiktok (e.target.value) );
     setMessageTiktok ("");
  };

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

    setBio (e.target.value) 
    dispatch ( updateBio (e.target.value) );
     setMessageBio ("");
  };

  const checkValue = async (info1: string, info2: string, email: string): Promise<boolean> => {
 
    const baseUrl  = `${API_URLS.GetExistenceCheck}?`;  // "https://api.lovelightfactory.com/api/existence_check?";
    const nomInfo1 = "champ="; 
    const nomInfo2 = "valeur=";
    const nomInfo3 = "email=";
    const valInfo1 = encodeURIComponent (info1);      
    const valInfo2 = encodeURIComponent (info2);      
    const valInfo3 = encodeURIComponent (email);      

  //  const url = `${baseUrl}${nomInfo1}${valInfo1}&${nomInfo2}${valInfo2}`;
    const url = `${baseUrl}${nomInfo1}${valInfo1}&${nomInfo2}${valInfo2}&${nomInfo3}${valInfo3}`;

    var resultat = false;

    try {
      
      const response = await axios.get(url);
      
      const statut = response.status;
      const exist  = response.data.exist;

      if ( statut == 200 && exist == true )
       {
        resultat = true;
       }
    } 
    catch (error) {

      setShowMessageError ( true );     
    }
       
    return resultat;
  };

  const checkInputs = async () => {

    var test = true;

    if ( registration.firstname == '' )
     {
      test = false;
      setMessageFirstname ("Il manque le prénom")
     }

    if ( registration.lastname == '' )
     {
      test = false;
      setMessageLastname ("Il manque le nom")
     }

    if ( registration.sex == '' )
     {
      test = false;
      setMessageSex ("Il manque le sexe")
     }

    if ( registration.birthday == '' )
          {
           test = false;
           setMessageBirthday ("Il manque la date de naissance")
          }	  
     else {
           const birthdayDate            = new Date(registration.birthday);
           const currentDate             = new Date();
           const differenceInYearsResult = differenceInYears(currentDate, birthdayDate);

           if ( differenceInYearsResult < 18 )
            {
             test = false;
             setMessageBirthday ("Il faut être âgé d'au moins 18 ans");
			}
          }

    if ( registration.address == '' )
     {
      test = false;
      setMessageAddress ("Il manque l'adresse")
     }

    if ( registration.zipCode == '' )
     {
      test = false;
      setMessageZipCode ("Il manque le code postal")
     }

    if ( registration.city == '' )
     {
      test = false;
      setMessageCity ("Il manque la ville")
     }

    if ( registration.country == '' )
     {
      test = false;
      setMessageCountry ("Il manque le pays")
     }


    if ( registration.phoneNumber == '' )
          {
           test = false;
           setMessagePhoneNumber ("Il manque le numéro de téléphone")
          }
     else {
           const checkPhoneNumber = await checkValue ( "phoneNumber" , registration.phoneNumber , registration.email );

           if ( checkPhoneNumber )
            {
             test = false;
             setMessagePhoneNumber ("Il existe déjà un compte avec ce numéro de téléphone")
            }
          }
          
    verifyRegistration (test);
    setVerify          (test);
    
    return test;
  }

  const handleAccountUpdateClick = async () => {
  
    const test = await checkInputs ();

    if ( test )
     {
      const id          =   registration.id
      const password    =   ""; //( passwordModified ? registration.password : "" )
      const firstname   =   registration.firstname
      const lastname    =   registration.lastname
      const sex         =   registration.sex
      const birthday    =   registration.birthday
      const address     =   registration.address
      const address2    =   registration.address2
      const zipCode     =   registration.zipCode
      const city        =   registration.city
      const country     = registration.country
      const phoneNumber =   registration.phoneNumber
      const website     =   registration.website
      const instagram   =   registration.instagram
      const facebook    =   registration.facebook
      const tiktok      =   registration.tiktok
      const bio         =   registration.bio

      const baseUrl     =  `${API_URLS.PatchUser}`; // "https://api.lovelightfactory.com/api/users/"

      const url = `${baseUrl}${id}`;

      // const url = 'https://webhook.site/292cb8db-a372-4805-8bf6-93e0768063ab';

      console.log ("handleAccountUpdateClick : ", url);

      const headers = {
          'Accept': 'application/ld+json',
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${authentication.token}`,
      };

      try {
        const response = await axios.patch (
                                              url              ,
                              
                                            { 
                                              firstname        ,
                                              lastname         ,
                                              sex              ,
                                              birthday         ,
                                              address          ,
                                              address2         ,
                                              zipCode          ,
                                              city             ,
                                              country          ,
                                              phoneNumber      ,
                                              website          ,
                                              instagram        ,
                                              facebook         ,
                                              tiktok           ,
                                              bio           
                                            },
                                             
                                            { headers: headers }
                                          
                                           );

        setShowMessageAck   ( true );     
        setShowMessageError ( false );
            setShowUpdateOk( true );

     
      } 
      catch (error) {
      
        setShowMessageAck   ( false );     
        setShowMessageError ( true );     
       }
    }
  };

  const handleCancelClick = () => {

    onPageStateChange (0);
  }

  const handleUpdateClick = () => {
  
    onPageStateChange (0);

  }

    const handleCloseUpdateOkMessageClick = () => {

    setShowUpdateOk( false );
    	    onPageStateChange (0);

  }



  return (
           <div className={classNames(styles.root, className)}>
             
                   {
        showUpdateOk &&
        ( <WindowMessage onClickOnCloseButton = {handleCloseUpdateOkMessageClick} message = "Les modifications ont bien été effectuées" /> )
      }


			 <br/>
			 
                <div className={classNames(styles.grid_firstname_nom,  styles.nom,  className)}>
				  Prénom <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_firstname_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {firstname}
                           error       = {messageFirstname}
                           onChange    = {handleFirstnameChange} />
                </div>
                    
                <div className={classNames(styles.grid_lastname_nom,  styles.nom,  className)}>
				  Nom <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_lastname_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {lastname}
                           error       = {messageLastname}
                           onChange    = {handleLastnameChange} />
			    </div>
                    
                <div className={classNames(styles.grid_sex_nom,  styles.nom,  className)}>
				  Sexe <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_sex_data,  className)}>
                  <Select  name        = ""
                           choices    = { [
                                               "Aucune réponse",
                                               "Homme",
                                               "Femme"
                                          ] }
                           value       = {sex}
                           error       = {messageSex}
                           onChange    = {handleSexChange}     />
                </div>
                    
                <div className={classNames(styles.grid_birthday_nom,  styles.nom,  className)}>
				  Date de naissance <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_birthday_data,  className)}>
                  <Field   name        = ""
                           type        = "date"
                           placeholder = ""
                           value       = {birthday}
                           error       = {messageBirthday}
                           onChange    = {handleBirthdayChange}  />
                </div>
                    
                <div className={classNames(styles.grid_address_nom,  styles.nom,  className)}>
				  Adresse <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_address_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {address}
                           error       = {messageAddress}
                           onChange    = {handleAddressChange}  />
                </div>
                    
                <div className={classNames(styles.grid_address2_nom,  styles.nom,  className)}>
				  Complément d'adresse
                </div>
                <div className={classNames(styles.grid_address2_data,  className)}>
                  <input type        = "text"
                         placeholder = ""
                         value       = {address2}
                         onChange    = {handleAddress2Change} 
						 className={classNames(styles.field_input,className)}  />
                </div>
                    
                <div className={classNames(styles.grid_zipCode_nom,  styles.nom,  className)}>
				  Code postal <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_zipCode_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {zipCode}
                           error       = {messageZipCode}
                           onChange    = {handleZipCodeChange}  />
                </div>
                    
                <div className={classNames(styles.grid_city_nom,  styles.nom,  className)}>
				  Ville <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_city_data,  className)}>
                   <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {city}
                           error       = {messageCity}
                           onChange    = {handleCityChange}   />
                </div>
                    
                <div className={classNames(styles.grid_country_nom,  styles.nom,  className)}>
				  Pays <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_country_data,  className)}>
                   <Select  name        = ""
                           choices    = { [
                                               "France",
                                               "Belgique",
                                               "Suisse"
                                          ] }
                           value       = {country}
                           error       = {messageCountry}
                           onChange    = {handleCountryChange}     />
                </div>
                    
                <div className={classNames(styles.grid_phoneNumber_nom,  styles.nom,  className)}>
				  Numéro de téléphone <i className={classNames(styles.required, className)}>&nbsp;&#1645;</i>
                </div>
                <div className={classNames(styles.grid_phoneNumber_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {phoneNumber}
                           error       = {messagePhoneNumber}
                           onChange    = {handlePhoneNumberChange} />
                </div>
                    
                <div className={classNames(styles.grid_website_nom,  styles.nom,  className)}>
				  Site web
                </div>
                <div className={classNames(styles.grid_website_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {website}
                           error       = {messageWebsite}
                           onChange    = {handleWebsiteChange} />
                </div>

                <div className={classNames(styles.grid_instagram_nom,  styles.nom,  className)}>
				  Instagram
                </div>
                <div className={classNames(styles.grid_instagram_data,  className)}>
                   <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {instagram}
                           error       = {messageInstagram}
                           onChange    = {handleInstagramChange} />
                </div>
                    
                <div className={classNames(styles.grid_facebook_nom,  styles.nom,  className)}>
				  Facebook
                </div>
                <div className={classNames(styles.grid_facebook_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {facebook}
                           error       = {messageFacebook}
                           onChange    = {handleFacebookChange} />
                </div>
                    
                <div className={classNames(styles.grid_tiktok_nom,  styles.nom,  className)}>
				  Tiktok
                </div>
                <div className={classNames(styles.grid_tiktok_data,  className)}>
                  <Field   name        = ""
                           type        = "text"
                           placeholder = ""
                           value       = {tiktok}
                           error       = {messageTiktok}
                           onChange    = {handleTiktokChange} />
                </div>

                <div className={classNames(styles.grid_bio_nom,  styles.nom,  className)}>
				  Qui êtes-vous ?
                </div>
                <div className={classNames(styles.grid_bio_data,  className)}>
                  <TextArea name        = ""
                            placeholder = ""
                            value       = {bio}
                            error       = {messageBio}
                            onChange    = {handleBioChange} />
                </div>
			 
             <div className={classNames(styles.grid_button)}>
			    <Trigger  name        = "Retour"
                          link        = "#"
                          onClick     = {handleCancelClick}
                          className   = {classNames(styles.big_trigger, className)} />

			    <Trigger  name        = "Enregistrer les modifications"
                          link        = "#"
                          onClick     = {handleAccountUpdateClick}
                          className   = {classNames(styles.big_trigger, className)} />
             </div>

			 <br/>

           </div>
         );
};

export default ProfilEdition;
