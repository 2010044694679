import { Dispatch } from 'redux';

import { ActionType                       ,
         Ensemble                         ,
         actionInitEnsemble               ,
		 actionAddPhoto                   ,
		 actionUpdatePhotoSelection       ,
		 DispatchTypeInitEnsemble         ,
         DispatchTypeAddPhoto             ,
		 DispatchTypeUpdatePhotoSelection } from './actionTypes';

export const initEnsemble = () => 
 {
  const action: actionInitEnsemble = 
   {
    type:          ActionType.INIT_ENSEMBLE
   }

  return ( dispatch: DispatchTypeInitEnsemble ) => 
   {
    dispatch (action);
   }
 } 

export const addPhoto = ( newId:                  number  ,
                          newFile:                string  ,
                          newName:                string  ,
                          newDescription:         string  ,
                          newChoosen:             boolean ,
                          newAuthorCertification: boolean ,
                          newCameraBrand:         string  ,
						  newCameraModel:         string  ,
						  newUrlThumbnail:        string  ,
                          newUrlMagnified:        string  ,
						  newWidth:               number  ,
						  newHeight:              number  ,
                          newFirstname:           string  ,
                          newLastname:            string  ) =>
 {
  const action: actionAddPhoto =
   {
    type:           ActionType.ADD_PHOTO,
	newPhoto: {
      id:                  newId                  ,
      file:                newFile                ,
      name:                newName                ,
      description:         newDescription         ,
      choosen:             newChoosen             ,
      authorCertification: newAuthorCertification ,
      cameraBrand:         newCameraBrand         ,
      cameraModel:         newCameraModel         ,
	  urlThumbnail:        newUrlThumbnail        , 
      urlMagnified:        newUrlMagnified        ,
	  width:               newWidth               ,
	  height:              newHeight              ,
	  selected:            false                  ,
      firstname:           newFirstname           ,
      lastname:            newLastname           ,
	}
   }
   
  return ( dispatch: DispatchTypeAddPhoto ) =>
   {
    dispatch (action);
   }
 } 
 
export const updatePhotoSelection = ( num:         number  ,
                                      newSelected: boolean ) =>
 {
  const action: actionUpdatePhotoSelection =
   {
    type:        ActionType.UPDATE_PHOTO_SELECTION,
	num:         num   ,
	newSelected: newSelected
   }
   
  return ( dispatch: DispatchTypeUpdatePhotoSelection ) =>
   {
    dispatch (action);
   }
 } 
 
