export enum ActionType
 {
  INIT_AUTHENTICATION = 'INIT_AUTHENTICATION' ,
  SET_LOGIN           = 'SET_LOGIN'           , 
  SET_TOKEN           = 'SET_TOKEN'           ,
  SET_STATE           = 'SET_STATE'           ,
  SET_VERIFICATION    = 'SET_VERIFICATION'
 }

export interface Authentication
 {
  login:        string ,
  token:        string ,
  state:        number ,
  verification: string
 }

export interface actionInitAuthentication
 {
  type:  ActionType.INIT_AUTHENTICATION;
 }

export interface actionSetLogin
 {
  type:  ActionType.SET_LOGIN;
  login: string;
 }

export interface actionSetToken
 {
  type:  ActionType.SET_TOKEN;
  token: string;
 }

export interface actionSetState
 {
  type:  ActionType.SET_STATE;
  state: number;
 }

export interface actionSetVerification
 {
  type:  ActionType.SET_VERIFICATION;
  verification: string;
 }

export type Action = actionInitAuthentication | 
                     actionSetLogin           | 
                     actionSetToken           | 
                     actionSetState           | 
                     actionSetVerification;

export type DispatchTypeInitAuthentication = ( args: actionInitAuthentication ) => actionInitAuthentication
export type DispatchTypeSetLogin           = ( args: actionSetLogin           ) => actionSetLogin
export type DispatchTypeSetToken           = ( args: actionSetToken           ) => actionSetToken
export type DispatchTypeSetState           = ( args: actionSetState           ) => actionSetState
export type DispatchTypeSetVerification    = ( args: actionSetVerification    ) => actionSetVerification

					 
