export interface Video
 {
  title:                string ,
  imageFile:            string ,
  videoFile:            string ,
 }

export const tabVideo: Video[] =
 [
  {
   title:     "Episode 1" ,
   imageFile: "_000123_" ,
   videoFile: "_000123_Emission_01_LOVELIGHT_CHALLENGE_2024" ,
  },
  {
   title:     "Episode 2" ,
   imageFile: "_000125_lovelight_challenge_saison_1_episode_2" ,
   videoFile: "_000125_Emission_02_LOVELIGHT_CHALLENGE_2024" ,
  },
  {
   title:     "Episode 3" ,
   imageFile: "Vignette03.jpg" ,
   videoFile: "_000131_Lovelight_Challenge_2024_episode_3b" ,
  },
  {
   title:     "Episode 4" ,
   imageFile: "Vignette04.jpg" ,
   videoFile: "_000133_Lovelight_Challenge_2024_episode_4" ,
  },
  {
   title:     "Episode 5" ,
   imageFile: "Vignette05.jpg" ,
   videoFile: "_000135_Lovelight_Challenge_2024_episode_5" ,
  },
  {
   title:     "Episode 6" ,
   imageFile: "_000137_" ,
   videoFile: "_000137_Lovelight_Challenge_2024_episode_6" ,
  },
  {
   title:     "Episode 7" ,
   imageFile: "ep7.png" ,
   videoFile: "_000139_Lovelight_Challenge_2024_episode_7" ,
  },
  {
   title:     "Episode 8" ,
   imageFile: "Vignette08.jpg" ,
   videoFile: "_000141_Lovelight_Challenge_2024_episode_8" ,
  },
  {
   title:     "Episode 9" ,
   imageFile: "_000143_lovelight_challenge_saison_1_episode_9" ,
   videoFile: "_000143_Lovelight_Challenge_2024_episode_9" ,
  },
  {
   title:     "Episode 10" ,
   imageFile: "_000148_lovelight_challenge_saison_1_episode_10" ,
   videoFile: "_000148_Lovelight_Challenge_2024_episode_10" ,
  }
 ]
