import   React               from "react";
import   classNames          from 'classnames';
import { Link        , 
         useNavigate }  from 'react-router-dom';
import   styles              from './jury.module.scss';


export interface JuryProps {
  className?:         string;                 // classe CSS complémentaire
}

export const Jury = ({ className  }: JuryProps) => {

  return (
  
    <div className={classNames(styles.root, className)}>

      <div className={classNames(styles.contain, className)}>

      <div className={classNames(styles.espace_haut, className)}>
      </div>
	  
      <p className={classNames(styles.texte_2, className)}>
        Au fil des épreuves les candidats seront coachés et évalués par un Jury de
        professionnels reconnus.
      </p>
      
      <br/>
      <br/>
      <br/>

      <div className={classNames(styles.photos, className)}>

        <div className={classNames(styles.photo1, className)}>
	      <img src= "/images/nath-sakura.jpg"
		       className={classNames(styles.image, className)}
		       alt="Nath Sakura"/><br/><Link to="https://nath-sakura.net/" 
			                                 className = { classNames(styles.link, className) } 
											 target = "_blank" >Nath Sakura</Link>
        </div>

        <div className={classNames(styles.photo2, className)}>
	      <img src= "/images/Ilan-Dehe.jpg"
		       className={classNames(styles.image, className)}
		       alt="Ilan Dehe"/><br/><Link to="https://www.instagram.com/ilandehe/?hl=fr" 
			                               className = { classNames(styles.link, className) } 
										   target = "_blank" >Ilan Dehe</Link>
        </div>
		

        <div className={classNames(styles.photo3, className)}>
	      <img src= "/images/Julien-Apruzzese.jpg"
		       className={classNames(styles.image, className)}
		       alt="Julien Apruzzese"/><br/><Link to="https://www.jas.studio/" 
			                                      className = { classNames(styles.link, className) } 
												  target = "_blank" >Julien Apruzzese</Link>
        </div>
	  
      </div>
	  
	  <div className={classNames(styles.espace_bas, className)}>
      </div>

	  
	  </div>

    </div>
           
  );
};

export default Jury;
